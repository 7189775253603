import { FormControl, Theme} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles'
import { format, addDays, parse } from "date-fns";
import React, { useEffect } from "react";
import { useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  calendar: {       
    '&.react-datepicker__day--in-range': {
      background: '#1976d2',
      color: 'black'
    }
},
disabledDay: {
'&.react-datepicker__day--disabled': {
  background: 'lightgray !important',
  color: 'gray !important',
}
},
selectedDay: {
  '&.react-datepicker__day--selected': {
    background: '#1976d2 !important',
    color: 'black !important'
  }
},

}))

const DateRange = ({selectedProgram, selectedDate, trainings, handleSelect}) => {
  const classes = useStyles();
  //TODO translate
  const [open, setOpen] = useState(false);
  const [markedDates, setMarkedDates] = useState([])
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);  

useEffect(() => {
  const programTrainings = trainings.filter((training) => training.training_program_name === selectedProgram);

  if(programTrainings.length > 0) {
   const markedDates = programTrainings.map((training) => new Date(training.login))
   setMarkedDates(markedDates);

   const firstDate = new Date(programTrainings[programTrainings.length - 1].login);
   const lastDate = new Date(programTrainings[0].login);

    setStartDate(firstDate);
    setEndDate(lastDate);
  }
}, [selectedProgram, trainings])

 const handleSelectWrapper = (dates) => {
  console.log("Selected dates:", dates);

 const [start, end] = dates;

 if(start instanceof Date &&(end === null || end instanceof Date)) {
  const formattedStart = start.toLocaleDateString();
  const formattedEnd = end ? end.toLocaleDateString() : null;

  setStartDate(start);
  setEndDate(end);
  handleSelect({startDate: formattedStart, endDate: formattedEnd})
  setOpen(false);
 } else {
  console.error('Invalid dates!', start, end)
 }
};
  const isDateDisabled = (date) => {
    const today = new Date();
    return date < today;
  };

  const dayClassName = (date) => {
    const isDisabled = isDateDisabled(date);
    const isSelected = startDate && endDate && date >= startDate && date <= endDate;

    if(isDisabled) {
      return classes.disabledDay
    }
    if (isSelected) {
      return classes.selectedDay
    }

    return ''
  };

  const highlightDates = markedDates.map((date) => new Date(date)) 

  return (
    <FormControl>
    <DatePicker    
      value={
        startDate && endDate ?
        `${format(startDate, 'dd-MM-yyyy')} - ${format(endDate, 'dd-MM-yyyy')}` : ""        
       }      
      withPortal
      className={classes.calendar}      
      selected={startDate}
      startDate={startDate}
      endDate={endDate}        
      onChange={handleSelectWrapper}                   
      includeDates={markedDates}      
      highlightDates={highlightDates}
      onClickOutside={() => setOpen(false)}
      selectsRange={true}
      dayClassName={dayClassName}
      customInput={<input
        style={{
          width: '100%',
          height: '56px',          
          fontSize: '16px',
          borderRadius: '4px',
          border: '1px solid lightgrey',
          textAlign: 'center'
        }}
        />}      
    />
    </FormControl>     
  );
};

export default DateRange; 