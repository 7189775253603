import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography
} from '@mui/material';
import StopIcon from '@mui/icons-material/StopOutlined';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import { blue, green, grey, red } from "@mui/material/colors";
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '150px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  activities_percent: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  activities_percentIcon: {
    color: theme.palette.icon
  }
}));

const ActivitiesStatus = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  
  const data = {
    datasets: [
      {
        data: [props.activities_status['O'], 
               props.activities_status['F'], 
               props.activities_status['U'], 
               props.activities_status['N']],
        backgroundColor: [
          blue[500],
          green[500],
          red[500],
          grey[500]
        ],
        borderWidth: 2,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: [ props.t('statistics_page.over_performed'), props.t('statistics_page.fully_performed'), 
      props.t('statistics_page.under_performed'), props.t('statistics_page.not_performed')]
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 70,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 4,

      titleFontColor: theme.palette.text.primary,

    }
  };
  const all_acts_sum = props.activities_status['O'] + 
                       props.activities_status['F'] +
                       props.activities_status['U'] +
                       props.activities_status['N'];
  const percent_values = {
    "O": all_acts_sum===0 ? 0 : (100*props.activities_status['O']/all_acts_sum).toFixed(1),
    "F": all_acts_sum===0 ? 0 : (100*props.activities_status['F']/all_acts_sum).toFixed(1),
    "U": all_acts_sum===0 ? 0 : (100*props.activities_status['U']/all_acts_sum).toFixed(1),
    "N": all_acts_sum===0 ? 0 : (100*props.activities_status['N']/all_acts_sum).toFixed(1)

  }
  const activities_percent = [
    {
      title: props.t('statistics_page.over'),
      value: percent_values["O"],
      icon: <StopIcon style={{ color: blue[500] }}/>,
      color: blue[500]
    },
    {
      title: props.t('statistics_page.fully'),
      value: percent_values["F"],
      icon: <StopIcon style={{ color: green[500] }}/>,
      color: green[500]
    },
    {
      title: props.t('statistics_page.under'),
      value: percent_values["U"],
      icon: <StopIcon style={{ color: red[500] }}/>,
      color: red[500]
    },
    {
      title: props.t('statistics_page.not_done'),
      value: percent_values["N"],
      icon: <StopIcon style={{ color: grey[500] }}/>,
      color: grey[500]
    }    
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <IconButton size="small">
            {props.is_gym ? (<BusinessIcon />) : (<HomeIcon />)}
          </IconButton>
        }
        title={props.is_gym ? props.t('statistics_page.gym_title') : props.t('statistics_page.home_workouts_title')}
        subheader={props.t('statistics_page.prescribed_exercises')}>
      </CardHeader>
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
        <div className={classes.stats}>
          {activities_percent.map(activities_percent => (
            <div
              className={classes.activities_percent}
              key={activities_percent.title}
            >
              <span className={classes.activities_percentIcon}>{activities_percent.icon}</span>
              <Typography variant="body1">{activities_percent.title}</Typography>
              <Typography
                style={{ color: activities_percent.color }}
                variant="body2"
              >
                {activities_percent.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ActivitiesStatus.propTypes = {
  className: PropTypes.string,
  activities_status: PropTypes.object,
  is_gym: PropTypes.bool
};

export default withTranslation()(ActivitiesStatus);
