import React, { Fragment, useEffect, useRef} from "react";
import StartActivityModal from "../components/StartActivityModal";
import { useTranslation } from "react-i18next";
import Fab from "@mui/material/Fab";
import makeStyles from "@mui/styles/makeStyles"
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { teal } from "@mui/material/colors";
import { getVisits, getAvailableActivities, getTrainings } from "../services/api";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputLabel, OutlinedInput, Paper, Theme, Typography, } from "@mui/material";
import { SvgAsset } from "../components/SvgAsset";
import { useActions } from "../actions";
import BasicLineChart from "../components/BasicChartLine";
import ProgressPie from "../components/ProgressPie";
import moment from 'moment';
import { HurLoadingScreen, Loading } from "../components";
import { format } from "path";
import { DashboardPage } from "./DashboardPage";
import { first } from "lodash";
import { start } from "repl";
import {
    ArrowLeft,
    CloseButton,
    InfoButton,
    FemaleBody,
    MaleBody,
    HurLogo,
} from '../resources'


    const useStyles = makeStyles((theme: Theme) => ({
        parent_div: {
            display: "flex",
            flexDirection: "column",            
            width: "100%",            
            minHeight: "100vh",                        
            boxSizing: "border-box",
            overflowY: "auto",
            paddingBottom: "35px"
            
        },    
        box: {
            width: '100%',            
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        },
        calendarInput: {            
            height: '100%'
        },
        model: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",                        
        },
        progressDescription: {
            alignItems: "center",
        },
        buttonDiv: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            borderRadius: "40px",
            marginTop: 15,
            marginBottom: 10,
            // overflow: "hidden",            
        },        
        dayButton: {                                       
            background: "rgba(0, 160, 223, 1)",
            opacity: "50%",
            color: "black",                        
            // width: "110px",
            borderRadius: "0",
            '&.clicked': {
                background:"rgba(0, 160, 223, 1)",
                color: "black",
                opacity: 1
            },
            textTransform: "none"
        },
        leftSideButton: {
            backgroundColor: "rgba(0, 160, 223, 1)",            
            color: "black",
            opacity: "50%",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
            width: "100px",
            '&.clicked': {
                background:"rgba(0, 160, 223, 1)",
                color: "black",
                opacity: 1
            },
            textTransform: "none"
        },
        righSideButton: {
            backgroundColor: "rgba(0, 160, 223, 1)",            
            color: "black",
            opacity: "50%",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            '&.clicked': {
                background:"rgba(0, 160, 223, 1)",
                color: "black",
                opacity: 1
            },
            textTransform: "none",
            
        },
        _clickedButton: {
            background: "rgba(242, 137, 134, 1)",
            color: "white",
            opacity: 1
        },
        selectButton: {
            borderRadius: "40px",
            background: "rgba(0, 160, 223, 1)",
            textAlign: "center",
            color: "black",
            justifyContent: "space-evenly",
            width: "150px",
            fontSize: "0.8125rem"
        },
        menuPaper: {
            maxHeight: 200,
            borderRadius: "8px",
        },
        menuItem: {
            borderBottom: "1px solid black",
            '&:last-child': {
                borderBottom: "none"
            }
        },
        selectedMenuItem: {
            backgroundColor: "rgba(0, 160, 223, 1) !important",
            '&:hover': {
                backgroundColor: "rgba(0, 160, 223, 1) !important",
            }
        },
        dateContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",        
            overflowX: "auto",
            marginBottom: "10px",
            marginLeft: "20px",
            marginRight: "20px"
   },
        intervalDates: {            
            color: "gray",                       
            minWidth: "100px",
            textAlign: "center",
        },
        intervalMonths: {          
            color: "gray",
            marginLeft: "15px",
            maringRight: "15px",
            minWidth: "90px"
        },
        monthContainer: {
            display: "flex",            
            justifyContent: "space-between",
            overflowX: "auto",
            maxWidth:"100%"
        },
        currentDate: {
            color: "black"
        },
        inputLabel: {
            color: "white"
        },
        programTitle: {
            color: "#404040",            
            fontStyle: "normal",            
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "2px",
            fontFamily: "Poppins",
            fontSize: "16px",
            marginTop: 0,
        },
        progressPaper: {
            display: "flex",               
            alignItmes: "center",
            borderRadius: 20,
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            margin: "10px",
            marginTop: 30,
            height: "100%",
            width: "100%",
            maxWidth: "500px",            
            maxHeight: "380px",            
        },
        svgbody: {
            paddingTop: 18,
            width: "100%",            
            maxWidth: "200px",            

            
        },
        dialogTitle: {
        display: "flex",        
        color: "#404040",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",         
        paddingTop: "15px",
        paddingBottom: "10px",        
        paddingLeft: "4px",
        padding: 0, 
        fontSize: "16px", 
        fontWeight: 700,         
        flex: 1,
        whiteSpace: "normal",
        textOverflow: "ellipsis"
        },
        programDescription: {
            color: "#404040",             
            fontWeight: 600,
            fontSize: "0.625rem",
            marginTop: "3px",
            textAlign: "center",
            fontFamily: "Poppins"
        },
        progressDetailsText: {
            color: "#404040", 
            margin: "2px 0",
            fontSize: "0.625rem",
            fontWeight: 500,
            fontFamily: "Poppins",
            textAlign: "center",            
            lineHeight: "14px",
            whiteSpace: "nowrap",            
            textOverflow: "ellipsis",
            width: "100%",
            boxSizing: "border-box"
        },

        progressDetails: {
            display: "flex",
            flexDirection: "column",                 
            alignItems: "center",
            height: "100%",            
            padding: "10px",
            paddingTop: 0,
            marginLeft: 3,                        
        },
        chartTexts: {
            margin: "2px",
        },
        topButtonContainer: {
            display: "flex",            
            flexDirection: "column",
            alignItems: "stretch",
            width: "100%",            
        },

        topButtonsDiv: {
            maxWidth:"315px", 
            width: "100%",
            display: "flex",
            justifyContent: "center",            
            alignItems: "center",            
            height: 60,
            backgroundColor: "#F7F8F8",
            borderRadius: 99,
            border: '1px solid rgba(247,248,248,1)',
            marginTop: 10,   
            margin: "auto"                    
            
        },
        topButtons: {
            width: '130px',
            height: '40px',
            border: '1px solid rgba(247, 248, 248, 1)',
            borderRadius: 99,            
            color: "#404040",
            textTransform: "none",            
            
            
        },
        clickedTopButton: {
            backgroundColor: "rgba(0, 160, 223, 1)"
        },
        selectMenuItem:{
            borderBorrom: "1px solid black",

        },
        logo: {

        },
        arrowButton: {
        width:"32px",
        height: "40px",               
        borderRadius: "14px",
        background: "rgba(247, 248, 248, 1)",
        marginRight: "54px",
        marginLeft: "10px" 
        },
        logoContainer:{
        height: '85vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        },
        
    }));

export function TrainingProgressPage() {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 0);
    const initialDateRange = { startDate, endDate };    

    const getLatestMonth = (trainings) => {
        if(!trainings || trainings.length === 0) {
            console.log("trainings are empty");
            return null;
        }
        const filteredPrograms = trainings.filter(training => training.visit_type != 4)
        if(filteredPrograms.length === 0) {
            console.log("No valid trainings");
            return null;
        }
        const sortedDate = filteredPrograms.sort((a, b) => new Date(b.login).getTime() - new Date(a.login).getTime());
        if (sortedDate.length === 0 || !sortedDate[0] || !sortedDate[0].login) {
            console.log("No valid training dates found");
            return null;
        }
        const latestDate = new Date(sortedDate[0].login)
        console.log("latestDate", latestDate)
        return latestDate.getMonth()
        
    }    

    
    const handleMonthClick = (index) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const selectedMonth = index;
        const startDate = new Date(year, index, 1);
        const endDate = new Date(year, index + 1, 0);                
        setSelectedDateRange({startDate, endDate})
        setSelectedMonth(index);
        setCurrentScrollMonth(index)
        
    }
    
    const navigate = useNavigate();
    const perPage=150;
    const classes = useStyles();
    const monthContainerRef = useRef(null);
    const initialScrollDone = useRef(false);    
    const currentUser = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = React.useState(false);            
    const [loadedPage, setLoadedPage] = React.useState(0);    
    const [trainings, setTrainings] = React.useState([]);
    const initialSelectedMonth = getLatestMonth(trainings);    
    const [selectedProgram, setSelectedProgram] = React.useState('');
    const [_selectedProgram, _setSelectedProgram] = React.useState<string>('');
    const [selectedDate, setSelectedDate] = React.useState([]);
    const [overallProgress, setOverallProgress] = React.useState(0);
    const [activityProgress, setActivityProgress] = React.useState(0);
    const [volumesByDay, setVolumesByDay] = React.useState(new Map())    
    const [selectedActivity, setSelectedActivity] = React.useState('');
    const [currentScrollMonth, setCurrentScrollMonth] = React.useState(getLatestMonth(trainings))
    const [selectedMonth, setSelectedMonth] = React.useState(initialSelectedMonth);
    const [selectedInterval, setSelectedInterval] = React.useState('monthly');
    const [selectDateRange, setSelectedDateRange] = React.useState({startDate, endDate})        
    const [activityVolumeByDay,setActivityVolumeByDay] = React.useState(new Map());
    const [showMonths, setShowMonths] = React.useState(false);    
    const [largestLoad, setLargestLoad] = React.useState(0);
    const [clickedButton, setClickedButton] = React.useState()
    const [smallestLoad, setSmallestLoad] = React.useState(0);
    const [averageReps, setAverageReps] = React.useState(0);
    const [averagesets, setAverageSets] = React.useState(0);
    const [openDialog, setOpenDialog]= React.useState(false);
    const [selectedActivityLargestLoad, setSelectedActivityLargestLoad] = React.useState(0);
    const [selectedActivitySmallestLoad, setSelectedActivitySmallestLoad] = React.useState(0);
    const [selectedActivityAverageReps, setSelectedActivityAverageReps] = React.useState(0);
    const [selectedActivityAverageSets, setSelectedActivityAverageSets] = React.useState(0);
    const { t, i18n } = useTranslation();
    console.log("InitialSelectedMonth")

    useEffect(() => {
        if(initialSelectedMonth !== null) {
            handleMonthClick(initialSelectedMonth);
        }
    }, [initialSelectedMonth])

    useEffect(() => {
        if (trainings) {
            const latestMonth = getLatestMonth(trainings);
            if (latestMonth !== null) {
                handleMonthClick(latestMonth);
            }
        }
    }, [trainings]);

    moment.locale(i18n.language);

    const handleIntervalClick = (interval) => {
        setSelectedInterval(interval);
        console.log("Selected interval", interval)     
    }      

    const handleDateClick = (formattedLabel) => {
        console.log("label", formattedLabel);            

        const [startDateString, endDateString] = formattedLabel.split('-').reduce((acc, val, idx) => {
            if(idx < 3) {
                acc[0] += (idx === 0 ? '' : '-') + val;
            } else {
                acc[1] += (idx === 3 ? '' : '-') + val;
            }
            return acc;
        }, ['', '']);
        console.log("Parsed startDateString:", startDateString);
        console.log("Patsed endDateString:", endDateString);
        if(!startDateString || !endDateString) {
            console.error("Invalid formattedLabel");
            return;
        }
        // const [startDay, startMonth, startYear] = startDateString.split('.').map(Number);
        // const [endDay, endMonth, endYear] = endDateString.split('.').map(Number);

        // const startDate = new Date(startDateString);
        // const endDate = new Date(endDateString); 
        // const startDate = new Date(startYear, startMonth - 1, startDay);
        // const endDate = new Date(endYear, endMonth - 1, endDay);          
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        
        if(!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            console.log("startDate, endDate", {startDate, endDate})
            setSelectedDateRange({startDate: startDate, endDate: endDate})
            
            
        } else {
            console.error("invalid date format");
        }
        // const [start, end] = label.split('-');
        // const [startDay, startMonth, startYear] = start.split('').map(Number);
        // const [endDay, endMonth, endYear] = end.split('').map(Number);

        // const startDate = new Date(startDay, startMonth - 1, startYear);
        // const endDate = new Date(endDay, endMonth -1, endYear);

        // if(!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        // const formattedStartDate = startDate.toLocaleDateString();
        // const formattedEndDate = endDate.toLocaleDateString();
        // console.log("formattedStartDate and formattedEndDate in parent", formattedStartDate, formattedEndDate);
        
        // setSelectedDateRange({startDate: formattedStartDate, endDate: formattedEndDate})
        // } else {
        //     console.error("invalid date format");
        // }
    }

   const handleButtonClick = (label) => {
        setClickedButton(label);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
   
    console.log("selected month initially should be the latest", selectedMonth)

    const formatDateWithoutYear = (date, locale = "default") => {
        const options = { day:"numeric", month:"numeric" }
        return date.toLocaleDateString(locale, options)
}

    const getValidPrograms = (trainings) => {
        return trainings.filter(program => {
            const activities = program.activities || [];
            if(!activities) {
                console.error("Activities are undefined");
                return false;
            }
        const hasValidActivities = activities.some(activity => {
            const results = activity.results;
            const sets = results && results.sets;
            return Array.isArray(sets) && sets.length > 0;
        });
        return hasValidActivities;
        })
    }
    useEffect(() => {
        const latestMonth = getLatestMonth(trainings);
        if(latestMonth !== null) {
            handleMonthClick(latestMonth)
        }
    },[trainings])

    const renderSevenDays = () => {
        const buttons= [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();                        
        const oneMonthBackDate = new Date(currentDate);
        oneMonthBackDate.setMonth(currentDate.getMonth() - 1);

        let startDate = new Date(currentDate);
        // startDate.setMonth(startDate.getMonth() - 1);
        // startDate.setDate(1)
        // startDate.setDate(startDate.getDate() - (startDate.getDate() - 4));

        while(startDate >= oneMonthBackDate) {
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() - 6);

            if(endDate < oneMonthBackDate) {
                endDate.setDate(oneMonthBackDate.getDate())
            }

            const formattedStartDate = `${startDate.getDate()}.${(startDate.getMonth() + 1).toString().padStart(2, '0')}`
            const formattedEndDate = `${endDate.getDate()}.${(endDate.getMonth() + 1).toString().padStart(2, '0')}`;

            const startWithoutYear = formatDateWithoutYear(startDate)
            const endWithoutYear = formatDateWithoutYear(endDate)

            const formattedStartDateLabel = startDate.toISOString().split('T')[0];
            const formattedEndDateLabel = endDate.toISOString().split('T')[0];

            const label = `${endWithoutYear}-${startWithoutYear}`
            const formattedLabel = `${formattedEndDateLabel}-${formattedStartDateLabel}`
            const isCurrentDateInRange = startDate <= currentDate && currentDate <= endDate;            
            buttons.push(
                <Button
                key={label}
                className={`${classes.intervalDates} ${clickedButton === label ? classes.currentDate : ''}`}
                onClick={() => {
                    handleDateClick(formattedLabel)
                    handleButtonClick(label);
                }}
                >
                    {label}
                </Button>
            );
            startDate.setDate(startDate.getDate() - 7);
        }
        return buttons.reverse();
    }

    const renderFourteenDays = () => {
        const buttons = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
    
        let startDate = new Date(currentYear, currentMonth, - 1, 1);
        startDate.setDate(1)
        while(startDate.getDay() !== 1) {
            startDate.setDate(startDate.getDate() + 1)
        }        
        
    
        const lastDayOfSecondMonth = new Date(currentYear, currentMonth + 1);
    
        while (startDate <= lastDayOfSecondMonth) {
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 13);           
    
            const formattedStartDate = `${startDate.getDate()}.${(startDate.getMonth() + 1).toString().padStart(2, '0')}`
            const formattedEndDate = `${endDate.getDate()}.${(endDate.getMonth() + 1).toString().padStart(2, '0')}`;

            const startWithoutYear = formatDateWithoutYear(startDate)
            const endWithoutYear = formatDateWithoutYear(endDate)

            // const formattedStartDateLabel = startDate.toLocaleDateString();
            // const formattedEndDateLabel = endDate.toLocaleDateString();

            const formattedStartDateLabel = startDate.toISOString().split('T')[0];
            const formattedEndDateLabel = endDate.toISOString().split('T')[0];

            const label = `${startWithoutYear}-${endWithoutYear}`
            const formattedLabel = `${formattedStartDateLabel}-${formattedEndDateLabel}`
            
    
            const isCurrentDateInRange = currentDate >= startDate && currentDate <= endDate;
            
    
            buttons.push(
                <Button
                    key={label}
                    className={`${classes.intervalDates} ${clickedButton === label ? classes.currentDate : ''}`}
                    onClick={() => {
                        handleDateClick(formattedLabel)
                        handleButtonClick(label);
                        }}
                >
                    {label}
                </Button>
            );
    
            startDate.setDate(startDate.getDate() + 14); // Move to the next 14-day interval
        }
        return buttons;
    }

    useEffect(() => {
        setCurrentScrollMonth(getLatestMonth(trainings))
    })

    useEffect(() => {
        if(monthContainerRef.current && !initialScrollDone.current && monthContainerRef.current.children.length > 0) {
            const monthElement = monthContainerRef.current.children[currentScrollMonth];
            if(monthElement) {
                monthElement.scrollIntoView({behavior: "smooth", inline:"center"});
                initialScrollDone.current = true;
                console.log("scrollDone", initialScrollDone)
            }
        }
    },[currentMonth,monthContainerRef.current])  

    const renderMonths = () => {                       
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();

        console.log("current month", currentMonth);      
              
        const monthNames = t('progress_page.months', {returnObjects: true})
        if(!Array.isArray(monthNames)) {
            console.error('Expected months to be an array', monthNames)
            return null;
        }                      
        return(
            <div ref={monthContainerRef} className={classes.monthContainer}>
                {monthNames.map((month, index) => {
                    const isCurrentMonth = index === selectedMonth;
                    return (
                        <Button
                        key={index}
                        className={`${classes.intervalMonths} ${isCurrentMonth ? classes.currentDate : ''}`}
                        onClick={() => {
                            handleMonthClick(index)
                            handleButtonClick(index)
                            setCurrentScrollMonth(index)
                        }}
                        >
                        {month}
                        </Button>
                    )
                })}
            </div>            
        )                
    }
    const renderDates = () => {
      if(showMonths || !selectedInterval) return null;

        if(selectedInterval === '7') {
            return renderSevenDays();
        } else if(selectedInterval === '14') {
            return renderFourteenDays();
        } else if(selectedInterval === 'monthly') {
            return renderMonths();
        }
    }

    const handleActivityChange = (event) => {
        const newActivity = event.target.value;
        setSelectedActivity(newActivity);
    }
    
          //Handler for program changing
          const handleTrainingChange = (event) => {
            const newSelectedTraining = event.target.value;
            setSelectedProgram(newSelectedTraining);            

            console.log("111111111111",newSelectedTraining);
            
        
            const filteredPrograms = trainings.filter((training => training.training_program_name === newSelectedTraining))                       
            if(filteredPrograms.length > 0) {
               
                const firstTrainingDate = new Date(filteredPrograms[0].login);
                const lastTrainingDate = new Date(filteredPrograms[filteredPrograms.length -1].login);
    
                if(!isNaN(firstTrainingDate.getTime()) && !isNaN(lastTrainingDate.getTime())) {
                    if(firstTrainingDate.getTime() === lastTrainingDate.getTime()){
                    setSelectedDate([firstTrainingDate.toLocaleDateString()])     
                } else {
                    const sortedSelectedDate = [lastTrainingDate.toLocaleDateString(), firstTrainingDate.toLocaleDateString()]
                    setSelectedDate(sortedSelectedDate);
                }
                } else {
                    setSelectedDate(['defaultDate'])
            }      
        } else {
            setSelectedDate(['defaultDate'])
        }                
    }
    console.log("222222222222",_selectedProgram);
            console.log("333333333333",selectedProgram);
    

      useEffect(() => {
        async function initData() {
            setLoading(true);
            const nextPage = loadedPage + 1;            
            try {
                const data = await getVisits(currentUser.id, nextPage, perPage );
                console.log("Fetched Data", data);
                setTrainings(data.results)                                           
            } catch(err) {
                console.error(err);
            }
            setLoading(false);
        }
        initData();
      }, [])   
      console.log("trainings:", trainings);    

    //Filter out home workouts and make a new array from unique programs
    //   const filterPrograms = trainings.filter(program => program.visit_type === 1 || program.visit_type === 3);
    //     // console.log("types:", filterPrograms);
    //      const trainingProgramName = filterPrograms?.map(training => {
    //         if(!training) {
    //             console.error("Training is undefined", training);
    //             return '';
    //         }
    //         return training.training_program_name
    //      });    
         const filterPrograms = getValidPrograms(trainings);
         const trainingProgramName = filterPrograms?.map(training => {
            if(!training) {
                console.error("No trainings");
                return '';
            }
            return training.training_program_name
         })
         console.log("trainingProgramName", trainingProgramName)
         const uniqueTrainingPrograms = Array.from(new Set(trainingProgramName ?? []))
         console.log("uniqueTrainingPrograms:", uniqueTrainingPrograms);

    //The latest used program and first and latest dates of using that
        useEffect(() => {
            if(trainings.length > 0) {
             const filteredTrainings = trainings.filter(training => training.visit_type === 1 || training.visit_type === 3);
             const validPrograms = getValidPrograms(filteredTrainings);
             console.log("Valid programs in the selectProgram useEffect", validPrograms)
             console.log("filteredTrainings in the selectProgram useEffect", filteredTrainings)
             if(validPrograms.length > 0) {
             const lastTraining = validPrograms[0];             
             setSelectedProgram(lastTraining.training_program_name)                
                handleTrainingChange({target: {value :lastTraining.training_program_name}})
                } else {
                    setSelectedProgram(null)                    
                }
            }
        }, [trainings]);
        
        const hasRequiredTrainings = trainings.some(training => 
            training.visit_type === 1 || training.visit_type === 3
        )

        const calculateOverallProgress = (selectedDateVolumes) => {
            console.log("selectedDateVolumes in calculate", selectedDateVolumes)
            if (selectedDateVolumes.length > 1) {
              const startDate = selectedDateVolumes[0];
              const endDate = selectedDateVolumes[selectedDateVolumes.length - 1];
              console.log("startDate in calculate", startDate)
              console.log("endDate in calculate", endDate)
          if(startDate > 0) {
              return ((endDate - startDate) / startDate) * 100;
            } else {
              console.warn('Start date volume is zero');
              return 0;
            }
            } else {
              return 0;
            }
          };        

      

    // const handleDateChange = (newDateRange) => {
    //     console.log("newDateRange", newDateRange)
        
    //     const startDate = newDateRange.startDate
    //     const endDate = newDateRange.endDate
        
    //     setStartDate(startDate);
    //     setEndDate(endDate);

    //     console.log("StartDate in Date handler", startDate);
    //     console.log("endDate in Date handler", endDate);

    //     const formattedDateString = [
    //         startDate,
    //         endDate
    //     ]
    
    //     console.log("selected dates in handler", formattedDateString);
    //     setSelectedDate(formattedDateString);
    //   };
     
        
        useEffect(() => {           
            if(selectedProgram && selectedDate.length > 0) {        
        const filteredPrograms = trainings.filter((training) => training.training_program_name === selectedProgram && (training.visit_type === 1 || training.visit_type === 3));
        console.log("selected program in the useEffect", selectedProgram)
        console.log("filteredPrograms in the useEffect", filteredPrograms)
        if(filteredPrograms.length > 0) {
            let biggestLoad = 0;
            let smallestLoad = Infinity;            
            let totalReps = 0
            let totalSets = 0;
            let totalActivities = 0;
            let selectedActivityBiggestLoad = 0;
            let selectedActivitySmallestLoad = Infinity;            
            let selectedActivityTotalReps = 0
            let selectedActivityTotalSets = 0;
            let selectedActivitytotalActivities = 0;
            const startDate = selectedDate[0];
            const endDate = selectedDate[1];
            console.log("Start and end dates in useEffect", startDate, endDate)
            const volumesByDayMap = new Map();
            const activityVolumesMap = new Map();
            const selectedDates = selectedDate;
            let hasValidActivities = false; 
            console.log("filteredPrograms", filteredPrograms)    

            filteredPrograms.forEach((program) => {                          
               
                const activities = program.activities || [];
                if(!activities) {
                    console.error("Activities are undefined or null", program);
                    return;
                }    
                const validActivities = activities.filter(activity => {                    

                    const results = activity.results;
                    const sets = results && results.sets;                 
                    return Array.isArray(sets) && sets.length > 0
                })
                console.log("validActivities", validActivities)
                if(validActivities.length > 0) {
                    totalActivities += validActivities.length
                    hasValidActivities = true;
                    validActivities.forEach(activity => {
                        const results = activity.results;
                        const sets = results && results.sets;
                        if(!sets) {
                            console.error("Sets are undefined for activity:", activity);
                            return;
                        }
                        sets.forEach(set => {
                            const loadInGrams = set.load || 0;
                            const load = Math.round((loadInGrams / 1000) * 10) / 10 || 0;
                            if(load > biggestLoad) {                                
                                biggestLoad = load;
                            }
                            if(load > 0 && load < smallestLoad) {                                                               
                                smallestLoad = load;                                
                            }
                            totalReps += parseInt(set.reps, 10) || 0;
                            totalSets++;                                                                                                                                                                                          
                        })
                        if(selectedActivity === activity.activity_name) {
                            sets.forEach(set => {
                                const loadInGrams = set.load || 0;
                                const load = Math.round((loadInGrams / 1000) * 10) / 10 || 0;

                            if(load > selectedActivityBiggestLoad) {
                                selectedActivityBiggestLoad = load;
                            }
                            if(load > 0 && load < selectedActivitySmallestLoad) {
                                selectedActivitySmallestLoad = load;
                            }
                            selectedActivityTotalReps += parseInt(set.reps, 10) || 0;
                            selectedActivityTotalSets++;                            
                            
                            })
                            
                        }                        
                    })                    

                    const averageReps = totalSets > 0 ? Math.floor(totalReps / totalSets) : 0;
                    const averageSets =  totalActivities > 0 ? Math.floor(totalSets / totalActivities) : 0;
                    
                    setAverageReps(averageReps);
                    setAverageSets(averageSets);
                    setLargestLoad(biggestLoad);                              
                    const adjustedSmallestLoad = (smallestLoad === Infinity) ? 0 : smallestLoad;
                    const adjustedSelectedActivitySmallestLoad = (selectedActivitySmallestLoad === Infinity) ? 0 : selectedActivitySmallestLoad
                    setSmallestLoad(adjustedSmallestLoad);

                    const selectedActivityAverageReps = selectedActivityTotalSets > 0 ? Math.round(selectedActivityTotalReps / selectedActivityTotalSets) : 0;
                    const selectedActivityAverageSets = totalActivities > 0 ? Math.round(selectedActivityTotalSets / totalActivities) : 0;

                    setSelectedActivityAverageReps(selectedActivityAverageReps);
                    // setSelectedActivityAverageSets(selectedActivityAverageSets);
                    setSelectedActivityLargestLoad(selectedActivityBiggestLoad);
                    setSelectedActivitySmallestLoad(adjustedSelectedActivitySmallestLoad);                     
                }
                console.log("validActivities", validActivities)              
                
                // console.log(program)
            const totalVolume = validActivities.reduce((sum, activity) => {
                const results = activity.results;              
                const sets =  results && results.sets;             
                if(sets && Array.isArray(sets) && sets.length > 0) {                
                const dayTotalVolume = sets.reduce((totalSum, set) => {
                    if(set.load !== undefined && set.reps !== undefined) {
                   return totalSum + (set.load / 1000) * set.reps;
                    }
                },0);
                      
                return Math.floor(sum + dayTotalVolume);
                } else {
                    console.log("HOX! 'sets' array is missing or empty in activity", activity)                    
                    return sum;
                 }                                        
                       
            }, 0);
            // const processedDates = new Set()
            // filteredPrograms.forEach((program) => {
            //     const loginDate = new Date(program.login);
            //     const dateKey = loginDate.toLocaleDateString();

            //     if(!processedDates.has(dateKey)) {
            //         processedDates.add(dateKey);
            //         volumesByDayMap.set(dateKey, totalVolume)
            //     } else {
            //         console.log(`Skipping duplicate training for date ${dateKey}`)
            //     }
            // })

            if (filteredPrograms.length > 0) {    
                const startDate = new Date(selectedDate[0]);
                const endDate = new Date(selectedDate[1]);
                // console.log("Start and end dates in useEffect", startDate, endDate);
                                        
                filteredPrograms.forEach((program) => {
                    const loginDate = new Date(program.login);
                    const loginDateKey = loginDate.toLocaleDateString();                                                       
                    const activities = program.activities;
                   
                    

                    activities.forEach((activity) => {
                        const results = activity.results;
                        const sets = results && results.sets;            
                        const activityName = activity.activity_name;

                        if(!activityVolumesMap.has(activityName)) {
                            activityVolumesMap.set(activityName, new Map());
                        }                                            
            
                        const volumeByDay = activityVolumesMap.get(activityName)                          
                        let dailyVolume = 0;
            
                        if(Array.isArray(sets) && sets.length > 0) {
                            sets.forEach((set) => {                                                        
                            // console.log("ActivityName:", activityName, "Sets:", sets);                              
                            const activityVolume = Math.floor((set.load / 1000) * set.reps);
                            dailyVolume += activityVolume
                                // console.log(`ActivityVolume: ${activityVolume} for Activity: ${activityName}`)
                                if(!volumeByDay.has(loginDateKey)) {
                                    volumeByDay.set(loginDateKey, 0);
                                
                                    }
                                    // console.log("00000000000000000000", [activityVolume]);                                  
                                    volumeByDay.set(loginDateKey, dailyVolume);
                                    // console.log("4444444444444444", [volumeByDay]);    
                                })
                            }  
                                                                                                                                                                                                                                                                        
                    });  
                }); 
            if(selectedActivity && activityVolumesMap.has(selectedActivity)) {
                const volumeByDay = activityVolumesMap.get(selectedActivity);
                console.log("volumebyday from activities", volumeByDay)
                const volumeEntries = Array.from(volumeByDay.entries());
                console.log("VolumeEntries", volumeEntries)
                const nonZeroVolumeEntries = volumeEntries.filter(entry => entry[1] > 0);
                if(nonZeroVolumeEntries.length > 0) {
                    const firstTraining = nonZeroVolumeEntries[nonZeroVolumeEntries.length - 1];
                    console.log("firsttraining from activities", firstTraining)
                    const latestTraining = nonZeroVolumeEntries[0];
                    console.log("latesttraining from activities", latestTraining)

                    const firstVolume = firstTraining[1]
                    const latestVolume = latestTraining[1]
                    if(firstVolume === 0 || latestVolume === 0) {
                    console.log("Skipping progress calculation because of 0 values")
                        setActivityProgress(0);
                    } else {
                    const activityProgress = Math.floor(((latestVolume - firstVolume) / firstVolume) * 100)
                    setActivityProgress(activityProgress);
                    console.log("activityProgress", activityProgress);
                    }
                } else {
                    setActivityProgress(0);
                }          
                } else {
                    setActivityProgress(0);
                }               
            }            
            const loginDate = new Date(program.login);
            const dateKey = loginDate.toLocaleDateString();

            if(totalVolume !== 0) {
                if(volumesByDayMap.has(dateKey)) {
                    volumesByDayMap.set(dateKey, volumesByDayMap.get(dateKey) + totalVolume)
                    // volumesByDayMap.set(dateKey, volumesByDayMap.get(dateKey) + dayTotalVolume)                
            } else {
                volumesByDayMap.set(dateKey, totalVolume);
            }                     
        }
        });
            setActivityVolumeByDay(activityVolumesMap)
            setVolumesByDay(volumesByDayMap);
            console.log("volumesbydaymap123", volumesByDayMap)
            console.log("setvolumebyday", volumesByDay)            

            const volumeValues = Array.from(volumesByDayMap.values());
            console.log("volumeValues", volumeValues)
            if(volumeValues.length > 1) {
                const startVolume = volumeValues[volumeValues.length - 1];
                const endVolume = volumeValues[0];

                const selectedDateVolumes = [startVolume, endVolume];
                console.log(`startVolume: ${startVolume}, endVolume: ${endVolume}`)

                const overallProgress = calculateOverallProgress(selectedDateVolumes)

                setOverallProgress(Math.floor(overallProgress))
            } else {
                setOverallProgress(0);
            }            
            console.log("Selected start date volume:", volumesByDayMap.get(startDate));
            console.log("Selected end date volume:", volumesByDayMap.get(endDate));               
            const selectedDateVolumes = [volumesByDay.get(startDate), volumesByDay.get(endDate)]                   
            console.log("Dates in volumesByDay", Array.from(volumesByDayMap.keys()))
            console.log("Selected date volumes", selectedDateVolumes)           
        }        
      }
    }, [selectedProgram, trainings, selectedDate, selectedActivity])        
    
        const totalTimeSpentPerDate= new Map();
        
        trainings.forEach(training => {
            if(training.logout != null && training.logout != undefined && training.login != null && training.login != undefined ) {
            if(typeof training.logout === 'string' && typeof training.login === 'string') {
            const logoutTime = new Date(training.logout);
            const loginTime = new Date (training.login);

            if(!isNaN(logoutTime.getTime()) && !isNaN(loginTime.getTime())) {
                const timeDifference = logoutTime.getTime() - loginTime.getTime();

                
            if(!isNaN(timeDifference)) {
                const totalTimeSpent = Math.floor(timeDifference / (1000 * 60));
            

            if(training.visit_type === 3 || training.visit_type === 1 || training.visit_type === 4)  {
                const dateKey = logoutTime.toLocaleDateString().split('T')[0];

            if(!totalTimeSpentPerDate.has(training.visit_type)) {
                totalTimeSpentPerDate.set(training.visit_type, new Map())
            }

            const visitTypeMap = totalTimeSpentPerDate.get(training.visit_type);
            const totalTimeSpentPerVisitType = visitTypeMap.get(dateKey) || 0;
            visitTypeMap.set(dateKey, totalTimeSpentPerVisitType + totalTimeSpent);
               }
            } else {
                console.error("Invalid time difference", timeDifference);
            }
        } else {
            console.error("Invalid date format", training.logout, training.login);
        }       
         } else {
            console.error("Null or undefined logout or login", training.logout, training.login);
         }
        }
        });
        
        console.log("totalTimeSpentPerDate", totalTimeSpentPerDate)  

        const isFemale = currentUser?.profile?.gender === 'F';

        if(currentUser && currentUser.profile) {
            const isFemale = currentUser.profile.gender === 'F';
        } else {
            const isFemale = false;
        }

        if(loading) {
            console.log("loading");
            return <HurLoadingScreen />;
        }   
        
        
        // if(trainings.every(training => training.visit_type === 4)) {
        if(trainings.length === 0) {
            console.log("rendering logo and message")
            return(
                <div>
                <div style={{display: "flex", flexDirection:"row", alignItems:"center", marginLeft: 35, zIndex:1100}}>
                    <Button onClick={() => navigate('/app/dashboard')} style={{marginRight: "54px"}}><ArrowLeft/></Button>
                    <h4>{t('common.bottom_label_progress')}</h4>
                </div>
            <div className={classes.logoContainer}>
                <HurLogo className={classes.logo} />
                <p style={{color: "#404040", fontSize:"16px", fontFamily: "Poppins", fontWeight: 400}}>{t('progress_page.no_trainings')}</p>
            </div>
            </div>
            );
        }        

        if(trainings.length > 0) {
         const filteredPrograms = trainings.filter((training) =>
         training.training_program_name === selectedProgram         
        )
        const dates = filteredPrograms?.map((date) => {            
           const loginDate = new Date(date.login);
           return loginDate.toLocaleDateString();
        });    
                                                
        const uniqueDates = [...new Set(dates)];
        const isMonthlySelection = selectDateRange && selectDateRange.startDate && selectDateRange.endDate;
        const selectedProgramActivities = filteredPrograms.flatMap(training => training.activities.map(activity => activity.activity_name));
        console.log("selectedProgramActivities", selectedProgramActivities)
        const uniqueActivities = Array.from(new Set(selectedProgramActivities ?? []));
        console.log("uniqueActivities", uniqueActivities)        
        console.log("Chosen program has been used on these days:", dates);
        console.log("Current selected program:", selectedProgram);  
        console.log("Selected programs trainings:", filteredPrograms)         
 
    console.log("rendering the main content")
    console.log("selectedProgram1235t312412", selectedProgram);
    return (
        <div className={classes.parent_div}>            
            <div style={{position: "fixed", height: "300px", width: "100%", top: 0, zIndex: 10, backgroundColor: "white"}}>
            <div className={classes.topButtonContainer}>
            <div style={{display: "flex", justifyContent:"center", alignItems: "center", marginTop:20, width:"100%",}}>
        <div style={{display: "flex", flexDirection:"row", alignItems:"center", maxWidth: "315px", width: "100%", marginRight: 20}}>
                    <Button onClick={() => navigate('/app/dashboard')} style={{marginRight: "54px"}}><ArrowLeft /></Button>
                    <h4 style= {{margin: 0, fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#404040"}}>{t('common.bottom_label_progress')}</h4>
                </div>	
                </div>
        
        <div className={classes.topButtonsDiv} style={{display: "flex", justifyContent: "center", marginLeft: "auto"}}>            
            <Button 
            className={classes.topButtons}
            style={{marginRight: '10px'}}
            onClick={() => navigate('/app/workouts')}
            >
                {t('progress_page.history_button')}
            </Button>
            <Button 
            className={classes.topButtons}
            style={{                    
                    background: "rgba(15, 84, 134, 1)",
                    textTransform: "none",
                    color: 'white'
            }}
            >
                {t('progress_page.insight_button')}
            </Button>            
        </div>
        </div>       
        <div className={classes.buttonDiv}>
        <Button className={`${classes.leftSideButton} ${selectedInterval === '7' ? 'clicked' : ''}`}
                onClick = {() => handleIntervalClick('7')}>
                {t('progress_page.sevendays_button')}
        </Button>
        <Button className={`${classes.dayButton} ${selectedInterval === '14' ? 'clicked' : ''}`}
                onClick = {() => handleIntervalClick('14')}
        >
            {t('progress_page.fourteendays_button')}
        </Button>
        <Button className={`${classes.righSideButton} ${selectedInterval === "monthly" ? 'clicked' : ''}`}
                onClick = {() => handleIntervalClick("monthly")}
        >
            {t('progress_page.monthly_button')}
        </Button> 
        </div>        
        <div className={classes.dateContainer}>        
        {selectedInterval === 'monthly' ? renderMonths() : renderDates()}
        </div>                 
        <Container className={classes.box}>
        <Box 
        className={classes.box}>
        <FormControl>        
        <Select
         className={classes.selectButton} 
         value={selectedProgram || ''}
         displayEmpty         
         onChange={handleTrainingChange}
         MenuProps={{
            PaperProps: {
                className: classes.menuPaper
            }
         }}        
         >  
            {uniqueTrainingPrograms && uniqueTrainingPrograms.length > 0 ? (
            uniqueTrainingPrograms
            .filter((programName): programName is string => !!programName)
            .map((programName, index) => (
            <MenuItem 
            key={index} 
            value={programName} 
            className={`${classes.menuItem} ${selectedProgram === programName ? classes.selectedMenuItem : ''}`}
            >
                {programName}
            </MenuItem>
            ))
            ) : (
                <MenuItem value="" disabled>
                    {t('progress_page.no_programs')}
                </MenuItem>            
            )}
        </Select>
        </FormControl>        
        <FormControl>        
        <Select            
            className={classes.selectButton}                        
            value={selectedActivity || ''}
            displayEmpty            
            onChange={handleActivityChange}    
            MenuProps={{
                PaperProps: {
                    className: classes.menuPaper
                }
             }}                                          
            > 
            <MenuItem value="" style={{borderBottom: "1px solid black"}}>
            {t('progress_page.activities_select')}
            </MenuItem>                           
            {uniqueActivities && uniqueActivities.length > 0 ? (
            uniqueActivities
            ?.filter(activity_name => activity_name)
            ?.map((activity_name, index) => (
                <MenuItem 
                key={index} 
                value={activity_name} 
                className={`${classes.menuItem} ${selectedActivity === activity_name ? classes.selectedMenuItem : ''}`}
                >
                    {activity_name}
                </MenuItem>
            ))
        ) : (
            <MenuItem>
            {t('progress_page.no_activities')}
            </MenuItem>
        )}            
            </Select>       
    </FormControl>    
    </Box>    
            </Container>
            </div>                                
        <div style={{marginTop: "280px"}}>
            <Box className={classes.model}>
            
            <Paper className={classes.progressPaper}>
                {isFemale ? <FemaleBody className={classes.svgbody}/> : <MaleBody className={classes.svgbody}/> }
                <div style={{ width:"220px", display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", justifyContent: "flex-end",}}>                
                <InfoButton onClick={handleOpenDialog} style={{marginTop:"20px", height: "24px", width: "24px", marginRight: "20px"}}/>			
					<Dialog
                PaperProps={{
                    style:{
                        border: "3px solid rgba(64, 64, 64, 1)",
                        borderRadius: "20px",
                        overflow: "hidden"
                    }
                }}              
				open={openDialog} 
				onClose={handleCloseDialog}				
				>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderBottom: "2px solid #404040", alignItems: "center", width: "100%"}}>
			<DialogTitle className={classes.dialogTitle}>{t('dashboard_page.overall')}</DialogTitle>
			<IconButton  
				onClick={handleCloseDialog}
				style={{
                    paddingRight: 10,
                    flexShrink: 0
                }}>
				<CloseButton />
			</IconButton>
            </div>
			<DialogContent>
				<DialogContentText style={{color: "#404040", fontSize:"0.75rem", lineHeight: "18px", fontWeight: 400, padding: 0, margin: 0}}>
				<p>{t('progress_page.overall_progress_explanation')}</p>
                <p>{t('progress_page.no_gym_data')}</p>
				</DialogContentText>
			</DialogContent>			
			</Dialog>
                </div>
                <div className={classes.chartTexts}>  
                <h3 className={classes.programTitle}>{selectedProgram}</h3>          
            <ProgressPie 
            overallProgress={selectedActivity ? activityProgress : overallProgress}/>
            <p className={classes.programDescription}>
                {selectedActivity ? selectedActivity : t('dashboard_page.overall')}
                </p>  
                   
            </div>
            {hasRequiredTrainings ? (
            <div className={classes.progressDetails}>
            <p className={classes.progressDetailsText}>{t('dashboard_page.largest_load')} {selectedActivity ? selectedActivityLargestLoad : largestLoad} kg</p>
            <p className={classes.progressDetailsText}>{t('dashboard_page.smallest_load')} {selectedActivity ? selectedActivitySmallestLoad : smallestLoad} kg</p>
            <p className={classes.progressDetailsText}>{t('dashboard_page.average_reps')} {selectedActivity ? selectedActivityAverageReps : averageReps}</p>
            <p className={classes.progressDetailsText}>{t('dashboard_page.usual_reps')} {averagesets}</p>
            </div>            
            ) : (
            <div style={{textAlign: "center"}}>
                <Typography style={{fontWeight: 400, fontSize: 12}}>
                    {t('progress_page.no_gym_data')}
                </Typography>
            </div>
            )}
            </div>
            </Paper>
        </Box>                          
        </div>
            <Box style={{padding: 10}}>           
            <BasicLineChart 
            trainings={trainings}
            totalTimeSpentPerDate={totalTimeSpentPerDate}
            volumesByDay={volumesByDay}
            selectDateRange={selectDateRange}
            activityVolumeByDay={activityVolumeByDay}
            selectedProgram={selectedProgram}
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}           
            isMonthlySelection={isMonthlySelection}
             />  
            </Box>                                   
        </div>                             
    );
}
 
}

