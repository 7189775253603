import * as React from "react";
import * as ReactDOM from "react-dom";
import { ReduxRoot } from "./ReduxRoot";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "./i18n";

const rootEl = document.getElementById("root");
ReactDOM.render(<ReduxRoot />, rootEl);

// comment in for PWA with service worker in production mode
serviceWorkerRegistration.register({
	onUpdate: async (registration) => {
		// We want to run this code only if we detect a new service worker is
		// waiting to be activated.
		// Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
		if (registration && registration.waiting) {
			await registration.unregister();
			// Once the service worker is unregistered, we can reload the page to let
			// the browser download a fresh copy of our app (invalidating the cache)
			if (window.confirm("New version available!  Ready to update?")) {
				window.location.reload();
			}
		}
	},
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
