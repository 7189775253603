import React, { useEffect } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import confetti from "../resources/img/confetti.png";
import { useState } from "react";
import  makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Trophy } from '../resources';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: "20%"
    },
    trophy: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: "Poppins",
        color: "#404040"

    },
    text: {
        fontWeight: 400,
        fontSize: 12,
        fontFamily: "Poppins",
        color: "#404040",
        textAlign: "center",
    },
    button: {
        margin: theme.spacing(3, 0, 2),
		borderRadius: 99,
		height: 47,
		width: 201,
		background: "rgba(15, 84, 134, 1)",
		textTransform: "none",
        color: "white",
        alignItems: "center",
    }
}))

export default function GoalsAchived ({open, handleCloseDialog}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false); 
    moment.locale(i18n.language);
   
    return(        
     <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
            style:{
                borderRadius: "20px",
                backgroundImage: `url(${confetti})`,
                backgroundSize: "cover",
            }
        }}
        >
            <DialogContent className={classes.dialogContent}>
                <Trophy className={classes.trophy} />            
            <DialogTitle className={classes.title}>{t('dashboard_page.goal_dialog_title')}</DialogTitle>
            <DialogContentText className={classes.text}>{t('dashboard_page.goal_dialog_text')}</DialogContentText>            
            <Button className={classes.button} onClick={handleCloseDialog}>{t('activity_page.continue')}</Button>
            </DialogContent>
          

     </Dialog> 
    );
}