export default {
	isLoggedIn: () =>
		localStorage.getItem &&
		typeof localStorage.getItem === "function" &&
		localStorage.getItem("secretKey") === "allowmein",
	logout: () =>
		localStorage.removeItem &&
		typeof localStorage.removeItem === "function" &&
		localStorage.removeItem("secretKey"),
};
