import React, { useEffect } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import confetti from "../resources/img/confetti.png";
import { useState } from "react";
import  makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
    WellDone,
    CloseDialog
} from "../resources"
import { useNavigate } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: "20%"
    },
    trophy: {
        paddingTop: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0,
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: "Poppins",
        color: "#404040",
        padding: 5

    },
    text: {
        fontWeight: 400,
        fontSize: 12,
        fontFamily: "Poppins",
        color: "#404040",
        textAlign: "center",
        padding: 10,
    },
    button: {
        minWidth: "114px",
		whiteSpace: "nowrap",
		height: 36,
		textTransform: "none",
		borderRadius: "99px",
		color: "#404040",
		boxShadow: "0 2px 2px #404040",
		backgroundColor: "#F28986",
		"&:hover": {
			backgroundColor: "#F28986",
		},
    }
}))

export default function CompletionDialog ({open, handleCloseDialog, endSession}) {
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false); 
    moment.locale(i18n.language);

    const handleEndSession = () => {
        endSession();
        navigate('/app/dashboard')
        handleCloseDialog();
    }
   
    return(        
     <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
            style:{
                borderRadius: "20px",
                backgroundImage: `url(${confetti})`,
                backgroundSize: "cover",
            }
        }}
        >
            <IconButton
							onClick={handleCloseDialog}
							style={{
								paddingRight: 20,
								flexShrink: 0,
                                display: "flex",
                                justifyContent: "flex-end",   

							}}>
								<CloseDialog />
							</IconButton>		
            <DialogContent className={classes.dialogContent}>
                <WellDone className={classes.trophy} />            
            <DialogTitle className={classes.title}>{t('training_session_page.completion_dialog_title')}</DialogTitle>
            <DialogContentText className={classes.text}>{t('training_session_page.completion_dialog_text')}</DialogContentText>            
            <Button className={classes.button} onClick={handleEndSession}>{t('training_session_page.end_session')}</Button>
            </DialogContent>
          

     </Dialog> 
    );
}