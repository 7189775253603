import React, {useState, useRef} from 'react';




const Swipeable = ({onSwipeLeft, onSwipeRight, children}) => {
    const containerRef = useRef(null);
    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);
    const [currentX, setCurrentX ] = useState(null);
    const [isSwiping, setIsSwiping] = useState(false);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
        setIsSwiping(false);
    }

    const handleTouchMove = (e) => {
        if (startX === null) return;
    const currentTouchX = e.touches[0].clientX;
    const currentTouchY = e.touches[0].clientY;
    const diffX = currentTouchX - startX;    
    const diffY = currentTouchY - startY;    

    if(Math.abs(diffX) > Math.abs(diffY)) {
      setIsSwiping(true);
      setCurrentX(currentTouchX)
      containerRef.current.style.transition = 'none'
      containerRef.current.style.transform = `translate(${diffX}px)`
    }
    }

   

    const handleTouchEnd = () => {
        if (startX === null || !isSwiping) return;
        const diffX = currentX - startX        
        const swipeThreshold = 175;

        containerRef.current.style.transition = 'transform 0.4s ease'
        if (diffX > swipeThreshold) {
          onSwipeRight && onSwipeRight();
        } else if (diffX < -swipeThreshold) {
          onSwipeLeft && onSwipeLeft();
        }
        containerRef.current.style.transform = ''; // Reset transform
        setStartX(null);        
        setCurrentX(null);
        setIsSwiping(false);
      };

    

    return (
      <div
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove= {handleTouchMove}
      onTouchEnd={handleTouchEnd}
      >
       {children}
      </div>
        
    )
};


export default Swipeable;