import { Box, Button, Container, Link } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../resources/img/for_lifelong.png";
import authenticator from "../services/authenticator";
import InstallPWAButton from "../components/InstallPWAButton";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundImage:
			"url(/img/homepage.png)",
		backgroundPositionX: "center",
		backgroundPositionY: "center",
		backgroundSize: "cover",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			backgroundSize: "cover",
		},
		backgroundRepeat: "no-repeat",
		position: "relative",		
		height: "100vh",
		width: "100%",
		overflow: "hidden",

		'&::before': {
			content: '""',
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "rgba(0,0,0,0.8)",			
		},

		'& > *': {
			position: "relative",
			zIndex: 2
		}
		
	},

	paper: {
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "100%",
		width: "100%",
		color: "white",				
	},
	
	button: {
		background: "#0F5486",
		borderRadius: "99px",
		textTransform: "none",
		fontSize: "16px",
		fontWeight: 700,
		marginTop: "20px",
		width: 201,
		height: 47
	},
}));

export function HomePage() {
	const classes = useStyles();

	const [, setLoaded] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);

	useEffect(() => {
		const isLoggedIn = authenticator.isLoggedIn();
		if (isLoggedIn) {
			setLoaded(true);
			setLoggedIn(true);
		} else {
			setLoaded(true);
		}
	}, []);

	return (
		<div className={classes.root}> 
		<Container component="main" maxWidth="sm">
			<CssBaseline />
			<div className={classes.paper}>
				<Box height={50} />
				<div>
					<img style={{zIndex: 10}} src={logo} alt="Logo" />
				</div>											
				<Box height={350} />
				<InstallPWAButton />
				{loggedIn ? (
					<Link component={RouterLink} to="/app/dashboard">
						<Button className={classes.button} variant="contained" color="primary">
							Go To Dashboard
						</Button>
					</Link>
				) : (
					<Button component={RouterLink} to="/login">
						<Button className={classes.button} variant="contained" color="primary">
							Sign In
						</Button>
					</Button>
				)}
				<Box height={300} />

			</div>
		</Container>
		</div>
	);
}
