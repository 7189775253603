export interface Visit {
	id: number;
	text: string;
	completed: boolean;
}

export enum VisitActions {
	LOAD_VISITS = "LOAD_VISITS",
	SET_TOTAL_VISITS = "SET_TOTAL_VISITS",
}

interface VisitActionType<T, P> {
	type: T;
	payload: P;
}

export type VisitAction = VisitActionType<
	typeof VisitActions.LOAD_VISITS,
	Visit[]
>;
export type VisitTotalAction = VisitActionType<
	typeof VisitActions.SET_TOTAL_VISITS,
	number
>;
