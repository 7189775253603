import React, { useEffect } from 'react';
import  makeStyles  from '@mui/styles/makeStyles'
import {
    HurLogo
} from '../resources'
import { Theme, keyframes } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    loadingScreen: {
        height: "80vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",        
    },
    logoContainer: {
        
    },
    textContainer: {
        position: "relative",
        height: "16px",
        width: "170px",
        textAlign: "left",
    },
    loadingText: {
        fontFamily: "Poppins",
        marginLeft: "3px",
        fontWeight: 300,        
        color: "rgba(0, 160, 223, 1)",
        fontSize: "16px",
        marginTop:"1px",
        position: "absolute",
        flexShrink: 0,
        padding: 0,
        margin: 0,
        opacity: 0,
        transition: "opacity 1.2s ease-in-out"
        
    },
    visibleText: {
        opacity: 1,
    },
    fadeInOut: {
        animation: "$fadeInOut 1.2 ease-in-out",
        animationFillMode: "forwards"
    },
    '@keyframes fadeInOut': {
        '0%, 100%':{
        opacity: 0.5
        },
    '50%': {
        opacity: 1
    }
    },
   

}))

export function HurLoadingScreen() {
    const classes = useStyles()
    const [currentMessage, setCurrentMessage] = React.useState(0);
    
    const loadingMessages = [
        'For',
        'For Lifelong',
        'For Lifelong Strength'
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessage((prevMessage) => (prevMessage + 1) & loadingMessages.length)
        }, 1200);
        return () => clearInterval(interval);
    }, []);

    return(
        <div className={classes.loadingScreen}>        
        <div className={classes.logoContainer}>
            <HurLogo />
            </div>
            <div className={classes.textContainer}>
                {loadingMessages.map((message, index) => (
                    
                
            <div key={index} className={`${classes.loadingText} ${index === currentMessage ? classes.visibleText : ''}`}
            >
                <p style={{margin: 0, fontSize: 16, fontWeight: 300}}>{message}</p>
                </div>                    
            
                ))}     
            </div>
        </div>
    )
}