import { Visit, VisitAction, VisitActions, VisitTotalAction } from "../model";
import createReducer from "./createReducer";

export const visits = createReducer<Visit[]>([], {
	[VisitActions.LOAD_VISITS](state: Visit[], action: VisitAction) {
		return [...state, ...action.payload];
	},
});

export const totalVisits = createReducer<number>(0, {
	[VisitActions.SET_TOTAL_VISITS](state: number, action: VisitTotalAction) {
		return action.payload;
	},
});
