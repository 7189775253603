
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locale/en/translation.json';
import translationFI from './locale/fi/translation.json';
import translationFR from './locale/fr/translation.json';
import translationDE from './locale/de/translation.json';
import translationIT from './locale/it/translation.json';
import translationES from './locale/es/translation.json';
import translationDK from './locale/dk/translation.json';
import translationDU from './locale/du/translation.json';
import translationSW from './locale/sw/translation.json';
import translationNO from './locale/no/translation.json';
import translationZH_CN from './locale/zh_CN/translation.json';
import translationZH_TW from './locale/zh_TW/translation.json';



const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  fi : {
    translation: translationFI
  },
  zh_CN: {
    translation: translationZH_CN
  },
  zh_TW: {
    translation: translationZH_TW
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  },
  it: {
    translation: translationIT
  },
  dk: {
    translation: translationDK
  },
  du: {
    translation: translationDU
  },
  sw: {
    translation: translationSW
  },
  no: {
    translation: translationNO
  }
};

i18n
.use(initReactI18next)
.use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
