import { Box, Button } from "@mui/material";
import { getAccount } from "../services/api";
import { useEffect } from "react";
import React from "react";
import { useActions } from "../actions";
import { UserActions } from "../model";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { RootState } from "../reducers";
import UserProfile from "../components/Profile/User";
import  makeStyles  from "@mui/styles/makeStyles";
import { CleaningServices } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
    NameIcon,
    GenderIcon,
    BirthIcon,
    EmailIcon,
    ArrowLeft,
} from '../resources'

const useStyles = makeStyles((theme) => ({
    topDiv: {
        maxWidth: "340px",
        width:"100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        paddingTop: 15,                      
        
    },
    boxes: {
        display: "flex",
        flexDirection: "row",        
        alignItems: "center",
        width: "315px",
        height: "45px",
        borderRadius: "14px",
        background: "#F7F8F8",
        flexShrink: "0",
        marginBottom: "20px"
    },
    boxContainer: {
        display: "flex",   
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width:"100%"
    },
    text: {
        fontFamily: "Poppins",
        color: "#404040",
        marginLeft: "20px",
        fontSize: "12px",
        fontWeight: 400,
        lineHeigth: "18px",
    },
    icon: {
        marginLeft: "10px"
    },
    arrowButton: {        
        height: "40px",               
        borderRadius: "14px",
        background: "#F7F8F8",
        marginRight: "20px",
        marginLeft: "10px"
    },
}))

export function PersonalInformation() {
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const currentUser = useSelector((state: RootState) => state.user);

    moment.locale(i18n.language);

    useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getAccount();
				setUser(data);
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
	}, []);
    console.log("user", user)
    
    return (
        <div>
            <div className={classes.boxContainer}>
            <div className={classes.topDiv}>
            <Button onClick={() => navigate("/app/profile")} style={{marginRight: "54px"}}><ArrowLeft/></Button>
            <h3 style={{fontFamily: "Poppins", fontWeight: 700, fontSize:"16px", color: "#404040" }}>{t('personalinformation_page.title')}</h3>
            </div>
            <div style={{display: "flex", alignItems: "flex-start", maxWidth:"315px", width:"100%"}}>
            <h3 style={{fontFamily: "Poppins", fontSize:"16px", fontWeight: 600, color:"#404040", width: "100%"}}>{t('personalinformation_page.sub_title')}</h3>
            </div>
            
            {user && user.profile && (
                <div>
            <Box className={classes.boxes}>
                <NameIcon className={classes.icon} />
                <h4 className={classes.text}>{currentUser.first_name} {currentUser.last_name}</h4>
            </Box>            
            <Box className={classes.boxes}>
                <GenderIcon className={classes.icon} />
                <h4 className={classes.text}>{user.profile.gender}</h4>
            </Box>
            <Box className={classes.boxes}>
                <BirthIcon className={classes.icon} />
            <h4 className={classes.text} >{user.profile.date_of_birth}</h4>
        </Box>
        <Box className={classes.boxes}>
        <EmailIcon className={classes.icon} />
            <h4 className={classes.text}>{user.email}</h4>
        </Box>
        </div>
            )}
            </div>            
        </div>
    )
}
