import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: "center",
	},
	label: {
		marginBottom: "0.5rem",
		marginTop: "1rem",
	},
	formControl: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
}));

TimeSetting.propTypes = {
	initialValue: PropTypes.number.isRequired,
	update: PropTypes.func.isRequired,
	label: PropTypes.string,
	unit: PropTypes.string,
};

export function TimeSetting(props: any) {
	const classes = useStyles();
	const { label, unit, update, initialValue } = props;
	let initialMinutes = 0;
	let initialSeconds = 0;
	if (unit === "min") {
		initialMinutes = initialValue;
	}
	if (unit === "sec") {
		const m = Math.floor(initialValue / 60);
		const s = initialValue % 60;
		initialMinutes = m;
		initialSeconds = s;
	}
	if (unit === "min_sec") {
		const m = Math.floor(initialValue / 60);
		const s = initialValue % 60;
		initialMinutes = m;
		initialSeconds = s;
	}
	const [seconds, setSeconds] = React.useState(initialSeconds);
	const [minutes, setMinutes] = React.useState(initialMinutes);

	const calculateTimeFromMinutes = (_minutes) => {
		if (unit === "min") {
			return _minutes;
		}
		if (unit === "min_sec") {
			return _minutes * 60 + seconds;
		}
		return _minutes * 60;
	};

	const calculateTimeFromSeconds = (_seconds) => {
		if (unit === "min") {
			return _seconds / 60;
		}
		if (unit === "min_sec") {
			return _seconds + minutes * 60;
		}
		return _seconds;
	};

	const handleMinutesChange = (e: any) => {
		const value = e.target.value;
		setMinutes(value);
		const m = calculateTimeFromMinutes(value);
		update(m);
	};

	const handleSecondsChange = (e: any) => {
		const value = e.target.value;
		setSeconds(value);
		update(calculateTimeFromSeconds(value));
	};

	const availableMinutes = [];
	for (let i = 0; i <= 90; i++) {
		availableMinutes.push(
			<MenuItem key={i} value={i}>
				{i}
			</MenuItem>
		);
	}
	const availableSeconds = [];
	for (let i = 0; i <= 59; i++) {
		availableSeconds.push(
			<MenuItem key={i} value={i}>
				{i}
			</MenuItem>
		);
	}
	return (
		<Box className={classes.root}>
			<div className={classes.label}>
				{label ?? ""}{" "}
				{unit !== null && unit !== "null"
					? `(${unit.replace("_", "/")})`
					: ""}
			</div>
			<FormControl className={classes.formControl}>
				<InputLabel id="time-minutes-label">Minutes</InputLabel>
				<Select
					labelId="time-minutes-label"
					id="demo-simple-select"
					value={minutes}
					onChange={handleMinutesChange}
				>
					{availableMinutes}
				</Select>
			</FormControl>
			<FormControl className={classes.formControl}>
				<InputLabel id="time-seconds-label">Seconds</InputLabel>
				<Select
					labelId="time-seconds-label"
					id="demo-simple-select"
					value={seconds}
					onChange={handleSecondsChange}
				>
					{availableSeconds}
				</Select>
			</FormControl>
		</Box>
	);
}
