import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';

// The InstallPWAButton component is a button that will trigger the PWA installation prompt.
// It will only be visible if the browser supports PWA and the app is not already installed.

const InstallPWAButton: React.FC = () => {
  const [supportsPWA, setSupportsPWA] = useState<boolean>(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  useEffect(() => {
    
    const handleBeforeInstallPrompt = (e: Event) => {
        e.preventDefault();
        setSupportsPWA(true);
        setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('transitionend', handleBeforeInstallPrompt);
    };
  }, []);

  const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
    const choiceResult = await promptInstall.userChoice;
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
      setPromptInstall(null);
      window.location.reload();
    } else {
      console.log('User dismissed the A2HS prompt');
    }
  };

  return (
    supportsPWA &&  (
      <Button style={{
        backgroundColor: "#0F5486",
        color: "white", borderRadius:"99px",       
        fontFamily: "Poppins",        
        width: 201,
        height: 47,
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "none",
        }}
        variant="contained"
        onClick={handleClick}
        >
          Install App
          </Button>
    )
  );
};

export default InstallPWAButton;
