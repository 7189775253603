import { Box, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: "center",
	},
	label: {
		marginBottom: "0.5rem",
		marginTop: "1rem",
	},
	numberButton: {
		width: "20px",
		color: "black !important",
	},
}));

StringSetting.propTypes = {
	initialValue: PropTypes.number.isRequired,
	update: PropTypes.func.isRequired,
	label: PropTypes.string,
	unit: PropTypes.string,
};

export function StringSetting(props: any) {
	const classes = useStyles();
	const { label, unit, update, initialValue } = props;

	const onChange = (e) => {
		update(e.target.value);
	};
	return (
		<Box className={classes.root}>
			<div className={classes.label}>
				{label ?? ""}{" "}
				{unit !== null && unit !== "null"
					? `(${unit.replace("_", "/")})`
					: ""}
			</div>
			<TextField value={initialValue} onChange={onChange} />
		</Box>
	);
}
