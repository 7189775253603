import { red, teal } from "@mui/material/colors";
import { deepOrange } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import WriteIcon from "@mui/icons-material/Create";
import {
	ArrowLeft,
	Inbox,
	NoPt,
	Tick,
} from '../resources'
import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { getMessages } from "../services/api";
import { RootState } from "../reducers";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";


import WeeklyGoal from "../components/Settings/WeeklyGoal";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(1),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/		
		justifyContent: "center",
		textAlign: "center",	
		width: "100%",
		display: "flex",
		flexDirection: "column",		
		paddingTop: 30
	},
	topDiv: {				
		position: "fixed",
		minWidth: "340px",
		display: "flex",
		flexDirection: "row",				
		alignItems: "center",				
		top: 0,		
		paddingTop: 15,
		height: 70
	},
	fab: {
		display:"flex",
		justifyContent: "flex-start",
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(14),
		right: theme.spacing(2),
		color: theme.palette.common.white,		
		backgroundColor: "#0F5486",
		"&:hover": {
			backgroundColor: "#0F5486",
		},
		fontFamily: "Poppins",
		fontSize: "16px",
		fontWeight: 600,
	},
	stat_card: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
	inline: {
		display: 'inline',
		color: "#404040",
		 fontSize: "12px",
		  fontWeight: 700
	},
	sent_message_avatar: {
		color: "black",
		backgroundColor: "#00A0DF",
		fontWeight: 700,
	},
	received_message_avatar: {
		color: "black",
		backgroundColor: "#00A0DF",
		fontWeight: 700,
	},
	inbox_outbox_button: {
		marginRight: 0,
		marginLeft: 0,
		padding: 0,
		minWidth: 0,
	},
    messageTimeStampRight: {
		opacity: "70%",
		fontFamily: "Poppins",
		position: "absolute",
		fontSize: ".75em",
		fontWeight: "500",
		marginTop: "10px",
		bottom: "-3px",
		right: "5px"
	  },
	  divider: {
		borderColor: "#404040",
		borderBottomWidth: "2px",
		opacity: "70%",
	  },
	  orange: {
		color: "black",
		backgroundColor: deepOrange[500],
		width: theme.spacing(4),
		height: theme.spacing(4)
	  },
	  textContainer: {
		marginTop: 10,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center",
		alignItems: "center"
	  },
	  titletext: {		
		width: 260,
		fontWeight: 500,
		fontSize: 16,
		color: "#404040",
	  },
	  infotext: {
		marginTop: 10,
		width: 250,
		fontWeight: 500,
		fontSize: 12,
		color: "#404040"
	  },


}));

export function MessagesPage() {
	const navigate = useNavigate();	
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [messages, setMessages] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [inboxActive, setInboxActive] = React.useState(true);

	const currentUser = useSelector((state: RootState) => state.user);

	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const handleGoBack = () => {
		navigate(-1)
	}

	const write_message = () => {
		navigate("/app/message/write");
	};
	
	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getMessages();
				setMessages(data);
				console.log(data);

			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if (loading) {
		return <Loading />;
	}

	const personalPT = currentUser.membership.personal_trainer;
	const hasPersonalPT = Boolean(personalPT)
	let messages_list = [];	
	if(messages?.results?.length) {
	const uniqueMessagesMap = new Map();
	messages.results.forEach((msg) => {
		const normalizedSubject = msg.subject.startsWith("Re: ")
		? msg.subject.substring(4)
		: msg.subject;

		const existingMessage = uniqueMessagesMap.get(normalizedSubject);

		if(!existingMessage || moment(msg.sent_at).isAfter(existingMessage.sent_at)) {
			uniqueMessagesMap.set(normalizedSubject, msg);
		}
	})
	console.log("uniqueMessagesMap", uniqueMessagesMap)
	
	messages_list = Array.from(uniqueMessagesMap.values()).map((msg, idx: number) => {
		return(
		<div>
		{/* Inbox */}		
		<div>
			<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={msg.sender_fullname} src={msg.sender_mugshot ? msg.sender_mugshot : "#"} className={classes.received_message_avatar}/> 
			</ListItemAvatar>
			<ListItemText
				// make it clickable
				onClick={() => {navigate(`/app/message/${msg.id}/?t=${msg.thread}`);}}
				//make it bold if it is not read yet 
				primary={msg.read_at ? msg.sender_fullname : <b>{msg.sender_fullname}</b>}

				secondary={
				<React.Fragment>
					<Typography
					component="span"
					variant="body2"
					className={classes.inline}
					color="textPrimary"
					>
					{msg.subject}
					</Typography>
					&nbsp;—&nbsp;{msg.body.split(" ").length > 10 ? `${msg.body.split(" ").slice(0, 10).join(" ")}...` : msg.body}
				</React.Fragment>
				}
			/>
			<div className={classes.messageTimeStampRight}>
				{msg.read_at && (
					<Tick style={{marginRight: 8}} />
				)}
				{moment(msg.sent_at).format('llll')}				
				</div>
			</ListItem>
			<Divider className={classes.divider} variant="inset" component="li" />
		</div>
		
		{/* Outbox */}
		{/* {msg.sender == currentUser.id && !inboxActive &&
		<div>
			<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={msg.recipient_fullname} src={msg.recipient_mugshot ? msg.recipient_mugshot : "#"} className={classes.sent_message_avatar}/> 
			</ListItemAvatar>
			<ListItemText
				// make it clickable
				onClick={() => {navigate(`/app/message/${msg.id}/?t=${msg.thread}`);}}
				//make it bold if it is not read yet 
				primary={msg.read_at ? msg.recipient_fullname : <b>{msg.recipient_fullname}</b>}

				secondary={
				<React.Fragment>
					<Typography
					component="span"
					variant="body2"
					className={classes.inline}
					color="#404040"
					>
					{msg.subject}
					</Typography>
					&nbsp;—&nbsp;{msg.body}
				</React.Fragment>
				}
			/>
			<div className={classes.messageTimeStampRight}>{moment(msg.sent_at).format('llll')}</div>
			</ListItem>
			<Divider className={classes.divider} variant="inset" component="li" />
		</div>
		} */}

		</div>
		)		
	});
}
	console.log("messages_list", messages);

    const current_month = moment().format('MMMM');
	return (
		<div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
		<div className={classes.topDiv}>
        <Button onClick={handleGoBack} style={{marginRight:"54px"}}><ArrowLeft/></Button>
        <h3 style={{justifyContent: "center", fontFamily: "Poppins", fontWeight: 700, fontSize:"16px", color:"#404040"}}>{ t('message_write_page.message') }</h3>
        </div>	
		<div className={classes.root}>
		
						{/* <Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} textAlign={"right"}>
					<Button variant="text" color="inherit" className={classes.inbox_outbox_button} onClick={() => { setInboxActive(true); console.log("Inbox");}}>
					 	<InboxIcon  fontSize="small" htmlColor={inboxActive ? "blue": "grey"}/>
					</Button>
					<Button variant="text" color="inherit" className={classes.inbox_outbox_button} onClick={() => { setInboxActive(false); console.log("Outbox");}}>
						<OutBox fontSize="small" htmlColor={inboxActive ? "grey": "blue"}/>
					</Button>
				</Grid>
			</Grid> */}			
			{messages_list.length > 0 ? (
				<List className={classes.root}>
					{messages_list}
				</List>
				) : (
				hasPersonalPT ? (
				<div style={{
						height: "45vh",
						display: "flex", 
						flexDirection: "column", 
						justifyContent: "center", 
						alignItems: "center"
						}}
						>	 
				<Inbox />
				<Typography className={classes.titletext}>
					{ t('messages_page.no_messages') }
				</Typography>
				<Typography className={classes.infotext} component="span" variant="body2" style={{width:"80%"}} >
					{t('messages_page.no_messages_info')}
					</Typography>		
				</div>
				
				) : (
				<div style={{
						height: "45vh",
						display: "flex", 
						flexDirection: "column", 
						justifyContent: "center", 
						alignItems: "center",
						textAlign: "center"
					}}
					>
				<NoPt />
				<div className={classes.textContainer} style={{}}>
				<Typography className={classes.titletext}>
					{t('messages_page.no_trainer')}
				</Typography>
				<Typography className={classes.infotext} component="span" variant="body2" style={{width:"80%"}} >
					{t('messages_page.no_trainer_info')}
					</Typography>
					</div>
				</div>
				)
				)}	

					
			<Fab
				variant="extended"
				
				aria-label="add"
				className={classes.fab}
				onClick={write_message}
				disabled={!hasPersonalPT}
			>
				<WriteIcon style={{marginRight: 10}}/>
				{ t('messages_page.write_message') }
			</Fab>
			{/* {open ? (
				<StartActivityModal open={open} handleClose={handleClose} />
			) : (
				<Fragment />
			)} */}
		</div>		
		</div>
	);
}

