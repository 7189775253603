import React from "react";
import { Navigate } from "react-router-dom";
import authenticator from "../services/authenticator";

export function LogoutPage() {
	// constructor(props) {
	//   super(props);
	//   this.state = {
	//     logout: false,
	//   };
	// }

	// componentDidMount() {
	//   this.props.dispatch({
	//     type: 'LOGOUT',
	//   });
	//   Authenticator.logout();
	//   this.setState({
	//     logout: true,
	//   });
	// }

	// const { logout } = this.state;

	authenticator.logout();
	localStorage.removeItem("access_token");
	localStorage.removeItem("refresh_token");

	return <Navigate to="/login" />;
}
