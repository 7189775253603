export interface AllActivity {
  id: number;
  picture: string;
  activity_type: number;
  description: string;
  animation: string;
  video: string;
}

export interface Activity {
  id: number;
  name: string;
  active: boolean;
  comments: string;
  description: string;
  use_in_training: boolean;
  picture: string;
  animation: string;
  video: string;
  all_activity: AllActivity
}

export enum ActivityActions {
  LOAD_AVAILABLE_ACTIVITIES = "LOAD_AVAILABLE_ACTIVITIES",
}

interface ActivityActionType<T, P> {
  type: T;
  payload: P;
}

export type ActivityAction =
  | ActivityActionType<typeof ActivityActions.LOAD_AVAILABLE_ACTIVITIES, Activity[]>;
;
