import { CssBaseline, adaptV4Theme, StyledEngineProvider, Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider  } from "@mui/styles";
import * as React from "react";
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { blue } from '@mui/material/colors';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme(adaptV4Theme({
	palette: {
		primary: blue,
		// secondary: {
		// 	light: "#ff5e50",
		// 	main: "#e41e26",
		// 	dark: "#a90000",
		// 	contrastText: "#fff",
		// },
	},
}));
const generateClassName = createGenerateClassName({
	productionPrefix: 'c',
	disableGlobal: true
  });

export function withRoot(Component: any) {
	function WithRoot(props: object) {
		// MuiThemeProvider makes the theme available down the React tree
		// thanks to React context.
		return (
            <StylesProvider generateClassName={generateClassName}>
				<StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <Component {...props} />
                    </ThemeProvider>
                </StyledEngineProvider>
			</StylesProvider>
        );
	}

	return WithRoot;
}
