import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import MonthLegendIcon from '@mui/icons-material/Stop';
import {  Grid, Typography } from '@mui/material';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from "moment";
import { withTranslation } from 'react-i18next';

import { options, palette } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 160,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

let graph_data = null;
let graph_legend;

const generateGraphData = (visitsWeeklyData) => {

  var visits_weekly = visitsWeeklyData;
  var labels = []
  var datas = []
  var background_colors = []
  var gr_labels = []
  var goal_line_data = []

  const date_6moths_ago = moment().subtract(6, 'months')
  var since_year = date_6moths_ago.year();
  var since_week = date_6moths_ago.week();
  var until_year = moment().year();
  var until_week = moment().week();
  var weekly_goal = 4;
  var w, y, i;

  var months_colors = ["#1111d9", "#8899AA", "#88AACC", "#AA9900","#009900","#88BB22","#22FF00","#BBBB00","#CC0000","#882000","#609900","#DDAAFF"];
  var months_names = []

  for(i=0;i<12;i++){
    months_names[i] = moment().month(i).format("MMM");
  }

  for(y=since_year; y<(until_year+1); y++ ){
    let start_week = y===since_year ?  since_week: 1;
    let end_week = y===until_year ?  until_week: 53;
    for(w=start_week; w < end_week+1; w++){
      const label =  y.toString() + "_" + w.toString().padStart(2, '0')
      labels.push( label);
      gr_labels.push( w.toString().padStart(2, '0') );
      datas.push(0);
      background_colors.push('#222222');
      goal_line_data.push(weekly_goal);
    }
  }

  Object.keys(visits_weekly).forEach(function(key) {
    //console.log(key + " " + visits_weekly[key]);
    const _label_idx = labels.indexOf(key);
    if(_label_idx !== -1){
      datas[_label_idx] = visits_weekly[key]['count'];
      background_colors[_label_idx] = months_colors[ visits_weekly[key]['month'] - 1 ];
    }
  });

  graph_legend = months_names.map((item, i) => {
    return (
      <Grid item key={item} lg={1} sm={1} xl={1} xs={2}>
        <div style={{display:"flex", alignItems:"center"}}><MonthLegendIcon style={{ color: months_colors[i] }}/>
        <Typography variant="caption">{item}</Typography>
        </div>
      </Grid>
    );
  });



  graph_data = (workout_weekly_label_translated, goal_line_label_translated) => ({
    labels: gr_labels,
    datasets: [{
        label: workout_weekly_label_translated,
        backgroundColor: palette.primary.main,
        data: datas,
        backgroundColor: background_colors,

      },
      {
        label: goal_line_label_translated,
        data: goal_line_data,
        type: 'line',

      }
    ]
  });
}
  

const WeeklyGoalInTime = props => {
  const { className, ...rest } = props;

  generateGraphData(props.visits_weekly);

  const classes = useStyles();


  let workout_weekly_label_translated = props.t('statistics_page.workout_weekly_label');
  let goal_line_label_translated = props.t('statistics_page.goal_line_label');

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            size="small"
            variant="text"
          >
            {props.t('statistics_page.last_6_months')}
          </Button>
        }
        title={props.t('statistics_page.trainings_weekly_title')}
      />

      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={graph_data(workout_weekly_label_translated, goal_line_label_translated)}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Grid container spacing={1}>
            {graph_legend}
        </Grid>
        {/* <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button> */}
      </CardActions>
    </Card>
  );
};

WeeklyGoalInTime.propTypes = {
  className: PropTypes.string,
  visits_weekly: PropTypes.any
};

export default withTranslation()(WeeklyGoalInTime);
