import * as React from "react";
import { Puff, DNA, ColorRing } from "react-loader-spinner";

export function Loading() {
	return (
		<div
			style={{
				textAlign: "center",
				width: "100%", display: 'flex', justifyContent: 'center'
			}}
		>
			<ColorRing height={80} width={80} colors={['#46a9db', '#46a9db', '#46a9db', '#46a9db', '#46a9db']} />
		</div>
	);
}
