import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Slide, Snackbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from '../resources';
import UserProfile from "../components/Profile/User";
import { pick } from "lodash";
import { updateAccount } from "../services/api";
import { useActions } from "../actions";
import * as UserActions from "../actions/auth";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { RootState } from "../reducers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Language } from "@mui/icons-material";
import Goals from "../components/Settings/Goals";

const useStyles = makeStyles((theme) => ({    
        topDiv: {
            maxWidth: "315px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "strecth",
            paddingTop: 15,
            marginRight: 30            
        },
        arrowButton: {
            height: "40px",               
            borderRadius: "14px",
            background: "#F7F8F8",
            marginRight: "20px",
            marginLeft: "10px"
        },
        selectDiv: {
            display: "flex",
            flexDirection: "column",            
            alignItems: "center",
            width:"100%"
            
        },
        select: {
            width: "306px",
            height: "84px",
            borderRadius: "16px",
            boxShadow: "0px 10px 40px 0px rgba(29, 36, 42, 0.05)",
            marginTop: "20px",
        },
        buttonContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 30

        },
        saveButton: {
            margin: "20px",
            color: "white",
            width: "114px",
            borderRadius: "99px",
            background: "rgba(0, 160, 223, 1)",
            textTransform: "none",
            boxShadow: "0 2px 2px #404040",
            '&:hover': {
                boxShadow: "0 2px 2px #404040",
                backgroundColor: "rgba(0, 160, 223, 1)",
            }

        },
        menuPaper: {
            maxHeight: "300px",
            borderRadius: 14,

        }
}))

const workoutLocations = ['home', 'gym'];
const workoutFrequencies = {
    home: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
    gym: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
}

export function GoalSettings() {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const authActions = useActions(UserActions);
    const currentUser = useSelector((state: RootState) => state.user);
    const [message, setMessage] = React.useState(null);    
    const [homeTrainingFrequency, setHomeTrainingFrequency] = React.useState(currentUser?.goals?.home_training_sessions_per_week ?? 0);
    const [gymVisitFrequency, setGymVisitFrequency] = React.useState(currentUser?.goals?.gym_visits_per_week ?? 0);  

    moment.locale(i18n.language);

    const handleChangeHomeFrequency = (event) => {
        setHomeTrainingFrequency(event.target.value);
      };
    
    const handleChangeGymFrequency = (event) => {
        setGymVisitFrequency(event.target.value);
      };

      const handleCloseMessage = () => {
        setMessage(null)
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}

    const saveGoals = async (goals) => {
        currentUser.goals = {...currentUser.goals, ...goals}
        currentUser.cards= []
        try{
			const updatedUser = await updateAccount(currentUser);
			authActions.setUser(updatedUser);
			setMessage( t('weeklygoals_page.weeklygoals_saved') );        
		}catch(e){
			console.log(e);
		}
    }

    const handleSave = async () => {
        const values = {
            home_training_sessions_per_week: homeTrainingFrequency,
            gym_visits_per_week: gymVisitFrequency
        }
        saveGoals(values);
    }

    console.log("frequencies", gymVisitFrequency, homeTrainingFrequency)

    
    return (
        <div>
        <div className={classes.selectDiv}>
        <div className={classes.topDiv}>
        <Button onClick={handleGoBack} style={{marginRight:"54px"}}><ArrowLeft/></Button>
        <h3 style={{justifyContent: "center", fontFamily: "Poppins", fontWeight: 700, fontSize:"16px", color:"#404040"}}>{t('weeklygoals_page.title')}</h3>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", maxWidth:"315px", width:"100%"}}>
        <h4 style={{fontFamily: "Poppins", fontWeight: 600, fontSize:"16px", color:"#404040"}} >{t('weeklygoals_page.sub_title')}</h4>
        </div>
        
            {workoutLocations[0] === 'home' && (
            <FormControl >
            <InputLabel id="workout-home-label">{t('weeklygoals_page.home_select_label')}</InputLabel>
            <Select 
                className={classes.select}
                style={{borderBottom: "3px solid #F28986", marginBottom: "20px"}}
                labelId="workout-home-label"
                value={homeTrainingFrequency}
                onChange={handleChangeHomeFrequency}
                MenuProps={{
                    PaperProps: {
                        className: classes.menuPaper,
                    }
                }}
                >
                {workoutFrequencies.home.map((frequency) => (
                    <MenuItem key={frequency} value={frequency} style={{borderBottom: "0.5px solid black"}}>
                        {frequency} {t('weeklygoals_page.home_select_menuitem')}                    
                    </MenuItem>
                ))}
                </Select>
                </FormControl>
                )}
            {workoutLocations[1] === 'gym' && (
                <FormControl >
            <InputLabel id="gym-visit-frequency-label">
            {t('weeklygoals_page.gym_select_label')}
            </InputLabel>
    <Select
      className={classes.select}
      style={{borderBottom: "3px solid #00A0DF", marginTop: "20px"}}
      labelId="gym-visit-frequency-label"      
      value={gymVisitFrequency}
      onChange={handleChangeGymFrequency}
      MenuProps= {{
        PaperProps: {
            className: classes.menuPaper
        }
      }}
    >
      {workoutFrequencies.gym.map((frequency) => (
        <MenuItem key={frequency} value={frequency} style={{borderBottom: "0.5px solid black"}}>
          {frequency} {t('weeklygoals_page.gym_select_menuitem')}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)}
        </div>
        <div className={classes.buttonContainer}>
        <Button className={classes.saveButton} onClick={handleSave}>{t('common.save')}</Button>
            {message && <Snackbar
						open={!!message}
						autoHideDuration={3000}
						onClose={handleCloseMessage}
						TransitionComponent={SlideTransition}
						sx={{ bottom: { xs: 60, sm: 0 } }}
					>
						<Alert severity="success">
							{message}
						</Alert>
					</Snackbar>
                }           
                </div>
        </div>
    )
}