import  makeStyles  from '@mui/styles/makeStyles'
import { Button, Theme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {
    ArrowLeft,
    HurLogo,
} from '../resources'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
    logoContainer: {
        height: "80vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
    },
    topDiv: {        
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",        
        paddingTop: 15,
        height: 70,
        background: "white",
        width: "340px",
        top: 0
    },
    arrowButton: {
        height: "40px",               
        borderRadius: "14px",
        background: "rgba(247, 248, 248, 1)",
        marginRight: "54px",
        marginLeft: "10px"
    }
}))

export function PlannerPage() {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();

    moment.locale(i18n.language);

    const handleGoBack = () => {
		navigate('app/dashboard');
	}
    return(
        
        <div style={{height: "100vh", display: "flex", justifyContent: "center"}}>
            <div className={classes.topDiv}>
            <Button onClick={() => navigate('/app/dashboard')} style={{marginRight: "54px"}}><ArrowLeft /></Button>
            <h4 style={{fontSize:"16px", fontFamily: "Poppins", fontWeight: 700, color: "#404040"}}>{t('common.bottom_label_planner')}</h4>
            </div>
        <div className={classes.logoContainer}>
            <HurLogo/>
            <p style={{color: "#404040", fontSize: "16px", fontFamily: "Poppins"}}>{t('planner_page.placeholder')}</p>
        </div>
        </div>
    )
}