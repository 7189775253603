import React from 'react';
import {Pie, Cell, PieChart, ResponsiveContainer } from "recharts";


export default function ProgressPie({overallProgress}) {    
    console.log("overallProgress in pie component", overallProgress);
    let completedColor;
    let remainingColor = '#BDBDBD';    
    // let completedProgress = 0;
    // let remainingProgress = 0;

    // if(overallProgress > 100) {     
    //     completedColor = 'green'
    //     completedProgress = 100;
    //     remainingProgress = 0;
    // } else if(overallProgress < 0) {
    //     console.log("negative progress detected", overallProgress)
    //     // completedColor = "red";        
    //     completedProgress = overallProgress;
    //     remainingProgress = 100 - Math.abs(overallProgress);
    // } else {
    //     // completedColor = "#1976D2"
    //     completedProgress = Math.max(Math.min(overallProgress, 100), 0);
    //     remainingProgress = Math.max(100 - overallProgress, 0);
    // }

    if(overallProgress >= 100) {
        completedColor= "green"
    }else if(overallProgress < 0) {
        completedColor="red"
    } else {
        completedColor="#007CEF"
    }

    const completedProgress = overallProgress >= 0 ? Math.min(Math.max(overallProgress, 0), 100) : Math.abs(overallProgress);
    const remainingProgress = 100 - completedProgress;
    //TODO not fully functioning

    // const completedProgress = Math.max(Math.min(overallProgress, 100), 0)
    // const remainingProgress = isNegative ? Math.abs(overallProgress) : Math.max(100 - completedProgress, 0);

    const data = [
                  { name: "Completed", value: completedProgress, fill: completedColor },
                  {name: 'Remaining', value: remainingProgress, fill: remainingColor}
                ];

    

    // const percentageDisplay = `${completedProgress}%`
    const percentageDisplay = overallProgress > 0 ? `+${overallProgress}%` : `${overallProgress}%`

    console.log("data passed to chart pie chart", data);
    
   
    

    console.log("completedProgress:", completedProgress);
    console.log("remainingProgress:", remainingProgress);
    console.log("completedColor:", completedColor);
    return(        
        <ResponsiveContainer width="100%" height={118}>
            <PieChart>
                <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                dataKey="value"
                startAngle={450}
                endAngle={90}                
                innerRadius={48}
                outerRadius={55}                
                fill={completedColor}
                >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.fill} /> 
                ))}                 
            </Pie>            
            <text x="50%" y="55%" textAnchor="middle" fontSize={26} fontWeight={600} fill='#46A9DB'>                                                             
                 {percentageDisplay}
            </text>
            </PieChart>          
        </ResponsiveContainer>                
    )
}