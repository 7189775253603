import { Alert, Button, Slide, Snackbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import {
    ArrowLeft,
    FlagOfFinland,
    FlagOfUK,
    FlagOfFrance,
    FlagOfGermany,
    FlagOfSweden,
    FlagOfSpain,
    FlagOfDenmark,
    FlagOfItaly,
    FlagOfNetherlands,
    FlagOfNorway,    
    FlagOfSimplifiedChina,
    FlagOfTraditionalChina,
} from '../resources'
import UserProfile from "../components/Profile/User";
import { pick } from "lodash";
import { updateAccount } from "../services/api";
import { useActions } from "../actions";
import * as UserActions from "../actions/auth";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { RootState } from "../reducers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Language } from "@mui/icons-material";

const languages = {
  'en': 'English (English)',
  'de': 'Deutch (German)',
  'fr': 'Français (French)',  
  'fi': 'Suomi (Finnish)', 
  'sw': 'Svenska (Swedish)',
  'no': 'Norsk (Norwegian)',
  'es': 'Español (Spanish)',
  'dk': 'Dansk (Danish)',
  'it': 'Italiano (Italian)',
  'du': 'Nederlands (Dutch)',
  'zh_CN': '简体 (Simplified Chinese)',
  'zh_TW': '繁體 (Traditional Chinese)',
}
const flags = {
    'en': FlagOfUK,
    'fi': FlagOfFinland,
    'de': FlagOfGermany,
    'fr': FlagOfFrance,
    'sw': FlagOfSweden,
    'no': FlagOfNorway,
    'es': FlagOfSpain,
    'dk': FlagOfDenmark,
    'it': FlagOfItaly,
    'du': FlagOfNetherlands,
    'zh_CN': FlagOfSimplifiedChina,
    'zh_TW': FlagOfTraditionalChina
}

const useStyles = makeStyles((theme) => ({ 
    topDiv: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        maxWidth:"340px",
        flexDirection: "row",
        alignItems: "stretch",
        paddingTop: 15,
    },
    arrowButton: {        
        height: "40px",               
        borderRadius: "14px",
        background: "#F7F8F8",
        marginRight: "54px",
        marginLeft: "10px"
    },
    languageButtonDiv: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        height: "70vh",        

    },
    languageButtons: {        
        display: "flex",              
        color: "#404040",
        width: "100%",
        height: "70px",
        borderTop: "1px solid #404040",                      
        fontWeigth: 500,
        fontSize: "16px",
        textTransform: "none",
        fontWeight: 600,        
    },
    lastButton: {
        borderBottom: "1px solid #404040",  
    },
    saveButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: 15
    },
    saveButton: {
        margin: "20px",
        color: "white",
        width: "114px",
		borderRadius: "99px",
		backgroundColor: "rgba(0, 160, 223, 1)",
        textTransform: "none",
        boxShadow: "0 2px 2px #404040",
        '&:hover': {
            boxShadow: "0 2px 2px #404040",
            backgroundColor: "rgba(0, 160, 223, 1)",
        }

    },
}))

export function LanguageSettings() {
    const classes = useStyles();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const authActions = useActions(UserActions);
    const currentUser = useSelector((state: RootState) => state.user);
    const [message, setMessage] = React.useState(null);
    // const [selectedLanguage, setSelectedLanguage] = React.useState(
    //     localStorage.getItem('selectedLanguage')
    // );
    const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language)

    useEffect (() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if(storedLanguage) {
            setSelectedLanguage(storedLanguage);
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n])

    moment.locale(i18n.language);

    const handleClick = (key) => {
        setSelectedLanguage(key);
    }

    const handleSave = async () => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        setMessage(t('profile_page.profile_saved'))
        // const values = {language: selectedLanguage};
        // await saveLanguage(values);
    }
    const handleCloseMessage = () => {
        setMessage(null)
    }

    function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}


    const saveLanguage = async (values) => {
        let languageValues = pick(values, ['language'])
        let _currentUser = {...currentUser.profile, ...languageValues}
        try{
			const updatedUser = await updateAccount(
				_currentUser
			);
			authActions.setUser(updatedUser);
			setMessage(  t('profile_page.profile_saved') );
            i18n.changeLanguage(selectedLanguage);
		}catch(e){
			console.log(e);
		}
    }
    console.log("currentUser")
    return(
        <div>
            <div className={classes.topDiv}>
            <Button onClick={() => navigate("/app/profile")} style={{marginRight: "54px"}}><ArrowLeft/></Button>
            <h3 style={{fontFamily: "Poppins", fontWeight: 700, fontSize: "16px", color: "#404040"}}>{t('languagesettings_page.title')}</h3>
            </div>
            <div className={classes.languageButtonDiv}>
            {Object.keys(languages).map((key, index, array) => {
                const FlagComponent = flags[key]
                return(
                <Button 
                className={`${classes.languageButtons} ${index === array.length - 1 ? classes.lastButton : ''}`}
                 key={index} 
                 value={key} 
                 onClick={() => handleClick(key)}
                 style={{
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 45px",                    
                }}
                 >
                    <div style={{display:"flex", alignItems:"center"}}>
                    {/* <div style={{width: 40, height: 24, marginLeft: 8, marginRight: 20,}}>
                    {FlagComponent && <FlagComponent style={{width:"100%", height:"100%", objectFit:"cover"}} />}
                    </div> */}
                    {languages[key]}
                    </div>
                    <div>
                    <input 
                    style={{width: "20px", height: "20px"}} 
                    type="radio" 
                    checked={selectedLanguage === key} 
                    onChange={() => handleClick(key)} 
                    />
                    </div>
                    </Button>
                    )
            })}
            </div>
            <div className={classes.saveButtonContainer}>
            <Button className={classes.saveButton} onClick={handleSave}>{t('common.save')}</Button>
            {message && <Snackbar
						open={!!message}
						autoHideDuration={3000}
						onClose={handleCloseMessage}
						TransitionComponent={SlideTransition}
						sx={{ bottom: { xs: 60, sm: 0 } }}
					>
						<Alert severity="success">
							{message}
						</Alert>
					</Snackbar>
                }            
                </div>
        </div>
    )
}