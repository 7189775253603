const privacyPolicy = `Ab Hur Oy - Privacy Policy
 
1. General
Ab Hur Oy is the provider of exercise concepts for senior exercise, rehabilitation and wellness. As
a supplier of professional and intelligent exercise equipment, HUR aims to improve its Service by
providing digital solutions for its Customers and its end users.
Ab Hur Oy and companies belonging to the same group (“HUR” or “We” or “data controller”) is
dedicated to protecting the confidentiality and privacy of personal data entrusted to it. This Privacy
Policy applies to personal data processed by HUR as a data controller.
This Privacy Policy explains and governs HUR’s personal data collection, processing and usage
practices. If You are unwilling to accept the practices described in this Privacy Notice, We
recommend that you do not give your personal data to HUR.\n
2. Purposes of the processing
HUR processes personal data for the following purposes:
- customer relationship management and customer service
- fulfilment of rights and obligations of HUR and the customer
- processing for the purposes related to the data controller’s products and services such as
developing, providing, performing, marketing, maintenance and technical support of
products and services
- directing the data controller’s advertising (including newsletter) and allocation of marketing
on basis of customer data via the data controller’s mediums and services
- in connection with the services and products (including websites operated by HUR, HUR
exercise equipment and related online services and mobile devices) (“Service”) offered by
HUR to its customers (also referred as “Customer”) and Customers’ end users (“You”). By
Customer We mean an entity who have acquired the Service from HUR and by an end user
We mean a natural person using the Service as our Customer’s client or as Customer’s
employee.
- to comply with any other obligations deriving from the applicable laws or orders made by
the authorities, including accounting and tax obligations, and product warranties.
Furthermore, We may use Your personal data and other data We collect to: (i) provide, personalize
and improve the Service or this website, for example by providing customized or localized content
and advertising; (ii) ensure technical functioning of the Service and website, develop new features,
functionalities or services and provide support; (iii) to perform research, analyze and compile
statistical information about Your use of the Service or website, including Your interaction with
content made available therein; (iv) communicate with You for purposes related to the
management of our Customer or end user (namely You) relationship (including billing, surveys,
newsletters etc.); (v) send You promotional material and other marketing communications relating
to our or our Customers’ products or services, and (vi) to enable interoperability and data (including
personal data) exchange between Service and third party service providers or product
manufacturers.

The data controller does not use solely automated decision-making, such as automated profiling,
as part of processing personal data.
3. Legal basis for the processing
Legal basis for processing of personal data are legal obligations of the data controller, contract,
consent and legitimate interests of the data controller.
The legitimate interest of the data controller is the legal basis for processing of personal data when
there is a material connection between a data subject and the data controller. Such material
connection is formed, for example, when the data subject has on its own initiative contacted the
data controller, or when the data controller, for example, processes the data subject’s personal
data in connection with a business or co-operation matter between the data subject’s employer and
the data controller.
On basis of its legitimate interest, the data controller may also save to its customer register
personal data of potential clients and their contact persons and representatives which can be, on
reasonable grounds, expected to be interested to acquire products and services provided by the
data controller.
The data controller’s electronic direct marketing may be sent to data subjects who have given their
voluntary consent to electronic direct marketing. When the data subject is requested to give his or
her consent, he or she will be simultaneously informed that withdrawal of consent is possible easily
and at any time. In addition, in accordance with applicable data protection legislation, electronic
direct marketing can also be sent to recipients for whom the data controller can reasonably
consider that the products or services marketed have essential connection with the potential
customer’s area of responsibility or work.
Withdrawal of consent may be done by giving a notice to the data controller or by clicking the
cancelling option, which can be found in every marketing message (“Unsubscribe” link),
whereupon personal data of the data subject will be removed from the data controller’s list
concerning subscribers of electronic direct marketing.
Based on the agreement between HUR and its Customer, and Your consent, HUR processes (for
example collect, use, disclose, transfer and store) Your personal data in connection with the
provision of the Service. You have the right to withdraw your consent at any time by sending an
email to helpdesk@hur.fi. Please see Section 10 below for further details.
4. The Information We Collect
Personal Data
The data controller processes personal data of the following persons:
 Customers of the data controller and their representatives and contact persons
 Representatives and contact persons of the data controller’s subcontractors and suppliers
 Potential customers
 Users of Services
 Other stakeholders (such as job applicants, other co-operation partners)
The following personal data of the data subjects, relevant on the basis of the above mentioned
purposes of processing, are processed, such as:

 Name
 E-mail address
 Phone number
 Company and title
 Name and business ID of the company and contact person
 Additional information provided by the data subject himself/herself
 Information based on customer relationship, such as contact history, feedback and follow-
up information
Furthermore, when using the Service We may ask You to provide certain personal data related to
Your usage of the Service. Processed personal data may include, for example:
Your name, contact details (such as address, e-mail and phone number), occupation, gender,
language and other information that personally identifies You or relates to the use of the Service,
such as weight, height and training data etc. submitted or made available by You later in the
course of Your subscription to our Service.
Regular sources of personal data
HUR may collect personal data when data subject interacts with Us or our business partners
pursuant to our business, websites, products or services, buys our products or services,
participates in a survey or campaigns, submits information through a web solution, by e-mail or by
any other way.   
Personal data is received and gathered:
 directly from the data subjects and customers, users and other stakeholders when
purchasing or using our products or services, visiting our websites and otherwise
interacting with HUR;
 from HUR’s group companies and distribution partners from whom a data subject (or
organisation represented by the data subject) has purchased the products or services;
 by using cookies and related technology or third-party analytical software; and
 from public sources such as population information system, trade register or other similar
external sources or from HUR’s customer database and third-party contact information
providers.
Website usage, Log files and Cookies
We may collect certain information received automatically when using our website. Such
information refers to Your computer (or to another device) and visits to our website. This
information includes (i) information about Your interactions with our website, including advertising,
products and services which are displayed, offered or otherwise made available on the website; (ii)
technical data, such as the referring website address, access times, Your IP address, device
identifier, network and computer performance, browser type, language and operating system; and
(ii) if you have given your explicit consent (such as via our cookie banner or otherwise), Your
location information. We may use cookies and similar technologies to collect this information (see
Section 8 below). We may store and use this information in accordance with this Privacy Policy.
Information Collected by Our Customers

Our Customers can use the online tools and functionalities We provide in connection with the
Service, as well as tools provided by third parties, to collect information similar to that referred to in
Section 2.1 and 2.2 above when You use the Service. HUR does not control its Customers’ use of
these tools or functionalities. In our agreements with our Customers We require that they comply
with applicable privacy laws and that they will provide any required notices regarding sharing Your
personal data with us collected by using the Service. In this instance, HUR is the data processor
and our Customer is the data controller under a data processing agreement executed between
HUR and our Customer.
5. Sharing of Your Information
We will not sell Your personal data any third party.
However, We may share the data (whether personal data or non-personally identifiable data) with
our customers, affiliates, subcontractors, suppliers or distributors for the above purposes. In case
We share the data, We will ensure that personal data is shared and processed only to the extent
necessary and only for the intended purpose. Such third parties are also required to maintain the
confidentiality of Your personal data.
We may use aggregated and anonymized information about end users for the purposes of internal
research and to determine trends or metrics concerning how end users are engaging with our
Service or website. We may disclose reports of such general trends publicly or share mentioned
information with our affiliates or business partners for product development, without disclosing end
users’ personal data.
Transfers of Personal Data to Other Countries
In general, HUR aims to provide its Services and process Your personal data by using operators
and services located within the EU or EEA. However, under certain circumstances, HUR’s
Services may be provided by using operators, services and servers located elsewhere, which
means that Your personal data may be transferred to another country. Such transfers may include
the disclosure of personal data outside the EU or EEA to countries with different legislations
concerning the processing of personal data than defined by the Finnish law, for example, to the
United States. Even in such cases, HUR shall ensure the appropriate level of data protection
regarding Your personal data by agreeing on matters related to the confidentiality and processing
of personal data in accordance with the law, for example, by using the standard contractual
clauses adopted by the EU Commission and other measures to ensure that the processing of
personal data is performed in accordance with this Privacy Policy, legislation and good practices.
Third Party Social Network Services and External Websites
The Service may enable You to promote and share Your own data (including personal data) with
third party social network services (such as Facebook, Twitter etc.). The use of Your content and
related information (including personal data, if any) on these services or products is subject to the
terms and conditions and privacy policies of the relevant third party service providers or product
owners, and We do not have access or control over nor do this Privacy Policy apply to such third
party services.
Our websites may include links to third party websites. Please note that We do not control, and are
not responsible for the content or practices of such websites, which are subject to their own terms
and conditions, and privacy policies.
6. Retention periods of personal data

The data controller will process and retain personal data only as long it is necessary for
compliance with a legal obligation or for the purposes of processing which have been determined
in advance. Personal data which has become redundant, i.e. personal data which the data
controller no longer has legal basis to retain or process, will be deleted on regular basis in
accordance with the data controller’s internal data protection policy.
7. Data Security
We take reasonable administrative, physical and electronic measures designed to protect the
information (including personal data) that We collect or receive from or about end users of our
Service from unauthorized access, use or disclosure. For example, when You enter personal data
on forms on our Service, We encrypt this data using relevant encrypting methods (for example
SSL) or other technologies. We have to inform You, however, that no method of transmitting
information over the internet or storing it is completely secure. For that reason, We cannot
guarantee the absolute security of any information (including personal data).
Access to personal data is given exclusively to authorized persons on HUR’s side or its
Customers, affiliates, subcontractors, suppliers or distributors, for instance, in connection with the
supply of support services or other services relating to the Service. We have ensured that our
employees do not process personal data on a wider basis than required for the provision of the
Service. HUR’s personnel have received training on the confidentiality obligations and restrictions
relating to the processing of personal data. HUR ensures that its employees who process personal
are committed to confidentiality and under an appropriate statutory obligation of confidentiality.
HUR ensures that its employees who process personal data are in compliance with the Personal
Data Safety and Security measures as defined in GDPR (for EU and Schengen area).
8. Information about Cookies and Similar Technologies
A “cookie” is a small text file that is placed on Your computer or other terminal device by a web
server when You visit a website. Cookies are used to personalize Your browsing experience by
helping a website recognize Your device when You return to that website or to analyze how You
use our Service or website. Each cookie is individually assigned to Your terminal device and only
the server that has placed the cookie is able to read it later on. A cookie does not run programs
and it cannot be used to deliver viruses or other malware, or harm Your terminal device or data
files. An individual user is not identifiable merely through the use of cookies or similar technologies.
We use cookies and other similar technologies (such as Google Analytics and Google Ads) to
operate, analyze and improve the Service or our website to better serve our Customers and end
users, and to provide You with personalized information in accordance with this Privacy Policy.
You have the option to accept all cookies or reject other than necessary technical cookies on our
cookies banner upon the first visit from Your device to our website. You can set Your browser to
accept, block or delete all or certain cookies. You can later change Your cookie preferences.
Please note that if You choose to block cookies, this may prevent the proper functioning of the
Service or our website.
9. Rights of the data subject
Right of access by the data subject to his or her data
The data subject has the right to obtain confirmation from the data controller as to whether or not
personal data concerning him or her are being processed, and, where that is the case, access to
the personal data and a copy of the personal data processed.

Right to rectification and erasure
Within the limits of the legislation, the data subject has the right to obtain the rectification or
erasure of inaccurate, unnecessary, defective or outdated personal data concerning him or her.
Right to lodge a complaint with a supervisory authority
The data subject has the right to lodge a complaint with a supervisory authority, if the data
controller is infringing applicable legislation concerning personal data processing and data
protection. The supervisory authority in Finland is the Data Protection Ombudsman,
www.tietosuoja.fi.
Right to withdraw consent
In case where processing of personal data is based on the consent of the data subject, the data
subject has the right to withdraw his or her consent by notifying the data controller.
Right to object
The data subject has the right to object, on grounds relating to his or her particular situation, at any
time processing of personal data concerning him or her and having its legal ground on the
legitimate interest of the data controller, including profiling.
The data controller will no longer process personal data unless the data controller demonstrates
compelling legitimate grounds for the processing which override the interests, rights and freedoms
of the data subject or for the establishment, exercise or defence of legal claims.
Where personal data are processed for direct marketing purposes, the data subject has the right to
object at any time of processing data concerning him or her for such marketing, which includes
profiling to the extent that it is related to such direct marketing. Where the data subject objects to
processing for direct marketing purposes, the personal data will no longer be processed for such
purposes.
Right to data portability
The data subject has the right to receive data concerning him or her, which he or she has provided
to the data controller, in a structured, commonly used and machine-readable format and has the
right to transmit those data to another data controller, in cases where processing is based on
consent or contract and the processing is carried out by automated means.
When exercising the above described right to data portability, the data subject has the right to have
personal data transmitted directly from one data controller to another, where technically feasible.
Responsibilities of the data controller
The data controller will inform the data subject about all measures that have been taken on basis
of a request, without undue delay and in any case within one month having received such a
request. The time limit may be prolonged for at most two months where needed, taking into
consideration quantity and complexity of the requests made. The data controller will inform the
data subject about such possible prolongment within one month having received the request, as
well as about the reasons for delay. If the data subject has presented his or her request
electronically, the information must be provided electronically when possible, unless the data
subject requests otherwise.
If the data controller does not carry out the measures based on the data subject’s request, the data
controller must immediately and at the latest within one month since having received the request,
notify the data subject about the reasons for this, as well as about the possibility to lodge a
complaint with a supervisory authority and to use other legal remedies.

10. Contacts
All questions about this Privacy Policy and other contacts, such as requests to exercise rights of
the data subject or withdraw consent, should be made by e-mail to the address helpdesk@hur.fi.
You may also contact us in person in our offices or in writing:
Ab Hur Oy
Patamäentie 4
67100 KOKKOLA, FINLAND
+358-6-8325500
helpdesk@hur.fi
Please note that prior to fulfilling a request we have a right as well as an obligation to verify your
identity, due to which we must be able to recognize you in an adequate manner.  
Please note that We may periodically update this Privacy Policy. We endeavour to carry out
reasonable means to inform you of any changes and their effects in due time beforehand.
Therefore, We advise you to review this Privacy Policy always after becoming aware of changes
regarding this Privacy Policy. This Privacy Policy was last updated on 1 February 2022.
`;
export default privacyPolicy