import Axios from "axios";

const getAccount = async () => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(`${serverUrl}aux-api/users/me/`);
	return response.data;
};

const updateAccount = async (data: any) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.patch(
		`${serverUrl}aux-api/users/me/`,
		data
	);
	return response.data;
};

const getVisits = async (userId: number, page = 1, perPage: number) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(
		`${serverUrl}aux-api/visits/?page=${page}&page_size=${perPage}&user__id=${userId}&o=-login`
	);
	return response.data;
};

const getLatestTrainingSession = async (userId: number) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(
		`${serverUrl}aux-api/visits/?o=-login&page_size=1&user__id=${userId}&logout__isnull=True`
	);
	return response.data;
};

const getDashboardData = async () => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(
		`${serverUrl}aux-api/users/me/client-summary/`
	);
	return response.data;
};

const getTrainings = async (userId: number) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(
		`${serverUrl}aux-api/trainings/?&user__id=${userId}&active=True`
	);
	return response.data;
};

const getVisitById = async (id: number) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(`${serverUrl}aux-api/visits/${id}/`);
	return response.data;
};

const getVisitActivityById = async (activityId: number) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(
		`${serverUrl}aux-api/visits_activities/${activityId}/`
	);
	return response.data;
};

const updateVisitActivity = async (activityId: number, data: any) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.put(
		`${serverUrl}aux-api/visits_activities/${activityId}/`,
		data
	);
	return response.data;
};

const createVisit = async (data: any) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.post(`${serverUrl}aux-api/visits/?use_training`, data);
	return response.data;
};

const updateVisit = async (visitId: number, data: any) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.patch(
		`${serverUrl}aux-api/visits/${visitId}/`,
		data
	);
	return response.data;
};


const getAvailableActivities = async (page = 1, totalFetched = 0) => {
	const perPage = 500;
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.get(
		`${serverUrl}aux-api/available_activities/?page_size=${perPage}&page=${page}`
	);
	const total = response.data.count;
	totalFetched += response.data.results.length;
	const rows = response.data.results;
	let nextRows = [];

	if (totalFetched < total) {
		nextRows = await getAvailableActivities(++page, totalFetched);
	}
	return [...rows, ...nextRows];
};

// messages related
const getMessages = async (thread: number = 0, id: number = 0, order:string="-id") => {
	const serverUrl = localStorage.getItem("server_url");
	
	let fullAddress = thread ? `${serverUrl}aux-api/messages/?thread=${thread}` : `${serverUrl}aux-api/messages/`;
	if (id) {
		fullAddress = `${serverUrl}aux-api/messages/?id=${id}`
	}
	fullAddress +=  (thread || id) ? `&page_size=100&o=${order}` : `?page_size=100&o=${order}`;

	const response = await Axios.get(
		fullAddress
	);
	return response.data;
};

const createMessage = async (data: any) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.post(`${serverUrl}aux-api/messages/`, data);
	return response.data;
};

const updateMessage = async (messageId: number, data: any) => {
	const serverUrl = localStorage.getItem("server_url");
	const response = await Axios.patch(
		`${serverUrl}aux-api/messages/${messageId}/`,
		data
	);
	return response.data;
};


const updateToken = async () => {
	const serverUuid = localStorage.getItem("server_uuid");
	const refreshToken = localStorage.getItem("refresh_token");
	const response = await Axios.post(
		"https://yg73pjd0x8.execute-api.eu-north-1.amazonaws.com/dev/refresh",
		{
			uuid: serverUuid,
			token: refreshToken,
		}
	);
	return response.data;
};

export {
	getAccount,
	updateAccount,
	getVisits,
	updateToken,
	getAvailableActivities,
	getVisitById,
	getLatestTrainingSession,
	getTrainings,
	createVisit,
	updateVisit,
	getDashboardData,
	getVisitActivityById,
	updateVisitActivity,
	getMessages,
	createMessage,
	updateMessage
};
