import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTrainings, getAvailableActivities, createVisit } from "../services/api";
import { RootState } from "../reducers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import {
    BackArrow
} from '../resources'
import { HurLoadingScreen } from "../components";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    activityContainer: {
        maxWidth: '100%',
		minWidth: '100%',
		borderRadius: "15px",
		boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        padding: theme.spacing(2)
    },
    activities: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center"
    },
    title: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#404040",
    },
    topDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 20,
        paddingTop: 15,
        height: 70,
        width: "100%"
    },
    image: {
        backgroundSize: "cover",
    },
    startTraining: {
        height: "44px",        
        borderRadius: "99px",
        backgroundColor: "#0F5486",
        color: "white",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Poppins",
        paddingLeft: 10,
        paddingRight: 10
    },
    startButtonContainer: {
        position: "fixed",
        zIndex: 100,
        justifyContent: "center",
        bottom: "10%"
    },
    card: {
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        minWidth: "140px", 
        minHeight: "80px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "15px"
    }

}))

export function TrainingPreviewPage() {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [program, setProgram] = React.useState(null)
    const [availableActivities, setAvailableActivities] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const {trainingId} = useParams();
    const navigate = useNavigate();
    const currentUser = useSelector((state: RootState) => state.user);
    const serverUrl = localStorage.getItem("server_url");
    const handleGoBack = () => {
        navigate(-1);
    }
    moment.locale(i18n.language);

    useEffect(() => {
        async function initData() {
            setLoading(true);
            try {
                const trainings = await getTrainings(currentUser.id)                
                const selectedProgram = trainings.results.find(t => t.id === parseInt(trainingId));
                setProgram(selectedProgram)
                const activities = await getAvailableActivities()              
                setAvailableActivities(activities);                
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        }
        initData();
    }, [trainingId, currentUser.id]);
    console.log("Programs", program)
    console.log("activities", availableActivities)

    const getActivityImage = (activityId) => {
        const cdnAddress = "https://cdn01.hursmarttouch.com"; //"https://d3ldm7xf6jmsyw.cloudfront.net";
		
        const activityDetails = availableActivities.find(a => a.id === activityId)
      
        const image = activityDetails.picture && activityDetails.picture.length > 1
        ? activityDetails.picture
        : activityDetails.all_activity.picture

        const imageUrl = `${image}`.startsWith('activity_images') ? `${cdnAddress}/media/${image}` : `${serverUrl}media/${image}`;

        return imageUrl
    }    

    if(loading) {
        return <HurLoadingScreen />
    }

    return(
        <div style={{ height: "100%", width: "100%", padding: "20x" }}>
        <div className={classes.topDiv}>
        <Button onClick={handleGoBack} style={{paddingLeft:0, marginRight: "54px"}}><BackArrow /></Button>
        <h3 className={classes.title}>{program.name} {t('common.preview_training')}</h3>        
        </div>                                      
            <Grid display="flex" justifyContent= "center" container spacing={2} paddingBottom="5px">                   
                {program.activities
                .sort((a, b) => a.order_index - b.order_index)
                .map((activity) => (                     
                    <Grid item xs={5} sm={5} key={activity.id}>
                    <Card className={classes.card}>
                      <CardMedia
                  className={classes.image}
                  component="img"                  
                  image={getActivityImage(activity.activity)}
                  alt={activity.activity_name}
                  style={{
                    borderBottom: "0.5px solid #7B6F72"
                  }}
                />                                     
                    <Typography gutterBottom align="center" color="#404040" fontSize="12px" fontWeight="500">{activity.activity_name}</Typography> 
                    </Card>                 
                    </Grid>                  
                ))}                           
                </Grid>                       
                </div>   
        
    )
}

