import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Snackbar,
	TextareaAutosize,
	Typography,
	Box,
	Grid,
	
} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { Loading } from "../components";
import { RootState } from "../reducers";
import { getVisitActivityById, updateVisitActivity } from "../services/api";
import { IntegerSetting } from "../components/IntegerSetting";
import { FloatSetting } from "../components/FloatSetting";
import { TimeSetting } from "../components/TimeSetting";
import { StringSetting } from "../components/StringSetting";
import  Timer  from "../components/Timer"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
	Check,
	ArrowLeft
} from '../resources'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';

import "moment/locale/fi"
import "moment/locale/de"
import "moment/locale/fr"
import "moment/locale/zh-cn"
import "moment/locale/zh-tw"
import moment from "moment";
import 'moment-duration-format';



const cdnAddress = "https://cdn01.hursmarttouch.com"; //"https://d3ldm7xf6jmsyw.cloudfront.net";

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 275,
		marginTop: "0rem",
		// marginBottom: "1rem",	
		// position: "relative",		
		// [theme.breakpoints.down(700)]: {
		// 	minHeight: "800px",
			
		// }
		
	},
	rootContainer: {
		[theme.breakpoints.up('xs')]: {
			minHeight:"750px"
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: "800px"
		},
		[theme.breakpoints.down('md')]: {
			minHeight: "800px",
		}
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	arrowButton: {        
        height: "40px",               
        borderRadius: "14px",
        background: "#F7F8F8",
        marginRight: "40px",
        marginLeft: "10px"
    },
	backButton: {
		margin: "1rem",
	},
	titleContainer: {
		display: "flex",
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		alignContent: "center",
		textAlign: "center",
		height: "24px",		
		background: "linear-gradient(314.13deg, rgba(70, 169, 219, 0.75) 22.62%, rgba(25, 118, 210, 0.75) 56.24%)",
		justifyContent: "center",
	},
	markdowncontainer: {
		background:  "linear-gradient( 314deg, rgba(70,169,219,0.75) 22.62%, rgba(25, 118, 210, 0.75) 56.24%);",
		display: "flex",
		justifyContent: "center",
		height: "24px",
		color: "black",
	},
	markTitle: {
		color: "#1D1617",
		fontSize: "14px",
		fontWeight: 700
	},
	title: {
		fontSize: "14px",
		fontWeight: 700,
		color: "#1D1617",				
	},
	media: {
		// height: 240,
		backgroundSize: "contain",
	},
	player: {
		[theme.breakpoints.up('xs')]: {
			maxHeight: "90%",
			maxWidth: "100%"			
		},
		[theme.breakpoints.down('md')]: {
			
		}
	},
	pos: {
		marginBottom: "0px",
	},
	statusButtons: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		
	},
	statusButton: {
		width: "205px",		
		borderRadius: 10,
		padding: "12px 18px",
		fontSize: "13px",
		marginBottom: "1rem",		
		border: "1px solid black",		
	},
	notes: {
		width: "100%",
		height: "100%",
		fontSize: "16px",
		border: "2px solid rgba(0, 160, 223, 1)",
		borderRadius: "12px",
		padding: "12px",
	},
	saveNotesButton: {
		margin: "0 auto",
		width: "114px",
		borderRadius: "99px",		
		background: "rgba(0, 160, 223, 1)",
		textTransform: "none",
		color: "black",
		boxShadow: "0 2px 2px #404040",
		fontweight: 500

		
	},
	saveButtonContainer: {		
		display: "flex",
		justifyContent: "flex-end",
	},
	instructionButton: {
			backgroundColor: "rgba(0, 160, 223, 1)",            		
			opacity: "50%",
            height:"40px",
            color: "black",
			borderRadius: 0,
			width: "100%",
            // borderTopLeftRadius: "30px",
            // borderBottomLeftRadius: "30px",
            
			'&:hover': {
				backgroundColor: "rgba(242, 137, 134, 0.7)"
			},
			'&:focus': {
				backgroundColor: "rgba(242, 137, 134, 0.7)",
				outline: "none"
			}
	},
	resultsButton: {
			backgroundColor: "rgba(0, 160, 223, 1)",   			
			opacity: "50%",
            height:"40px",
			borderRadius: 0,
            color: "black",                        
            width: "100%",
			'&:hover': {
				backgroundColor: "rgba(242, 137, 134, 0.7)"
			},
			'&:focus': {
				backgroundColor: "rgba(242, 137, 134, 0.7)",
				outline: "none"
			}
            
	},
	notesButton: {
		backgroundColor: "rgba(0, 160, 223, 1)",            		
		opacity: "50%",
		color: "black",
		borderRadius: 0,
		// borderTopRightRadius: "30px",
		// borderBottomRightRadius: "30px",
		width: "100%",
		height: "40px",
		'&:hover': {
			backgroundColor: "rgba(242, 137, 134, 0.7)"
		},
		'&:focus': {
			backgroundColor: "rgba(242, 137, 134, 0.7)",
			outline: "none"
		}
	},

	clickedButton: {
		background: "rgba(0, 160, 223, 1)",
		opacity: 1,		
		// height: "50px",
		width: "100%",
		'&:hover': {
			backgroundColor: "rgba(0, 160, 223, 1)"
		},
		'&:focus': {
			backgroundColor: "rgba(0, 160, 223, 1)",
			outline: "none"
		}
		
	},
	instructionContainer: {
		// padding: "0vh 3vw 0vw", 
		padding: "0 2%",
		paddingTop: "0", 
		paddingBottom: "0",
		outline: "2px solid rgba(0, 160, 223, 1)", 
		borderRadius: 12, 
		overflowY: "auto", 
		maxHeight: "35vh", 
		minHeight: "20vh",		
		margin:"10px", 
		marginTop: 1, 
		marginBottom:0,

		[theme.breakpoints.down("lg")]: {
			maxHeight: "30vh",
		},		
		[theme.breakpoints.down("md")]: {
			maxHeight: "25vh"
		},
		[theme.breakpoints.up("sm")]: {
			maxHeight: "10vh",
		},		
	},
	markdonetext: {
		textOverflow: "ellipsis", 
		whiteSpace: "nowrap", 
		marginRight: 5, 
		marginLeft: 10, 
		fontFamily: "Poppins", 
		fontWeight: 600, 
		fontSize: "14px", 
		color:"black"
	},
}));

function formatTime(seconds: number): string {

	const duration = moment.duration(seconds, 'seconds');
  
	if (seconds < 60) {
	  return duration.format('ss [sec]');
	} else if (seconds < 3600) {
	  if (seconds % 60 === 0) {
		if(seconds < 600){
			return duration.format('m [min]');
		}
		return duration.format('mm [min]');
	  }				
	  return duration.format('mm [min] ss [sec]');
	} else {
	  return duration.format('hh [hours] mm [min] ss [sec]');
	}
}

export function ActivityPage(props) {
	const navigate = useNavigate();
	const location = useLocation();	
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	let { activityId } = useParams<{ activityId: string }>();
	const [activity, setActivity] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [updatingActivity, setUpdatingActivity] = React.useState(false);
	const [message, setMessage] = React.useState(null);
	const [results, setResults] = React.useState(null);
	const [notes, setNotes] = React.useState("");
	const [showVideo, setShowVideo] = React.useState(false);
	const [clickedButton, setClickedButton] = React.useState('Instructions');	
	// const [initialTime, setInitialTime] = React.useState(null);
	const [shouldShowTimer, setShouldShowTimer] = React.useState(false);
	const [lowerView, setLowerView] = React.useState('instructions');

	// get parameters from url
	const params = new URLSearchParams(location.search);
	const edit = params.get("e");

	
	// set language
	moment.locale(i18n.language);


	const handleNotesChange = (event: any) => {
		setNotes(event.target.value);
	};

	const handleShowVideoClick = () => {
		let _showVideo = !showVideo;
		setShowVideo(_showVideo);
	};

	const handleTimerEnd = () => {
		console.log("Timer ended");
	}

	const handleGoBack = () => {
		navigate(-1);
	}


	// const handleChangeLowerViewClick = () => {
	// 	let _newLowerView;

	// 	if(activityType !== 3){
	// 		_newLowerView = lowerView == 'instructions' ? 'settings' : lowerView == 'settings' ? 'notes' : 'instructions';
	// 	}else{
	// 		if(edit){
	// 			_newLowerView = lowerView == 'instructions' ? 'settings' : lowerView == 'settings' ? 'notes' : 'instructions';
	// 		}else{
	// 			_newLowerView = lowerView == 'instructions' ? 'notes' : 'instructions';
	// 		}
	// 	}
		
	// 	setLowerView(_newLowerView);
	// };

	const handleChangeLowerViewClick = (newLowerView: string) => {
		
		setLowerView(newLowerView);
	}

	const availableActivities = useSelector((state: RootState) => {
		return state.activities;
	});

	
	

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const activity = await getVisitActivityById(
					parseInt(activityId, 10)
				);

				setActivity(activity);
				
				console.log("activity", activity);

				if (activity?.settings?.parameters) {
					if (
						activity.results &&
						typeof activity.results === "object"
					) {
						setResults(activity.results);
					} else {
						setResults(activity.settings);
					}
				} else {
					if (
						activity.results &&
						typeof activity.results === "object"
					) {
						//console.log("activity.results", activity.results);
						setResults(activity.results);
					} else {
						//console.log("activity.settings", activity.settings);
						//setResults(activity.settings);
					}
				}
				setNotes(activity.user_comments);
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
	}, [activityId]);	

	console.log("Activities log", results)	

	if (loading || !activity) {
		return <Loading />;
	}

	const estimateStatus = () => {
		if (activityType === 7) {
			if (activity.settings.time > results.time) {
				return "U";
			}
			if (activity.settings.time < results.time) {
				return "O";
			}
			if (activity.settings.time === results.time) {
				return "F";
			}
		}
		if (activityType === 8 || activityType === 9) {
			if (activity.settings.performances > results.performances) {
				return "U";
			}
			if (activity.settings.performances < results.performances) {
				return "O";
			}
			if (activity.settings.performances === results.performances) {
				return "F";
			}
		}
		if (activityType === 56) {
			if (
				checkIfParametersAreUnderPerformed(
					activity.settings.parameters,
					results.parameters
				)
			) {
				return "U";
			}
			if (
				checkIfParametersAreOverPerformed(
					activity.settings.parameters,
					results.parameters
				)
			) {
				return "O";
			}
		}
		return "F";
	};

	const checkIfParametersAreUnderPerformed = (parameters, results) => {
		let underPerformed = false;
		for (const [idx, param] of parameters.entries()) {
			if (param.value > results[idx].value) {
				underPerformed = true;
				continue;
			}
		}
		return underPerformed;
	};

	const checkIfParametersAreOverPerformed = (parameters, results) => {
		let overPerformed = false;
		for (const [idx, param] of parameters.entries()) {
			if (param.value < results[idx].value) {
				overPerformed = true;
				continue;
			}
		}
		return overPerformed;
	};
	const onStatusUpdate = async (update: any, go_back: boolean = false, update_status: boolean = true ) => {
		setUpdatingActivity(true);
		activity.status = update_status ? estimateStatus() : activity.status;
		console.log("activity", activity);
		try {
			const updatedActivity = await updateVisitActivity(
				parseInt(activityId, 10),
				Object.assign({}, activity, update)
			);
			setActivity(updatedActivity);
			setMessage(t('activity_page.message.updated_successfully'));
			if (go_back) {
				navigate(-1);
			}
		} catch (e) {
			console.log(e);
		}
		setUpdatingActivity(false);
	};
	const setAsOverPerformed = () => {
		onStatusUpdate({
			status: "O",
		});
	};
	const setAsNotPerformed = () => {
		onStatusUpdate({
			status: "N",
		});
	};
	const setAsUnderPerformed = () => {
		onStatusUpdate({
			status: "U",
		});
	};
	const setAsFullyPerformed = () => {
		onStatusUpdate({
			status: "F",
		});
	};
	const saveNotes = () => {
		onStatusUpdate({
			user_comments: notes,
		}, false, false);
	};
	const setAsUnderPerformedAndReturn = () => {

		if([1, 3, 7, 8, 9, 56].includes(activityType) ){
			activity["results"] = results;
		}

		onStatusUpdate({
			status: "U",
		}, true);
	};

	const setAsFullyPerformedAndReturn = () => {

		if([1, 3, 7, 8, 9, 56].includes(activityType) ){
			activity["results"] = results;
		}

		onStatusUpdate({
			status: "F",
		}, true);
	};
	const setAsOverPerformedAndReturn = () => {
		if([1,3,7,8,9,56].includes(activityType)) {
			activity["results"] = results;
		}
		onStatusUpdate({
			status: "O",
		}, true)
	}

	const getStatusName = () => {
		switch (activity.status) {
			case "O":
				return t('statistics_page.over_performed'); 
			case "N":
				return t('statistics_page.not_performed');
			case "U":
				return t('statistics_page.under_performed');
			case "F":
				return t('statistics_page.fully_performed');
			case "S":
				return t('common.started');
		}
		return "Unknown";
	};

	const handleCloseMessage = () => {
		setMessage(null);
	};

	const activityDetails = availableActivities.find((availableActivity) => {
		return availableActivity.id === activity.activity;
	});
	const image =
		activityDetails.picture && activityDetails.picture.length > 1
			? activityDetails.picture
			: activityDetails.all_activity.picture;
	const imageFallback = activityDetails.all_activity.picture;
	const activityType = activityDetails.all_activity.activity_type;

	console.log("activityType", activityType)
	
	// use cdn for video
	const video = 
		activityDetails.video && activityDetails.video.length > 1
		? activityDetails.video
		: !!activityDetails.all_activity.video ? 
		 `${cdnAddress}/media/${activityDetails.all_activity.video}`
		 : null;	


	let parameters = [];

	if (activityType === 1) {
		// const handleChange = (value: number) => {
		// 	setResults({
		// 		time: value,
		// 	});
		// };
		// create parameters as a table of sets
		parameters = [
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200, fontFamily: "Poppins" }} aria-label="simple table">
				<TableHead>
					<TableRow>
					<TableCell>Set</TableCell>
					<TableCell align="right">Load</TableCell>
					<TableCell align="right">Reps</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{activity.settings.sets?.map((set, index) => (
					<TableRow
						key={index+1}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row">
						{index+1} 
						</TableCell>
						<TableCell align="right">{activity.results?.sets?.[index]?.load ? 
							(`${(parseInt(activity.results.sets[index].load) / 1000).toFixed(1)} / ${(parseInt(set.load) / 1000).toFixed(1)}  kg`) : 
							`${(parseInt(set.load) / 1000).toFixed(1)}  kg`}
						</TableCell>
						<TableCell align="right">
						{activity.results?.sets?.[index]?.reps ? 
							(`${parseInt(activity.results.sets[index].reps)} / ${parseInt(set.reps)}`) : 
							`${parseInt(set.reps)}`}
						</TableCell>

					</TableRow>
					))}
				</TableBody>
				</Table>
			</TableContainer>
		];
	};

	// aerobic exercises
	console.log('results', results);
	if (results && results != "{}" && [2,13,15,16,17].includes(activityType) ) {

		// create table of poms
		parameters = [
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
					<TableCell sx={{ width: "10%" }}>#</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Load</TableCell>
					<TableCell align="right" sx={{ width: "60%" }}>Time</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>HR</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Rpm</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{activity.results?.poms?.map((pom, index) => (
					<TableRow
						key={index+1}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row">
						{index+1} 
						</TableCell>
						<TableCell align="right">
							{pom.res}&nbsp;{activity.settings?.units}
						</TableCell>
						<TableCell align="right">
						{formatTime(pom.time)}
						</TableCell>
						<TableCell align="right">
						{pom.HR}
						</TableCell>
						<TableCell align="right">
						{pom.rpm}
						</TableCell>
					</TableRow>	
					))}
				</TableBody>
				</Table>
			</TableContainer>
		];
	};

	if(!results && [2,13,15,16,17].includes(activityType) ){
		// create table of poms
		parameters = [
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
					<TableCell sx={{ width: "10%" }}>#</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Load</TableCell>
					<TableCell align="right" sx={{ width: "60%" }}>Time</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>HR</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Rpm</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{activity.settings?.steps?.map((step, index) => (
					<TableRow
						key={index+1}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row">
						{index+1} 
						</TableCell>
						<TableCell align="right">
							{step.res}&nbsp;{activity.settings?.units}
						</TableCell>
						<TableCell align="right">
						{formatTime(step.time)}
						</TableCell>
						<TableCell align="right">
						{step.HR}
						</TableCell>
						<TableCell align="right">
						{step.rpm}
						</TableCell>
					</TableRow>	
					))}
				</TableBody>
				</Table>
			</TableContainer>
		];
	};

	if (results && activityType === 7) {
		const handleChange = (value: number) => {
			setResults({
				time: value,
			});
		};
		parameters = [
			<IntegerSetting
				key="Time"
				label="Time (minutes)"
				initialValue={results.time}
				update={handleChange}
			/>,
		];
	}
	if (results && activityType === 8) {
		const handleChange = (value: number) => {
			setResults({
				performances: value,
			});
		};
		parameters = [
			<IntegerSetting
				key="Performances"
				label="Performances"
				initialValue={results.performances}
				update={handleChange}
			/>,
		];
	}
	if (results && activityType === 9) {
		const handleChange = (value: number) => {
			setResults({
				performances: value,
			});
		};
		const load = (activity.settings.init_load / 1000).toFixed(0);
		const label = `Performances ${load} kg`;
		parameters = [
			<IntegerSetting				
				key="Performances"
				label={label}
				initialValue={results.performances}
				update={handleChange}
			/>,
		];
	}
	if (activityType === 56 && Array.isArray(results.parameters)) {
		const handleChange = (idx: number) => {
			return (value: number) => {
				//console.log("Param", results.parameters);
				//console.log("Act settings", activity.settings);
				const updatesResultsParamaters = [...results.parameters];
				updatesResultsParamaters[idx].value = value;
			};
		};
		parameters = results.parameters.map((result, idx: number) => {
			if (result.type === "integer") {
				return (
					<IntegerSetting
						key={result.id}
						unit={result.unit}
						label={result.name}
						initialValue={result.value}
						update={handleChange(idx)}
					/>
				);
			}
			if (result.type === "float") {
				return (
					<FloatSetting
						key={result.id}
						unit={result.unit}
						label={result.name}
						initialValue={result.value}
						update={handleChange(idx)}
					/>
				);
			}
			if (result.type === "time") {
				return (
					<TimeSetting
						key={result.id}
						label={result.name}
						unit={result.unit}
						initialValue={result.value}
						update={handleChange(idx)}
					/>
				);
			}
			return (
				<StringSetting
					key={result.id}
					label={result.name}
					unit={result.unit}
					initialValue={result.value}
					update={handleChange(idx)}
				/>
			);
		});
	}
	

	const serverUrl = localStorage.getItem("server_url");

	// use cdn
	const imageUrl = `${image}`.startsWith('activity_images') ? `${cdnAddress}/media/${image}` : `${serverUrl}media/${image}`;
	
	console.log('imageUrl', imageUrl)
	console.log('image', `${serverUrl}media/${image}`)		
	const imageFallbackUrl = `${serverUrl}media/${imageFallback}`;
	const onMediaFallback = (event) => {
		event.target.src = imageFallbackUrl;
	};

	function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}

const parameter = activity?.settings?.parameters?.find(param => param.type === 'time') || {};
const initialTime = (
	activity?.settings?.parameters &&
	activity.settings.parameters.find(param => param.type === 'time')?.value
) || null;
const unit = parameter.unit || 'sec'
console.log("initialTime",initialTime);
const paramName = (
	activity?.settings?.parameters &&
	activity.settings.parameters.find(param => param.name === 'Hold' || param.name === 'Hold for')
) || null
const isStrechActivity = (activity) => {
	return activity?.settings?.parameters?.some(param =>
	 param.type === 'time' && param.name === 'Hold' || param.name === 'Hold for'
	)
}
const filteredActivities = activity?.settings?.parameters?.filter(activity => activity.name && activity.type.name === 'Hold')

	const BandOfButtons = (props: any) => {
		const shouldShowInstructionButton = true;
		const shouldShowNotesButton = true;
		const shouldShowResultsButton = !isStrechActivity(activity);
		// const shouldShowResultsButton = activityDetails.all_activity.activity_type !== 56;
		

		const handleClick = (buttonType: string) => {
			let _newLowerView;
			switch(buttonType) {
				case 'Instructions':
				    _newLowerView='instructions';
					break;
				 case "Results":
					_newLowerView= 'settings';
					break;
				 case "Take Notes":
					_newLowerView = 'notes';
					break;
					default:
					break;
			}
			handleChangeLowerViewClick(_newLowerView);
			setClickedButton(buttonType);
		}
		return(
			<div style={{ maxWidth: "650px", display: "flex", padding: "0 20px", justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto"}}>
				{shouldShowInstructionButton && (
					<Button 
						
						className={`${classes.instructionButton} ${clickedButton === 'Instructions' ? classes.clickedButton : ''}`}						
						onClick={() => handleClick('Instructions')}						
						>
							{t('activity_page.instruction')}
						</Button>
				)}
				{shouldShowResultsButton && (
					<Button 
					className={`${classes.resultsButton} ${clickedButton === 'Results' ? classes.clickedButton : ''}`}
						onClick={() => handleClick('Results')}
						>
							{t('activity_page.results')}
						</Button>
				)}
				{shouldShowNotesButton && (
					<Button 
					className={`${classes.notesButton} ${clickedButton === 'Take Notes' ? classes.clickedButton : ''}`}
						onClick={() => handleClick('Take Notes')}
						>
							{t('activity_page.notes')}
						</Button>
				)}
					</div>
			
		)
	}

	// const initialTime = activityDetails?.all_activity?.activity_type === 56
	// 	? activity?.settings?.parameters[0]?.value ?? null : null;

// 	const initialTime = activity?.settings?.parameters?.value?.time
//   ? activity?.results?.parameters?.value?.time
//   : null;
	

	

	const propagatedDescription = () => {
		/* all ____ and ___ in description shall be exchanged into iterated parameters values */
		let _description = activityDetails.description?.length > 0 ? activityDetails.description : activityDetails['all_activity'].description;
		let count = 0;
		_description = _description.replace(/____|___/g, (match) => {
			count++;
			if(results?.parameters?.length > count -1 ){
				return results.parameters[count-1].value;
			}
			return String("___");
		});
		
		return (<span style={{whiteSpace: 'pre-line'}}> {_description}</span>);
	}

	var this_player:any;

	function loop_video(){
		this_player.playing = true;
		this_player.seekTo(1, 'seconds');

		//console.log("loop");

	}

	function handleProgress(state){
		//console.log('onProgress', state);
		//console.log('this_player', this_player);
		// if(state['playedSeconds']>72){
			
		// 	this_player.seekTo(67, 'seconds');
		// 	this_player.playing = true;
		// }

	}

	function ref(player){
		this_player = player;
	}
	return (
		<div style={{ flex: 1, maxHeight: "100vh", maxWidth: "900px", overflowY: "auto"}} className={classes.rootContainer}>
			<Box className={classes.root}>
				{/* <Box className={classes.titleContainer}>
					<Typography
						className={classes.title}
						color="textSecondary"
						gutterBottom
					>
						Activity Details
					</Typography>
				</Box> */}
				
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<div style={{ padding: "1vh 3vw 0vh 3vw", display: "flex", flexDirection:"row" }}>
						<Button onClick={handleGoBack}><ArrowLeft style={{marginRight: "54px", flexShrink: 0}}/></Button>
							<Typography variant="h6" component="h2" style={{alignContent:"center"}}>
								{activity?.activity_name}
							</Typography>
							{/* <Typography className={classes.pos} color="textSecondary">
								{t('common.status')}: {getStatusName()}
							</Typography> */}

						</div>
					</Grid>
					<Grid item xs={12} style={{paddingTop:0, marginBottom: 0}}>											
							<Box
								style={{ position: 'relative', justifyContent: "center", alignItems: "center", height: "auto", marginBottom: 0  }}																
							>
							{!video ? ( 
							<Box
							sx={{ position: 'relative' }}
							justifyContent="center"
							alignItems="center"
							display="flex"
							flexDirection="column"
						>
						<CardMedia 
							component="img"
							className={classes.media}
							image={imageUrl}
							title={activity.activity_name}
							onError={onMediaFallback}
							sx={{ position: 'relative', maxWidth: "50vh" }}
						/>						
					</Box>					
					) : (
								<div
									className="player-wrapper"
									style={{
										position: "relative",									
										width: "100%",
										height: "auto",
										maxHeight: "50vh",
										overflow: "hidden",				
										marginBottom: 0,		
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>									
									<ReactPlayer
										className={classes.player}
										id="video-player"
										ref={ref}
										url={video}
										controls={true}
										// width="100%"
										// height="100%"															
										playing={false}
										muted={true}
										light = {false}									
										config={{
											vimeo: {
											playerOptions: {
												playsinline: true,
											}
											}
										}}
										onEnded={loop_video}
										onProgress={handleProgress}
									/>
								</div>
								)}								
								<div>																								
								</div>																
								<BandOfButtons margin={'0px 0px 0px 0px'} position={'relative'}></BandOfButtons>	
							</Box>												
					</Grid>					
					
					{ lowerView == 'instructions' && <Grid item xs={12} style={{paddingTop: 0, maxHeight: "calc(100vh - 180px)"}}>
						<div className={classes.instructionContainer}>						
						{activity.instruction && 
						<Typography component="p" paddingTop={0}>
							<i>{activity.instruction}</i>
						</Typography>						
						}
						<Typography variant="h6" component="h6">
							{t('activity_page.instruction')}
						</Typography>						
						<Typography component="p" >
						     {propagatedDescription()}
						</Typography>
						</div>													
					</Grid>
					}		
				</Grid>
			</Box>

			{lowerView == 'settings' && activityType !== 3 ? (
				<Card className={classes.root} variant="outlined">
					<Box className={classes.titleContainer}>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
						>
							{ (activity.results && activity.results != "{}") ? t('activity_page.results') : t('settings_page.title') }

						</Typography>
					</Box>					
						<CardContent sx={{ opacity: !edit ? 0.9 : 1, pointerEvents:!edit ? "none" : "auto", color: "#404040"}} >{parameters}</CardContent>					
				</Card>
			) : (
				""
			)}

			{ lowerView == 'settings' && <Grid item xs={12}>
				{(edit &&  [3, 7, 8, 9, 56].includes(activityDetails.all_activity.activity_type)) && <Card  variant="outlined">
					<Box className={classes.markdowncontainer}>
						<Typography
							className={classes.markTitle}
							color="#404040"
							gutterBottom
						>
							{t('activity_page.mark_as')}
						</Typography>
					</Box>
				
					<CardContent>
						<div className={classes.statusButtons}>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsNotPerformed}
								style={{
									backgroundColor: "#9e9e9e",
									color: "black",
									textTransform: "none",
									boxShadow: "0 2px 2px #404040"
								}}
							>
								{t('activity_page.not_performed')}
							</Button>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsUnderPerformedAndReturn}
								style={{
									backgroundColor: "#FFDA17",
									color: "black",
									textTransform: "none",
									boxShadow: "0 2px 2px #404040"
								}}
							>
								{t('activity_page.under_performed')}
							</Button>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsFullyPerformedAndReturn}
								style={{
									backgroundColor: "#8bc34a",
									color: "black",
									textTransform: "none",
									boxShadow: "0 2px 2px #404040"
								}}
							>
								{t('activity_page.fully_performed')}
							</Button>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsOverPerformedAndReturn}
								style={{
									backgroundColor: "#03a9f4",
									color: "black",
									textTransform: "none",
									boxShadow: "0 2px 2px #404040"
								}}
							>
								{t('activity_page.over_performed')}
							</Button>																			
						</div>
					</CardContent>
				
				</Card>
			}
			</Grid>
			}

			{ lowerView == 'notes' && <Box>
				{/* <Box className={classes.titleContainer}>
					<Typography
						className={classes.title}
						color="textSecondary"
						gutterBottom
					>
						{t('activity_page.notes')}
					</Typography>
				</Box> */}
				
					<CardContent sx={{p: 1.5, paddingTop: 0}}>
						<TextareaAutosize
							aria-label="minimum height"
							minRows={5}
							onChange={handleNotesChange}
							value={notes}
							placeholder={t('activity_page.type_your_notes_here')}
							className={classes.notes}
						/>
					</CardContent>
					<Box style={{display:"flex", justifyContent:"flex-end"}}>
						<Button
							color="primary"
							variant="contained"	
							onClick={saveNotes}
							className={classes.saveNotesButton}
							style={{background: notes.trim() ? '' : '#ADA4A5'}}
							disabled={!notes.trim()}
						>
							{t('common.save')}
						</Button>
					</Box>
					</Box>
			}
			{lowerView == 'instructions' && initialTime && paramName && edit && (
				<>					
				<Box display="flex" height="20px" alignItems= "center" justifyContent="space-between">
				<Box display="flex" alignItems="center" justifyContent="center" minWidth={180} marginTop= "18px">								
				<h4 className={classes.markdonetext}>{t('activity_page.mark_done')}</h4>
				<Check onClick={() => { setAsFullyPerformedAndReturn(); } } />
				</Box>
				<Box marginRight="50" zIndex= "10">							
				<Timer											
				initialTime={initialTime}
				unit={unit}
				onTimerEnd={handleTimerEnd}
				/>
			</Box>
		</Box>			
			
			</>
		)}
			
			<Box sx={{ margin: '0px 0px 50px 0px' }}></Box>		
			{message && <Snackbar
				open={!!message}
				autoHideDuration={4000}
				onClose={handleCloseMessage}
				TransitionComponent={SlideTransition}
				sx={{ bottom: { xs: 60, sm: 0 } }}
			>
				<Alert severity="success">
					{message}
				</Alert>
			</Snackbar>
			}
		</div>
	);
}
