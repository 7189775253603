import { History } from "history";
import { combineReducers } from "redux";
import { Activity, User, Visit } from "../model";
import * as activityReducer from "./activity";
import * as userReducer from "./user";
import * as visitReducer from "./visit";

export interface RootState {
	visits: Visit[];
	totalVisits: number;
	user: User;
	activities: Activity[];
	unreadMessagesNumber: number;
}

export default (history: History) =>
	combineReducers({
		...userReducer,
		...visitReducer,
		...activityReducer,
	});
