//import palette from 'theme/palette';
export const palette = {
  primary: {
    main: "#ffddff"
  },
  text: {
    primary: "#111",
    secondary: "#bbb"
  },
  divider: "#dddddd"
}



export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: false,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: 
      {
        barPercentage: 1.0,
        categoryPercentage: 0.95,
        gridLines: {
          drawBorder: false,
          display: false,
        }
      },
    yAxes:
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
          display: true,
        },
        gridLines: {
          display: false,
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,

        }
      }
  }
};
