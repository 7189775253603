import { Box, Button, ButtonGroup } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: "center",
	},
	label: {
		marginBottom: "0.5rem",
		marginTop: "1rem",
	},
	numberButton: {
		width: "20px",
		color: "black !important",
	},
}));

FloatSetting.propTypes = {
	initialValue: PropTypes.number.isRequired,
	update: PropTypes.func.isRequired,
	label: PropTypes.string,
	unit: PropTypes.string,
};

export function FloatSetting(props: any) {
	const classes = useStyles();
	const { label, unit, update, initialValue } = props;
	const [value, setValue] = React.useState(initialValue);

	const decrease = () => {
		if (value === 0) {
			return;
		}
		const newValue = parseFloat((value - 0.1).toFixed(1));
		setValue(newValue);
		update(newValue);
	};

	const increase = () => {
		const newValue = parseFloat((value + 0.1).toFixed(1));
		setValue(newValue);
		update(newValue);
	};
	return (
		<Box className={classes.root}>
			<div className={classes.label}>
				{label ?? ""}{" "}
				{unit !== null && unit !== "null"
					? `(${unit.replace("_", "/")})`
					: ""}
			</div>
			<ButtonGroup
				variant="contained"
				color="primary"
				aria-label="minus - add"
			>
				<Button onClick={decrease}>
					<RemoveIcon />
				</Button>
				<Button className={classes.numberButton} disabled>
					{value}
				</Button>
				<Button onClick={increase}>
					<AddIcon />
				</Button>
			</ButtonGroup>
		</Box>
	);
}
