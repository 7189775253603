import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import img1 from '../resources/img/OnboardingOne.jpeg'
import img2 from '../resources/img/OnboardingStep21.jpg'
import img3 from '../resources/img/onboarding6.png'
import onboardingText from '../resources/img/messageHand_lower.png'
import onboardingChart from '../resources/img/chartHand_lower.png'
import onboradingBackground3 from '../resources/img/onboardingbackground3.png'
import { Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
    root: {           
        height: "100vh",        
        display: "flex",        
        flexDirection: "column", 
        justifyContent: "space-between",
        position: "relative",
        
    },
    imageContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",           
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",        
        transition: "opacity 0.3s ease-in-out"
    },
    imageContainer1: {
        backgroundSize: "cover",
        backgroundPosition: "30% center"
    },
    imageContainer2: {
        backgroundSize: "cover",
        
    },
    imageContainer4: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",                   
        backgroundColor: "#00A0DF",                            
        width: "100vw",        
        height: "100vh",
        backgroundPosition: "center 20%",                
        transition: "opacity 0.3s ease-in-out",
        padding: 20,  
    },
    textImage: {
        position: "absolute",              
        left: "50%",
        bottom: "18%",
        transform: "translateX(-50%)",
        maxWidth: "100vw",
        maxHeight: "80vh",
        width: "auto",
        height: "auto",
        objectFit: "contain", 
        [theme.breakpoints.down('sm')]: {
            bottom: "25%"
        }
    },
    imageContainerStep3: { 
        flex: 1,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",                   
        backgroundColor: "#00A0DF",                            
        width: "100vw",        
        height: "100vh",
        backgroundPosition: "center",                
        transition: "opacity 0.3s ease-in-out",        
    },
    chartImage: {   
      position: "absolute",              
      left: "50%",
      bottom: "18%",
      transform: "translateX(-50%)",
      maxWidth: "100vw",
      maxHeight: "80vh",
      width: "auto",
      height: "auto",
      objectFit: "contain",   
      [theme.breakpoints.down('sm')]: {
        bottom: "25%"
      }
    }, 
    imagePadding: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        paddingBottom: 230,
        transition: "opacity 0.3s ease-in-out" 
    },
    footer: {        
        background: "white",        
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "40px",
        borderTop: "2px solid #0F5486",
        height: "266px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignText: "center",
        textAlign: "center",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
    footerWithBorder: {
        background: "white",
        borderTop: "2px solid #0F5486",
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "40px",
        height: "266px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignText: "center",
        position: "absolute",
        bottom: 0,        
    },
    textContainer: {
        alignItems: "center",
        textAlign: "center",                
        maxWidth: "100%",
        height: "90%",
        padding: 10,          
    },
    title: {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        margin: 0,
        color: "#404040",        
        width: "100%",                
        transition: "textColor 0.5s",
        lineHeight: "18px",
    },
    text: {
        
        fontFamily: "Poppins",
        fontSize: "14px",
        margin: 0,
        marginTop: 10,
        height: "106px",    
        transition: "textColor 0.5s",
        marginBottom: 45
    },
    next: {
        fontFamily: "Poppins",
        color: "#0F5486",
        position: "absolute",
        right: 10,  
        bottom: 35,
        cursor: "pointer",
        fontWeight: 600,
        
        
    },
    back: {
        fontFamily: "Poppins",
        color: "#0F5486",
        position: "absolute",
        bottom: 35,
        left: 10,
        cursor: "pointer",
        fontWeight: 600
    },
    skip: {
        zIndex: 100,
        position: "absolute",
        top: 9,
        right: 10,
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#0F5486",        
        height: "36px",
        background: "white",
        borderRadius: "15px",
        boxShadow: "0 2px 2px #404040",
        textTransform: "none"
    },
    skipButtonWhite: {
        position: "absolute",
        top: 20,
        right: 30,
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "white"
    },
    dots: {                 
        marginBottom: 21,
        position: "absolute",
        bottom: "20px",        
    },
    dot: {
        margin: "0 5px",
        width: "6px",
        height: "6px",
        background: "#404040",
        '&:hover': {
            background: "#404040",
        },
        marginRight: "5px",        
        
    },
    activeDot: {
        borderRadius: "62px",
        width: "30px",
        height: "6px",
        background: "#0F5486",
        '&:hover': {
            background: "#0F5486",
        },
        margin: "0 5px",
        transition: "transform 0.3s, width 0.3s",
    },
}))



export function OnboardingPage() {
    const classes = useStyles();
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [currentStep, setCurrentStep] = React.useState(0);   
    const [fade, setFade] = React.useState(true);
    const currentUser = useSelector((state: RootState) => state.user);    
    console.log("currentUser", currentUser)
    moment.locale(i18n.language);

    const onboardingSteps = [
        {
            image: img1,
            title: t("onboarding_page.slide_1_title"),
            description: t("onboarding_page.slide_1_text"),
        },
        {
            image: img2,
            title: t("onboarding_page.slide_2_title"),
            description: t("onboarding_page.slide_2_text")
        },
        {                        
            title: t("onboarding_page.slide_3_title"),
            description: t("onboarding_page.slide_3_text")
        },
        {             
            title: t("onboarding_page.slide_4_title"),
            description: t("onboarding_page.slide_4_text"),
        }
    ]
    console.log("onboardingSteps", onboardingSteps)

    const handleNext = () => {
        if(currentStep < onboardingSteps.length - 1) {
            setFade(false)
            setTimeout(() => {
            setCurrentStep(currentStep + 1);
            setFade(true);
        }, 300)
        } else {
            navigate("/app/dashboard")
        }
    }

    const handleBack = () => {
        if(currentStep > 0) {
            setFade(false);
            setTimeout(() => {
            setCurrentStep(currentStep - 1);
            setFade(true);
        }, 300)
        }
    }


    const handleSkip = () => {
        navigate("/app/dashboard")
    }

    const getClassName= ({currentStep}) => {
        switch (currentStep) {
            case 0:
                return `${classes.imageContainer} ${classes.imageContainer1}`
            case 1:
                return `${classes.imageContainer} ${classes.imageContainer2}`
            case 2:                      
                return `${classes.imageContainerStep3}`;            
            case 3:
                return `${classes.imageContainer4}`;
            default:
                return classes.imageContainer
        }
    }

    const { image, title, description } = onboardingSteps[currentStep];
    
    return (
        <div className={classes.root}>
            {currentStep < onboardingSteps.length - 1 && (
        <Button className={classes.skip} onClick={handleSkip}>{t("common.skip")}</Button>
        )}
        <div
            className={getClassName({currentStep})}         
            style={{
                backgroundImage: currentStep === 2 || currentStep === 3 ? `url(${onboradingBackground3})` : `url(${image})`,                
                opacity: fade ? 1 : 0.7,
                marginBottom: currentStep < 2 ? '230px' : '0', 
                             
            }}
        >
            {currentStep === 2 && (
                <img 
                    src={onboardingChart}
                    alt="Onboarding chart"
                    className={classes.chartImage}/>                    
            )}
            {currentStep === 3 && (
                <img 
                    src={onboardingText}
                    alt="onboarding text"
                    className={classes.textImage}/>
            )}
        {/* <div className={currentStep === 2 ? classes.imageContainerStep3 : classes.imageContainer}
         style={currentStep === 2
            ? {backgroundImage: `url(${onboardingChart})`, opacity: fade ? 1 : 0.7} :
              {backgroundImage: `url(${image})`, opacity: fade ? 1 : 0.7, paddingBottom: currentStep < 2 ? '230px' : '0'}}> */}
        {/* <img src={image} alt="onboarding" className={classes.image} style={{opacity: fade ? 1 : 0.7}}/> */}
        </div>
        <div className={currentStep === onboardingSteps.length - 1 ? classes.footerWithBorder : classes.footer}>
        <div className={classes.textContainer}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.text}>{description}</p>       
        </div>        
        <div className={classes.dots}>
            {onboardingSteps.map((_, index) => (
                <IconButton key={index} className={currentStep === index ? classes.activeDot : classes.dot}></IconButton>
            ))}        
        </div>
        {currentStep > 0 && (
            <span className={classes.back} onClick={handleBack}>{t("common.back")}</span>
        )}
        <span className={classes.next} onClick={handleNext}>{currentStep === onboardingSteps.length - 1 ? t("common.start") : t("common.next")}</span>
        </div>
        </div>
        
    )

}

