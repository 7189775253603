import {
	Avatar,
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	Fab,
	Typography,
	Grid,
	Button
} from "@mui/material";
import { green, teal } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import get from "lodash/get";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import {
	ArrowLeft,
	GymTag,
	HomeTag
} from '../resources'
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import StartActivityModal from "../components/StartActivityModal";
import { RootState } from "../reducers";
import { getLatestTrainingSession, getVisits, getTrainings } from "../services/api";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import  {useNavigate}  from "react-router-dom";

import "moment/locale/fi"
import "moment/locale/de"
import "moment/locale/fr"
import "moment/locale/zh-cn"
import "moment/locale/zh-tw"


const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 0,
		
	},
	latestWorkouts: {	
		borderRadius: 16,		
		height: "78px",
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',		
	},
	visitTypeHome: {
		borderBottom: '4px solid #F28986'
	},
	visitTypeGym: {
		borderBottom: '4px solid rgba(70, 169, 219, 0.75)'
	},
	avatar: {
		width: '55px',
        height: '55px',
        borderRadius: '50%',        
        objectFit: 'cover',
		marginLeft: 5
	},
	tag: {
		padding: 0,		
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: "#46a9db",
		"&:hover": {
			backgroundColor: teal[600],
		},
	},
	topButtonContainer: {
		display: "flex",
        justifyContent: "center", 
		flexDirection: "column",
		alignItems: "stretch",
		width: "100%"
		
	},
	topButtonsDiv: {
		display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width:315, 
        height: 60,
        backgroundColor: "#F7F8F8",
        borderRadius: 99,
        border: '1px solid rgba(247,248,248,1)',
        marginTop: 10,  
		margin: "auto" 	
	},
	topButtons: {
		width: '130px',
        height: '40px',
        border: '1px solid rgba(247, 248, 248, 1)',
        borderRadius: 99,
        color: "#404040",
		textTransform: "none"
	},
	arrowButton: {
		height: "54px",               
        borderRadius: "14px",
        background: "rgba(247, 248, 248, 1)",
        marginRight: "40px",
        marginLeft: "10px" 
	}
}));

const getTrainingPicture = (trainingProgramId, trainingPrograms) => {
	if(!Array.isArray(trainingPrograms)) {
		return null;
	}
	const trainingProgram = trainingPrograms.find(program => program.id === trainingProgramId);
	return trainingProgram ? trainingProgram.training_picture : null;
}


const serverUrl = localStorage.getItem("server_url");
	const cdnAddress = "https://cdn01.hursmarttouch.com"						
	
	const fallBackImages = [
		"TEK_1633.jpg",
		"TEK_1650.jpg",
		"TEK_2013.jpg",
		"TEK_1944.jpg",
		"TEK_1768.jpg",
		"TEK_1987.jpg",
		"TEK_2261.jpg",
	]	

	function getRandomFallBackImageUrl() {
		const randomIndex = Math.floor(Math.random() * fallBackImages.length)
		return `${cdnAddress}/media/training_images/gym_training/${fallBackImages[randomIndex]}`
	}

	function getImageUrl(visit, trainingPrograms, fallbackImageCache) {
		const trainingPicture = getTrainingPicture(visit.training_program, trainingPrograms)
		if(trainingPicture && trainingPicture.trim() !== "") {
			return `${serverUrl}/media/${trainingPicture}`
		}
		const image = visit.training_picture;
		if(image && image.trim() !== "") {
			return `${serverUrl}/media/${image}`
		} else {
			if(!fallbackImageCache[visit.id])			 {
				fallbackImageCache[visit.id] = getRandomFallBackImageUrl()
			}
			return fallbackImageCache[visit.id]	
		}
	}

export function TrainingSessionsPage() {
	const navigate = useNavigate();
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const perPage = 100;
	const [isNextPageLoading, setNextPageLoading] = React.useState(false);
	const [hasNextPage, setHasNextPage] = React.useState(true);
	const [loadedPage, setLoadedPage] = React.useState(0);
	const [visits, setVisits] = React.useState([]);
	const [trainingsData, setTrainingsData] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [imageCache, setImageCache] = React.useState({});
	
	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	const [lastVisit, setLastVisit] = React.useState(null);

	const currentUser = useSelector((state: RootState) => state.user);

	useEffect(() => {
		async function initData() {
			try {
				const [visitResponse, trainingsData] = await Promise.all([
					getLatestTrainingSession(currentUser.id),
					getTrainings(currentUser.id)
				]);
				const lastVisit = get(visitResponse, "results[0]");
				if (lastVisit) {
					setLastVisit(lastVisit);
				}
				if(trainingsData) {
					setTrainingsData(trainingsData.results)
				}
			} catch (e) {}
		}
		initData();
		// eslint-disable-next-line
	}, []);	
	console.log("trainingsData", trainingsData)

	async function loadMoreRows({ startIndex, stopIndex }) {
		setNextPageLoading(true);

		const nextPage = loadedPage + 1;
		const nextVisits = await getVisits(currentUser.id, nextPage, perPage);

		if (nextVisits.results.length + visits.length >= nextVisits.count) {
			setHasNextPage(false);
		}
		setNextPageLoading(false);

		setLoadedPage((prev) => {
			return ++prev;
		});

	

		
		setVisits((prevVisits) => {
			const updateVisits = [...prevVisits, ...nextVisits.results];

			const newImageCache = {...imageCache};
			nextVisits.results.forEach((visit) => {
				if(!visit.training_picture || visit.training_picture.trim() === "") {
					newImageCache[visit.id] = getRandomFallBackImageUrl()
				}
			});
			setImageCache(newImageCache);
			return updateVisits		
		});

		return;
	}	

	console.log("visits", visits )

	return (
		<div style={{ display: "flex", width: "100%", height: "100vh"}}>			 	
			<div style={{ flex: "1 1 auto", height: "100%" }}>
			<div className={classes.topButtonContainer}>	
			<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
			<div style={{display: "flex", flexDirection:"row", alignItems:"center", maxWidth: "315px", width: "100%", marginRight: 20}}>				
                    <Button onClick={() => navigate('/app/dashboard')} style={{marginRight: "54px"}}><ArrowLeft/></Button>
                    <h4 style={{ fontFamily: "Poppins", fontWeight: 700, fontSize:"16px", color:"#404040", margin:0}}>{t('common.bottom_label_progress')}</h4>
                </div>
				</div>						
        <div className={classes.topButtonsDiv}>
            <Button 
            className={classes.topButtons}            
			style={{
				marginRight: '10px',
				background: "rgba(15, 84, 134, 1)",
				color: 'white'
		}}            
            >
                {t('progress_page.history_button')}
            </Button>
            <Button 
            className={classes.topButtons}            
			onClick={() => navigate('/app/progress')}
            >
                {t('progress_page.insight_button')}
            </Button>
        </div>
        </div>
				<ExampleWrapper
					hasNextPage={hasNextPage}
					isNextPageLoading={isNextPageLoading}
					items={visits}
					loadNextPage={loadMoreRows}
					imageCache={imageCache}
					setImageCache={setImageCache}
					trainingData={trainingsData}
				/>
				
			</div>
		</div>
	);
}



function ExampleWrapper({
	// Are there more items to load?
	// (This information comes from the most recent API request.)
	hasNextPage,

	// Are we currently loading a page of items?
	// (This may be an in-flight flag in your Redux store for example.)
	isNextPageLoading,

	// Array of items loaded so far.
	items,

	// Callback function responsible for loading the next page of items.
	loadNextPage,

	imageCache,

	trainingData,

	setImageCache
}) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	
	// If there are more items to be loaded then add an extra row to hold a loading indicator.
	const itemCount = hasNextPage ? items.length + 1 : items.length;
	// Only load 1 page of items at a time.
	// Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
	const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

	// Every row is loaded except for our loading indicator row.
	const isItemLoaded = (index) => !hasNextPage || index < items.length;

	// Render an item or a loading indicator.
	const Item = ({ index, style }) => {
		let content;
    
		if (!isItemLoaded(index)) {
			content = (
				<Card variant="outlined" className={classes.root}>
					<CardContent>{t('workouts_page.loading')}</CardContent>
				</Card>
			);
		} else {
			const visit = items[index];
			const visitId = `/app/workouts/${visit.id}`;
			const visitType = visit.visit_type == 4 ? "H" : "G";
			const is_pending = visit.logout === null ? true: false;
			const bk_color = is_pending ? "#46a9db" : visit.visit_type == 4 ? '#49ed89' : '#4969ed';
			
     		const visitStart = moment(visit.login);
			let duration = t('common.pending') + "...";
			if (visit.logout) {
				const visitEnd = moment(visit.logout);
		
				let _duration = moment.duration(visitEnd.diff(visitStart));
				if( _duration.asMilliseconds()  === 0) {
					duration = "0 min";
				}else{

					duration = _duration.humanize()[0].toUpperCase() + _duration.humanize().substr(1);
				}	
			}
			// const title = `${visitStart.format("dddd, MMMM Do YYYY")} ${visitStart.format("LT")}`;
			// const status = `${duration} | ${visit.training_program_name}`;
		

			const title = visit.training_program_name;
			const status = `${visitStart.format('DD/MM/YYYY')} | ${visitStart.format("LT")} | ${duration}`			

			content = (				
				<Card style={{margin: 20, marginBottom: 0, marginTop: 10}} className={`${classes.latestWorkouts} ${visit.visit_type === 4 ? <HomeTag /> : <GymTag />}`}>
					<CardActionArea component={RouterLink} to={visitId}>						
						<CardHeader											
							avatar={
								<Avatar
									aria-label="training-image"
									className={classes.avatar}
									src={getImageUrl(visit, trainingData, imageCache)}
									alt="Training"
								>
									{visitType}
								</Avatar>
							}
							action={
								<IconButton style={{position: "absolute", top: 0, right: 22, padding: 0}}>
								{visit.visit_type === 4 ? <HomeTag className={classes.tag} /> : <GymTag />}
								</IconButton>
							}							
							title={title}
							subheader={status}
							titleTypographyProps={{style: {color: "#404040"}}}
							subheaderTypographyProps={{style: {color: "#404040"}}}
						/>
					</CardActionArea>
					<Box>
			
			</Box>
				</Card>
			);
		}
		style = {
			...style,
		};

		return <div style={style}>{content} </div>;
	};

	return (		
		<InfiniteLoader
			isItemLoaded={isItemLoaded}
			itemCount={itemCount}
			loadMoreItems={loadMoreItems}
			
		>
			{({ onItemsRendered, ref }) => (
				<AutoSizer>
					{({ height, width }) => {
						return (
							<FixedSizeList
								itemSize={97}
								itemCount={itemCount}
								onItemsRendered={onItemsRendered}
								height={height - 190}
								width={width}
								ref={ref}								
							>
								{Item}
							</FixedSizeList>
						);
					}}
				</AutoSizer>
			)}
		
		</InfiniteLoader>		

	);
}
