import * as React from 'react';
import { LineChart, YAxis, XAxis, CartesianAxis, Tooltip, TooltipProps, Legend, Line, CartesianGrid, Bar, ComposedChart, Rectangle, BarChart, ResponsiveContainer } from 'recharts'
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Theme } from '@mui/material';
import {
    InfoButton,
    CloseButton,
} from '../resources'
import CloseIcon from '@mui/icons-material/Close';
import  makeStyles  from '@mui/styles/makeStyles';
import { getTrainings } from '../services/api';
import { entries } from 'lodash';
import { parse } from 'path';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles ((theme: Theme) => ({
    paper: {
            width: "100%",
            borderRadius: 20,
            border: '3px solid #404040',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            marginBottom: 39,
    },
    chartTitle: {        
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        marginLeft: "35px",   
        fontFamily: "Poppins", 
        color: "#404040",
    },
    chart: {
        width: "100%",
        disaply: "flex",
        justifyContent: "center"
    },
    text: {
        fontFamily: "Poppins",
        fontSize: "0.875rem",
        fontWeight: 400,
        margin: 10
    },
    dialogTitle: {
		display: "flex",        
        color: "#404040",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",         
        paddingTop: "15px",
        paddingBottom: "10px",        
        paddingLeft: "4px",
        padding: 0, 		
        fontSize: "16px", 
        fontWeight: 700,         
        flex: 1,
        whiteSpace: "normal",
        textOverflow: "ellipsis" 		 
	},   
}))

export default function BasicLineChart({volumesByDay, isMonthlySelection, totalTimeSpentPerDate, selectDateRange, activityVolumeByDay, selectedProgram, selectedActivity, setSelectedActivity, trainings}) {
    const classes = useStyles()    
    const [barVisible, setBarVisible] = React.useState(false)
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [chartKey, setChartKey] = React.useState(0);
    // const [trainings, setTrainings] = React.useState([]);
    // const [isMonthlySelection, setIsMonthlySelection] = React.useState(null);
    const [openVolumeDialog, setOpenVolumeDialog]= React.useState(false);
    const [openTimeDialog, setOpenTimeDialog]= React.useState(false);
    const {t, i18n} = useTranslation();
    console.log("selectedDateRange in chart component", selectDateRange)
    console.log("volumesByDay in chart component", Array.from(volumesByDay.entries()));
    console.log("trainings in chart component", trainings)

    moment.locale(i18n.language);       
    useEffect(() => {
        setSelectedActivity(null);
    },[selectedProgram, setSelectedActivity])
let formattedStartRange = new Date();
let formattedEndRange = new Date();
if(selectDateRange && selectDateRange.startDate && selectDateRange.endDate) {
    const startRange = selectDateRange.startDate
    const endRange = selectDateRange.endDate
    formattedStartRange = new Date(startRange);
    formattedEndRange = new Date(endRange);
    
}

const handleOpenVolumeDialog = () => setOpenVolumeDialog(true);
const handleCloseVolumeDialog = () => setOpenVolumeDialog(false);

const handleOpenTimeDialog = () => setOpenTimeDialog(true);
const handleCloseTimeDialog = () => setOpenTimeDialog(false);

let chartData = null;

console.log("selected program in chart component", selectedProgram)

function generateDatesInRange(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    while(currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

function generateDatesInMonth(month) {
    const dates = [];
    const year = new Date().getFullYear();
    const date = new Date(year, month - 1, 1)

    while(date.getMonth() === - 1) {
        dates.push(date.toLocaleDateString());
        date.setDate(date.getDate() + 1);
    }
    // const firstDay = new Date(month, 1);
    // const lastDay = new Date(month + 1, 0);

    
    // let currentDate = firstDay;
    // while(currentDate <= lastDay) {
    //     dates.push(currentDate.toLocaleDateString());
    //     currentDate.setDate(currentDate.getDate() + 1);
    // }
    return dates;
}

function generateChartDataForMonth(selectedMonth) {
    const allDatesInMonth = generateDatesInMonth(selectedMonth);
    let filteredChartData = [];

    for (const [index, date] of allDatesInMonth.entries()) {
        const volumeFromVolumesByDay = volumesByDay.get(date);
        const volumeFromActivityVolumeByDay = activityVolumeByDay?.get(selectedActivity)?.get(date);

        if (volumeFromVolumesByDay) {
            filteredChartData.push({date, volume: volumeFromVolumesByDay, key: `chart-data-${date}-${index}`});
        } else if (volumeFromActivityVolumeByDay) {
            filteredChartData.push({date, volume: volumeFromActivityVolumeByDay, key: `chart-data-${date}-${index}`});
        }
    }

    return filteredChartData;
}

if(selectedActivity && volumesByDay && selectDateRange && selectDateRange.startDate && selectDateRange.endDate) {
    const activityVolumeData = activityVolumeByDay.get(selectedActivity);
    if(activityVolumeData instanceof Map) {
        const filteredVolumeData = new Map()

        for(let date = new Date(selectDateRange.startDate); date <= selectDateRange.endDate; date.setDate(date.getDate() + 1)) {
            const dateString = date.toLocaleDateString();
            if(activityVolumeData.has(dateString)) {
                const volume = activityVolumeData.get(dateString);
                filteredVolumeData.set(dateString, volume);
            }
        }
        chartData = Array.from(filteredVolumeData.entries()).map(([date, volume]) => ({date, volume}));
    } else {
        chartData = [];
    }
} else if(selectedProgram && volumesByDay && selectDateRange && selectDateRange.startDate && selectDateRange.endDate) {
    const programData = volumesByDay
    if(programData instanceof Map) {
        const filteredVolumeData = new Map();

    for(let date = new Date(selectDateRange.startDate); date <= selectDateRange.endDate; date.setDate(date.getDate() + 1)) {
        const dateString = date.toLocaleDateString();

        if(programData.has(dateString)) {
            const volume = programData.get(dateString);

            filteredVolumeData.set(dateString, volume);
        } 
    }
    chartData = Array.from(filteredVolumeData.entries()).map(([date, volume]) => ({date, volume}));
    } else {
        chartData = [];
    }
    
} else if(isMonthlySelection && selectDateRange && selectDateRange.month !== undefined) {
    const selectedMonth = selectDateRange.month;
    const allDatesInMonth = generateDatesInMonth(selectedMonth);

    let filteredChartData = [];
    for(const date of allDatesInMonth) {
        const volumeFromVolumesByDay = volumesByDay.get(date);
        const volumeFromActivityVolumeByDay = activityVolumeByDay?.get(selectedActivity)?.get(date);

        if(volumeFromVolumesByDay) {
            filteredChartData.push({date, volume: volumeFromVolumesByDay})
        } else if(volumeFromActivityVolumeByDay) {
            filteredChartData.push({date, volume: volumeFromActivityVolumeByDay})
        }
        // if(volumesByDay.has(date) && programData.has(date)) {
        //     const volume = volumesByDay.get(date);
        //     filteredChartData.push({date, volume});
        // } else if(activityVolumeByDay && activityVolumeByDay.get(selectedActivity) && activityVolumeByDay.get(selectedActivity).has(date)) {
        //     const volume = activityVolumeByDay.get(selectedActivity).get(date);
        //     filteredChartData.push({date, volume});
        // }
    }
    chartData = filteredChartData;
} else {
    chartData = null;
}

const reversedChartData = chartData ? [...chartData].reverse() : [];

// Generate chart data for a specific month

console.log("chartData", chartData);
console.log("totalTimeSpent in chart component", totalTimeSpentPerDate)
console.log("ActivityvolumeByDay in chart component", activityVolumeByDay)
const timeChartData = []


totalTimeSpentPerDate.forEach((visitTypeMap, visitType) => {
    visitTypeMap.forEach((timeValue, dateKey) => {   
        
        // // const dateObject = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
        
        timeChartData.push({
            date: dateKey,
            gymTime:  (visitType === 1 || visitType === 3 ) ? timeValue : 0,
            homeTime: visitType === 4 ? timeValue : 0,
        })        
    })
})
// timeChartData.sort((a, b) => moment(a.date).diff(moment(b.date)));
// timeChartData.sort((a ,b ) => a.date - b.date);
const filterTimeChartData = timeChartData.filter(data => data.gymTime > 0 || data.homeTime > 0);

filterTimeChartData.sort((a, b) => {
    const dateA = moment(a.date, 'DD-MM-YYYY')
    const dateB = moment(b.date, 'DD-MM-YYYY')
    const dateComparison = dateA.diff(dateB)
    if(dateComparison !== 0){
        return dateComparison;
    } else {
        return a.time - b.time;
    }
})

const reversedTimeChartDate = [...filterTimeChartData].reverse()
console.log("Chart data", reversedChartData);

const firstDay= filterTimeChartData[0]?.date;
const middleIndex = Math.floor(filterTimeChartData.length / 2);
const middleDay = filterTimeChartData[middleIndex]?.date
const lastDay = filterTimeChartData[filterTimeChartData.length - 1]?.date
console.log(`firstDay: ${firstDay}, middleDay: ${middleDay}, lastDay: ${lastDay}`)

const volumeFirstDay = chartData && chartData.length > 1 ? chartData[0].date : null
const volumeMiddleIndex = chartData && chartData.length > 0 ? Math.floor(chartData.length / 2) : null
const volumeMiddleDay = chartData && chartData.length > 2 ? chartData[volumeMiddleIndex].date : null
const volumeLastDay = chartData && chartData.length > 0 ? chartData[chartData.length - 1].date : null

console.log("timeChartData",reversedTimeChartDate);

const getUniqueTicks = () => {
    const uniqueTicks = new Set<string>();
    if(volumeLastDay) uniqueTicks.add(`last-${volumeLastDay}`)
    if(volumeMiddleDay) uniqueTicks.add(`middle-${volumeMiddleDay}`)
    if(volumeFirstDay) uniqueTicks.add(`first-${volumeFirstDay}`)
    const uniqueTicksArray = Array.from(uniqueTicks)
        console.log("uniqueTicks", uniqueTicksArray);
        return uniqueTicksArray;
};

const formatDate = (dateString) => {
    const date = new Date(dateString)
    const dayName = date.toLocaleDateString('en-US', {weekday: 'short'})
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${dayName} ${day}.${month}`    
}

const CustomTimeTooltip: React.FC<TooltipProps<number, string>> = ({active, payload, label}) => {
    if(active && payload && payload.length) {
        console.log("tooltip payload", payload)
        const gymVisit = payload.find(item => item.name === t('common.gym_visit'))?.value ?? 0;
        const homeVisit = payload.find(item => item.name === t('common.home_workout'))?.value ?? 0;
        return (
            <div style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                border: "1px solid #404040",
                marginBottom: 40
            }}>
                <p className={classes.text} style={{marginBottom: 0}}>{label}</p>
                {gymVisit !== 0 && (
                <p className={classes.text} style={{marginTop: 0, color: "#46A9DB"}}>{`${t('common.gym_visit')}: ${gymVisit} min`}</p>
            )}
                {homeVisit !== 0 && (
                <p className={classes.text} style={{marginTop: 0, color: "#F28986"}}>{`${t('common.home_workout')}: ${homeVisit} min`}</p>
            )}
            </div>
        )
    }
    return null;
}

const CustomToolTip: React.FC<TooltipProps<number, string>> = ({active, payload, label}) => {
    if(active && payload && payload.length) {
        const formattedDate = formatDate(label);

        return (
            <div style={{                
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    border: "1px solid #404040",                    
            }}
            >
                <p className={classes.text} style={{marginBottom: 0}}>{label}</p>
                <p className={classes.text} style={{marginTop: 0}}>{`${payload[0].name}: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
}

const uniqueTicks = getUniqueTicks();
console.log("uniqueTicks", uniqueTicks);

const filterChartData = Array.isArray(chartData) ? chartData.filter(entry => entry[t('volume')] !== 0) : [];

const textColor = (value, entry) => {
    return (
        <span style= {{color: '#404040', fontFamily: "Poppins", fontSize: "0.6875rem", fontWeight: 400}}>{value}</span>
    )
}

const hasRequiredTrainings = trainings.some(training => 
    training.visit_type === 1 || training.visit_type === 3
)
const hasChartData = filterChartData && filterChartData.length > 0;
const hasTimeChartData = filterTimeChartData && filterTimeChartData.length > 0;

return (
    <div style={{marginTop: 31}}>
        {hasRequiredTrainings && hasChartData && (
         <Paper 
        className={classes.paper}
        >
        <div style={{display:"flex", justifyContent:"space-between"}}>
      <h3 className={classes.chartTitle}>{t('progress_page.volume_chart')}</h3>
      <InfoButton onClick={handleOpenVolumeDialog} style={{marginRight: "20px", marginTop:"20px", height: "24px", width: "24px"}}/>			
					<Dialog
                PaperProps={{
                    style:{
                        border: "2px solid rgba(64, 64, 64, 1)",
                        borderRadius: "20px"
                    }
                }}              
				open={openVolumeDialog} 
				onClose={handleCloseVolumeDialog}				
				>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderBottom: "2px solid #404040", alignItems: "center", width: "100%"}}>
			<DialogTitle className={classes.dialogTitle}>{t('progress_page.volume_chart_info_title')}</DialogTitle>
			<IconButton  
				onClick={handleCloseVolumeDialog}
				style={{
                    paddingRight: 10,
                    flexShrink: 0, 
                }}>
				<CloseButton />
			</IconButton>
            </div>
			<DialogContent>
				<DialogContentText style={{color: "#404040", fontSize:"0.75rem", lineHeight: "18px", fontWeight: 400, padding: 0, margin: 0}}>
				<p>{t('progress_page.volume_chart_explanation')}</p>
				</DialogContentText>
			</DialogContent>			
			</Dialog>
            </div>
            <ResponsiveContainer width="100%" height={300}>
      <ComposedChart              
      data={filterChartData} 
      margin={{right: 40, left: -10}}
      className={classes.chart}
      >
      <XAxis 
      dataKey='date' 
      type='category' 
      ticks={uniqueTicks.map((tick: string) => tick.split('-')[1])} 
      interval={0} 
      tick={{textAnchor: 'middle'}}        
      style={{fontSize: "0.75rem", fontFamily: "Poppins"}}      
      tickMargin={8}      
      minTickGap={8}      
      />
      <YAxis/>
      <Tooltip content={<CustomToolTip />}/>          
      <CartesianGrid strokeDasharray = "3 3" stroke= "black" />
      <Line style={{opacity: "70%"}} type='monotone' dataKey={t('volume')} stroke="#404040" strokeWidth={2.2} dot />      
      {/* <Bar dataKey='volume' fill='rgba(70, 169, 219, 1)' barSize={10}/>                    */}
      </ComposedChart>
      </ResponsiveContainer>
      </Paper>
        )}
    {hasTimeChartData && (
      <Paper
    className={classes.paper}
    >
        <div style={{display:"flex", justifyContent:"space-between"}}>
      <h3 className={classes.chartTitle}>{t('progress_page.time_chart')}</h3>
      <InfoButton onClick={handleOpenTimeDialog} style={{marginRight: "20px", marginTop:"20px", height: "24px", width: "24px"}}/>			
					<Dialog 
                PaperProps={{
                    style:{
                        border: "2px solid rgba(64, 64, 64, 1)",
                        borderRadius: "20px"
                    }
                }}
				open={openTimeDialog} 
				onClose={handleCloseTimeDialog}				
				>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderBottom: "2px solid #404040", alignItems: "center", width: "100%"}}>
			<DialogTitle className={classes.dialogTitle}>{t('progress_page.time_chart_info_title')}</DialogTitle>
			<IconButton  
				onClick={handleCloseTimeDialog}
				style={{
                    paddingRight: 10,
                    flexShrink: 0,
                }}>
				<CloseButton />
			</IconButton>
            </div>
			<DialogContent>
				<DialogContentText style={{color: "black", fontSize:"0.75rem", lineHeight: "18px", fontWeight: 400, padding: 0, margin: 0}}>
				  <p>{t('progress_page.time_chart_explanation')}</p>
				</DialogContentText>
			</DialogContent>			
			</Dialog>
            </div>
            <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
      data={filterTimeChartData} 
      margin={{right: 35, left: -10}}      
      >
      <XAxis
       dataKey='date'
       ticks={[lastDay, middleDay, firstDay]}
       style={{fontFamily: "Poppins", fontSize: "0.75rem"}}
       tickMargin={6}
       interval={0}
       />
      <YAxis/>
      <Tooltip
      content={<CustomTimeTooltip />}    
       />
      <Legend 
        iconType='circle'
        iconSize={20}
        align='center'
        wrapperStyle={{             
             marginBottom: "5px",
             marginLeft: "10px"
             }}
        formatter={textColor}                   
            />
      <CartesianGrid strokeDasharray = "3 3" stroke= "black" vertical={false} />
       <Bar dataKey="gymTime" name={t('common.gym_visit')} fill="#46A9DB" barSize={10} />           
       <Bar dataKey="homeTime" name={t('common.home_workout')} fill="#F28986" barSize={10} />   
      </ComposedChart>
      </ResponsiveContainer>
      </Paper>
        )}
      </div>            
  );
}

