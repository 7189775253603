import { teal } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { getDashboardData, getLatestTrainingSession } from "../services/api";
import { RootState } from "../reducers";
import Goals from "../components/Settings/Goals";
import { useActions } from "../actions";
import * as UserActions from "../actions/auth";
import { getAccount, updateAccount } from "../services/api";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(2),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: "#46a9db",
		"&:hover": {
			backgroundColor: teal[600],
		},
	},
	stat_card: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
}));

export function SettingsPage() {

	const classes = useStyles();
	const { t, i18n } = useTranslation();	
	const authActions = useActions(UserActions);
	const [dashboardData, setDashboardData] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [lastVisit, setLastVisit] = React.useState(null);
	const [message, setMessage] = React.useState(null); // for snackbar

	const currentUser = useSelector((state: RootState) => state.user);

	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	const handleCloseMessage = () => {
		setMessage(null);
	};


	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				// const data = await getDashboardData();
				// setDashboardData(data.visits);
				// const visitResponse = await getLatestTrainingSession(
				// 	currentUser.id
				// );
				// const lastVisit = get(visitResponse, "results[0]");
				// if (lastVisit) {
				// 	setLastVisit(lastVisit);
				// }

				const user = await getAccount();
				authActions.setUser(user);
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveGoals = async (goals) => {
		console.log(goals);

		console.log(currentUser.goals);

		currentUser.goals = {...currentUser.goals, ...goals};
		// remove cards for now
		currentUser.cards = []	

		try{
			const updatedUser = await updateAccount(
				currentUser
			);
			authActions.setUser(updatedUser);
			setMessage( t('settings_page.settings_saved') );

		}catch(e){
			console.log(e);
		}

		
		//authActions.setUser(currentUser);
	}
	


	// const  endSession = async () => {

	// 	try {
	// 		// remove activities from payload
	// 		const {activities, ...uploadVisitData} = visit;
	// 		// fill in logout
	// 		uploadVisitData.logout = moment().toISOString();
	// 		const updatedVisit = await updateVisit(
	// 			parseInt(visit.id, 10),
	// 			uploadVisitData
	// 		);
	// 		setVisit(updatedVisit);
	// 		setMessage("Session ended.");
	// 		setTimeElapsed("");
	// 		clearInterval(interval);			
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
		

	// }

	function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}

	if (loading) {
		return <Loading />;
	}
    const current_month = moment().format('MMMM');
	return (
		<div className={classes.root}>
				<Grid container spacing={3}>

					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<Goals user_goals={currentUser.goals} save_user_goals={saveGoals}/>
					</Grid >
					{message && <Snackbar
						open={!!message}
						autoHideDuration={3000}
						onClose={handleCloseMessage}
						TransitionComponent={SlideTransition}
						sx={{ bottom: { xs: 60, sm: 0 } }}
					>
						<Alert severity="success">
							{message}
						</Alert>
					</Snackbar>
					}
				</Grid>

			{open ? (
				<StartActivityModal open={open} handleClose={handleClose} />
			) : (
				<Fragment />
			)}
		</div>
	);
}
