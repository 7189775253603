import in_progress from "../resources/img/in_progress.svg";
import { teal } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { getDashboardData, getLatestTrainingSession } from "../services/api";
import { RootState } from "../reducers";
import HomeWorkouts from "../components/Dashboard/HomeWorkouts";
import GymWorkouts from "../components/Dashboard/GymWorkouts";
import GoalProgress from "../components/Dashboard/GoalProgress";
import WeeklyGoalInTime from "../components/Dashboard/WeeklyGoalInTime"
import ActivitiesStatus from "../components/Dashboard/ActivitiesStatus"
import {
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Divider,
	Typography
  } from '@mui/material';
import {SvgIcon} from '@mui/material';
import { useTranslation } from 'react-i18next';

//import { ReactComponent as InProgressIcon } from '../resources/img/in_progress.svg';

const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(2),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: "#46a9db",
		"&:hover": {
			backgroundColor: teal[600],
		},
	},
	stat_card: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
}));

export function StatisticsPage() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [dashboardData, setDashboardData] = React.useState(null);
	const [homeActivitiesStatData, setHomeActivitiesStatdData] = React.useState( {"O":0, "F":0, "U":0,"N":0} );
	const [gymActivitiesStatData, setGymActivitiesStatData] = React.useState( {"O":0, "F":0, "U":0,"N":0} );
	const [visitsWeeklyData, setVisitsWeeklyData] = React.useState( {} );
	const [weekGoalThisWeekData, setWeekGoalThisWeekData] = React.useState( {count:0, percent:"0"}, );

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [lastVisit, setLastVisit] = React.useState(null);

	const currentUser = useSelector((state: RootState) => state.user);
	
	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getDashboardData();
				setDashboardData(data.visits);
				setHomeActivitiesStatdData(data.visits.home_visits.activities_status);
				setGymActivitiesStatData(data.visits.gym_visits.activities_status);
				setVisitsWeeklyData(data.visits.visits_weekly);
				// calc the goal for component
				let this_week_key = moment().format('YYYY') + "_" +  moment().format('WW');

				if(this_week_key in  data.visits.visits_weekly){
					const this_week = data.visits.visits_weekly[this_week_key]['count'];
					const weekly_goal = 4;
					const perc = (100*this_week/weekly_goal).toFixed(0);
					setWeekGoalThisWeekData({count: this_week, percent: perc })
				}else{
					// no data
					setWeekGoalThisWeekData({count:0, percent:"0"});
				}
				
				const visitResponse = await getLatestTrainingSession(
					currentUser.id
				);
				const lastVisit = get(visitResponse, "results[0]");
				if (lastVisit) {
					setLastVisit(lastVisit);
				}
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let firstHomeVisit = null;
	let lastHomeVisit = null;

	let firstGymVisit = null;
	let lastGymVisit = null;

	let gymVisitCountThisMonth;
	let homeVisitCountThisMonth;
	let homeVisitCountLastMonth;
	let gymVisitCountLastMonth;	
	let gymVisitPercentThisToPreviousMonth;
	let homeVisitPercentThisToPreviousMonth;

	if (dashboardData) {
		const homeVisits = dashboardData.home_visits;
		const gymVisits = dashboardData.gym_visits;


		// if(homeVisits.count) homeVisitCountThisMonth = homeVisits.count;
		// if(gymVisits.count) gymVisitCountThisMonth = gymVisits.count;
		
		// this month
		let this_month_key = moment().format('YYYY') + "_" +  moment().format('MM');
		let last_month_key = moment().subtract(1,'months').format('YYYY') + "_" +  moment().subtract(1,'months').format('MM');

		if(this_month_key in homeVisits.monthly){
			homeVisitCountThisMonth = homeVisits.monthly[this_month_key]['count'];
			if(last_month_key in homeVisits.monthly){
				homeVisitCountLastMonth = homeVisits.monthly[last_month_key]['count'];
				if(homeVisitCountThisMonth  != 0 ){
					homeVisitPercentThisToPreviousMonth = ( 100 * (homeVisitCountThisMonth - homeVisitCountLastMonth)/ (homeVisitCountThisMonth) ).toFixed(0);
				}else{
					homeVisitPercentThisToPreviousMonth = 0;	
				}				
			}else{
				homeVisitCountLastMonth = 0;
				homeVisitPercentThisToPreviousMonth = 100;

			}
		}else{
			homeVisitCountThisMonth = 0;
			homeVisitCountLastMonth = 0;
			homeVisitPercentThisToPreviousMonth = 0;
		}

		if(this_month_key in gymVisits.monthly){
			gymVisitCountThisMonth = gymVisits.monthly[this_month_key]['count'];
			if(last_month_key in gymVisits.monthly){
				gymVisitCountLastMonth = gymVisits.monthly[last_month_key]['count'];
				if(gymVisitCountThisMonth != 0 ){
					gymVisitPercentThisToPreviousMonth = ( 100 * (gymVisitCountThisMonth - gymVisitCountLastMonth)/ (gymVisitCountThisMonth) ).toFixed(0);
				}else{
					gymVisitPercentThisToPreviousMonth = 0;	
				}
			}else{
				gymVisitCountLastMonth = 0;
				gymVisitPercentThisToPreviousMonth = 100;

			}
		}else{
			gymVisitCountThisMonth = 0;
			gymVisitCountLastMonth = 0;
			gymVisitPercentThisToPreviousMonth = 0;
		}

		if (homeVisits.first_visit_date) {
			firstHomeVisit = (
				<p>
					<b>{t('common.first')}: </b>{" "}
					{moment(homeVisits.first_visit_date).format("LLL")}
				</p>
			);
		}
		if (homeVisits.last_visit_date) {
			lastHomeVisit = (
				<p>
					<b>{t('common.last')}: </b>
					{moment(homeVisits.last_visit_date).format("LLL")}
				</p>
			);
		}



		if (gymVisits.first_visit_date) {
			firstGymVisit = (
				<p>
					<b>{t('common.first')}: </b>
					{moment(gymVisits.first_visit_date).format("LLL")}
				</p>
			);
		}
		if (gymVisits.last_visit_date) {
			lastGymVisit = (
				<p>
					<b>{t('common.last')}: </b>
					{moment(gymVisits.last_visit_date).format("LLL")}
				</p>
			);
		}

	}
    //console.log(11111111111111, InProgressIcon);
	if (loading) {
		return <Loading />;
	}
    const current_month = moment().format('MMMM');
	return (
		<div className={classes.root}>
				<Grid container spacing={3}>
			
					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<WeeklyGoalInTime visits_weekly={visitsWeeklyData}/>
					</Grid >
					<Grid item lg={4} sm={12} xl={4} xs={12}>
						<ActivitiesStatus activities_status={homeActivitiesStatData} is_gym={false}/>
					</Grid >	
					<Grid item lg={4} sm={12} xl={4} xs={12}>
						<ActivitiesStatus activities_status={gymActivitiesStatData} is_gym={true}/>
					</Grid >
					<Grid item lg={12} sm={12} xl={12} xs={12}></Grid>
					<Grid item lg={4} sm={12} xl={4} xs={12}>
						<Card>
							<CardHeader title={t('statistics_page.history_title')} subheader={t('statistics_page.first_and_last_recorded_workouts')}>
							</CardHeader>
							<CardContent>
								{firstHomeVisit}
								{lastHomeVisit}

								{firstGymVisit}
								{lastGymVisit}
							</CardContent>
							
						</Card>
					</Grid >										
				</Grid>

			<Fab
				variant="extended"
				color="primary"
				aria-label="add"
				className={classes.fab}
				onClick={startTraining}
			>
				{lastVisit ? (<RefreshIcon />) : (<AddIcon />)}
				{lastVisit ? t('common.continue_training') : t('common.start_training')}
			</Fab>
			{open ? (
				<StartActivityModal open={open} handleClose={handleClose} />
			) : (
				<Fragment />
			)}
		</div>
	);
}
