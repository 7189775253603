
import makeStyles from '@mui/styles/makeStyles';
import get from "lodash/get";
import { useNavigate, useLocation } from "react-router-dom";
import moment, { duration } from "moment";
import React, { Fragment, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import { HurLoadingScreen } from "../components"
import { getDashboardData, getLatestTrainingSession, getTrainings, getVisits, createVisit, getAvailableActivities, getMessages } from "../services/api";
import { RootState } from "../reducers";
import Swipeable from "../components/Dashboard/Swipeable";
import WorkoutProgress from "../components/WorkoutProgress";
import GoalsAchived from "../components/GoalsAchieved";
import ProgressPie from "../components/ProgressPie";
import { Link as RouterLink } from "react-router-dom";
import {
	MessageIcon,
	InfoButton,
	MaleUpperBody,
	MaleMiddleBody,
	MaleLowerBody,
	MaleWhole,
	FemaleUpperBody,
	FemaleMiddleBody,
	FemaleLowerBody,
	FemaleWholeBody,
	MaleBodyWithArrows,
	FemaleBodyWithArrows,
	GymTag,
	HomeTag,
	CloseButton,
	Trophy
} from "../resources";
import {
	IconButton,
	Paper,
	Button,
	Box,
	DialogContentText,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	Avatar
  } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		paddingTop: 5,
		minHeight: "1650px",
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
		maxWidth: "450px",
		margin: "0 auto",		
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
				
	},	
	welcome: {
		color: "#404040",
		marginBottom: 0,
		fontFamily: "Poppins",
		fontWeight: 400,
		fontSize: "0.75rem"
	},
	nameContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	welcomeName: {
		marginTop: 0,
		fontFamily: "Poppins",
		fontWeight: 700,
		fontSize: "1.25rem",
		color: "#404040",
	},
	unreadMessage: {
		position: "absolute",
		minWidth: 16,
		height: 16,
		top: 10,
		right: 14,
		backgroundColor: "#F28986",
		borderRadius: "50%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "0.75rem",
		fontFamily: "Poppins",
		fontWeight: 600,
		padding: 2,
		color: "white",
	},
	trainingPapers: {		
		height: 200,
		width: 345,
		borderRadius: "20px",		
		backgroundColor: "linear-gradient(314deg, rgba(70, 169, 219, 0.2) 22.62%, rgba(25, 118, 210, 0.2) 56.24%);",
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',						
		marginRight: "25px",
		
	},
	workoutProgram: {
		marginLeft: 20,
		marginBottom: 10,
		marginTop: 35
	},

	workoutDesc: {		
		color: "#404040",
		marginTop: 0,
		fontSize: "0.93rem"
		
	},
	topPaper: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderTopRightRadius: "20px",
		borderBottomRightRadius: "20px",
		borderBottomLeftRadius: "20px",
		background:"rgba(255, 255, 255, 0.8)",
		height: "60px"
		
	},
	
	trainingButton: {		
		minwidth: "100px",		
		height: "34px",
		background: "#0F5486",
		borderRadius: "50px",
		color: "white",		
		fontSize: "0.625rem",
		fontWeight: 500,
		alignItems:"center",
		textTransform: "none",
		boxShadow: "0 2px 2px #404040"

	},

	dotContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItmes: 'center',
		marginBottom: 10,
		marginTop: 10,				
	},

	dot: {
	width: "12px",
    height: "12px",	
    borderRadius: '50%',
    backgroundColor: 'rgba(173, 164, 165, 1)',
    cursor: 'pointer',
	margin: theme.spacing(0.5),
	},

	activeDot: {
		backgroundColor: 'rgba(64, 64, 64, 1)',
		width: 14,
		height: 14,
		borderRadius: '50%',
	},

	trainingPapersDiv: {				
		display: "flex",
		justifyContent: "center",
		alignItems: "center",					
	},
	paperContainer: {
		display: "flex",
		justifyContent: "center",
		width: "88%",
		margin: '0 5px',
		marginLeft: "25px"
		
	},
	progressDiv: {
		display: "flex",		
		justifyContent: "center",
		alignItems: "center",
	},
	progressPaper: {
		display: "flex",
		height: "100%",
		width: "100%",
		maxHeight: 500,
		maxWidth: 400,
		justifyContent: "center",
		borderRadius: 20,
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',		
	},
	dialogTitle: {
		display: "flex",        
        color: "#404040",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",         
        paddingTop: "15px",
        paddingBottom: "10px",        
        paddingLeft: "4px",
        padding: 0, 		
        fontSize: "16px", 
        fontWeight: 700,         
        flex: 1,
        whiteSpace: "normal",
        textOverflow: "ellipsis" 		 
	},
	programTitle: {
		color: "#404040",            
        fontStyle: "normal",            
        fontWeight: 700,
        textAlign: "center",        		
		width: "180px",
		margin: 0,
		fontSize: "1rem",
		fontFamily: "Poppins"
	},
	progressDetails: {
		display: "flex",
		flexDirection: "column", 
		alignItems: 'center',
		marginTop: "10px",
		justifyContent: "space-between",
	},
	svgPart: {
		maxWidth: "100%",
		paddingTop: "10px",
		marginRigt: "5px",
		paddingBottom: "5px",				
		height: "100%",				
	},
	progressDetailsText: {
		color: "#404040", 
        margin: "2px",
        fontSize: "0.625rem",
		fontFamily: "Poppins",
        fontWeight: 500,
		justifyContent: "center",
		textAlign: "center",		
		
	},
	progressDetailsTextProgress: {
		color: "#404040", 
        margin: "2px",
        fontSize: "0.625rem",
        fontWeight: 500,
		fontFamily: "Poppins",
		justifyContent: "center",
		textAlign: "center",				
		textDecoration: "underline",
	},
	arrowIcon: {
        width: '16px',
        height: 'auto',
        marginLeft: '4px',
        verticalAlign: 'middle',
    },
	workoutPaperContainer: {
		display: "flex",
		justifyContent: "center",
	},
	workoutPaper: {
		borderRadius: 20,
		border: '3px solid rgba(70, 169, 219, 0.75)',
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
		width: "100%",		
		maxWidth: 500,
		justifyContent: "center",
		flexWrap: "nowrap",
		whiteSpace: "nowrap",
		overflow: "hidden",				      
		textOverflow: "ellipsis",		
		
	},
	setgoalsbutton:{
		background: "#0F5486",		
		height: "34px", 
		width: "auto",
		borderRadius: "10px", 
		textTransform: "none", 
		padding: 0, 
		color: "white", 
		marginBottom:"8px", 
		marginTop: 5,
		boxShadow: "0 2px 2px #404040",
		fontSize: "0.625rem",
		fontWeight: 500
	},
	goaltext: {
		marginBottom: 10, 
		marginTop: 0, 
		color:"#404040", 
		fontSize: "0.625rem", 
		fontWeight: 400, 
		fontFamily: "Poppins"
	},
	workoutTotalContainer: {
		display: "flex",		
		justifyContent: "space-between",		
		flex:"0 0 auto",
		flexDirection: "column",
		borderBottom: "solid 1px",		
	},
	workoutTotalText: {
		marginLeft: 35,
		marginTop: 10
	},
	workoutProgressContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",							
	},

	latestWorkouts: {
		display: "flex",
		flexDirection: "row",		
		color: "black",		
		justifyContent: "flex-start",
		marginBottom: 15,
		marginLeft: 30,
		borderRadius: 16,
		width: "100%",
		height: 80,
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',

	},
	visitTypeHome: {
		borderBottom: '4px solid #F28986'
	},
	visitTypeGym: {
		borderBottom: '4px solid rgba(70, 169, 219, 0.75)'
	},
	workoutTitle: {
		display: "flex",
		justifyContent: "flex-start",
		margin: 0,
		marginBottom: 0,
		textTransform: "none",
		fontSize: "0.74rem",
		color: "#404040",		
	},
	workoutDetail: {
		marginTop: 0,
		marginBottom: 0,
		margin: 0,
		fontSize: "0.75rem",
		fontWeight: 500,
		color: "#404040",
		textTransform: "none",
		opacity: "80%"		
	},
	latestworkoutpic: {
		width: '55px',
        height: '55px',
        borderRadius: '50%',        
        objectFit: 'cover',
		marginLeft: 5,
		alignItems: "center",			
	},
	tag: {
		position: "absolute",
		top:'-5px',
		right:'25px'
	},
	programDescription: {
		color: "#404040",             
        fontWeight: 500,
        fontSize: "10px",
		fontFamily: "Poppins",
        marginTop: "3px",
		marginBottom: "3px",
		justifyContent: "center",
        textAlign: "center",
		width: "150px"
	},	
}));

export function DashboardPage() {
	const navigate = useNavigate();
	const classes = useStyles();		
	const location = useLocation();
	const perPage = 150;
	const [loadedPage, setLoadedPage] = React.useState(0);
	const { t, i18n } = useTranslation();
	const [dashboardData, setDashboardData] = React.useState(null);
	const [availableActivities, setAvailableActivities] = React.useState([]);
	const [homeActivitiesStatData, setHomeActivitiesStatdData] = React.useState( {"O":0, "F":0, "U":0,"N":0} );
	const [gymActivitiesStatData, setGymActivitiesStatData] = React.useState( {"O":0, "F":0, "U":0,"N":0} );
	const [visitsWeeklyData, setVisitsWeeklyData] = React.useState( {} );
	const [weekGoalThisWeekData, setWeekGoalThisWeekData] = React.useState( { home: {count:0, percent:0, goal:0}, gym: {count:0, percent:0, goal:0}, all: {count:0, percent:0, goal:0} } );
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [trainings, setTrainings] = React.useState([]);
	const [programName, setProgramName] = React.useState('');
	const [firstTrainingDate, setFirstTrainingDate] = React.useState(null)
	const [latestTrainingDate, setLatestTrainingDate] = React.useState(null)
	const [latestTrainings, setLatestTrainings] = React.useState([]);
	const [lastVisit, setLastVisit] = React.useState(null);
	const [trainingData, setTrainingData] = React.useState([]);
	const [mostUsedPrograms, setMostUsedPrograms] = React.useState([]);
	const [selectedVisitType, setSelectedVisitType] = React.useState(4);
	const [error, setError] = React.useState(null);
	const [openDialog, setOpenDialog]= React.useState(false);
	const [openProgressDialog, setOpenProgressDialog]= React.useState(false);	
	const [openGoalsDialog, setOpenGoalsDialog] = React.useState(false);
	const [filteredPrograms, setFilteredPrograms] = React.useState([]);	
	const [selectedPart, setSelectedPart] = React.useState('wholeBody');	
	const [imageCache, setImageCache] = React.useState({});
	const [messages, setMessages] = React.useState([]);
	const [isDataFetched, setIsDataFetched] = React.useState(false);
	const [categorizedActivities, setCategorizedActivities] = React.useState({
		upperBody: 0,
		middleBody: 0,
		lowerBody: 0,
		wholeBody: 0,
		upperBodyProgressActivities: {mostProgress: null, leastProgress: null},
		middleBodyProgressActivities: {mostProgress: null, leastProgress: null},
		lowerBodyProgressActivities: {mostProgress: null, leastProgress: null},
		wholeBodyProgressActivities: {mostProgress: null, leastProgress: null},
	  });
	const [hasSeenGoalDialog, setHasSeenGoalDialog] = React.useState(() => {
		return localStorage.getItem('hasSeenGoalDialog') === 'true'
	});
	const [manualDelay, setManualDelay] = React.useState(false);
	const previousPath = useRef(location.pathname);
	const currentUser = useSelector((state: RootState) => state.user);	
			
	moment.locale(i18n.language);

	// const hasSeenGoalDialog = localStorage.getItem('hasSeenGoalDialog') === 'true';

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	}

	
	const handleCloseDialog = () => {
		setOpenDialog(false);
	}

	const handleOpenProgressDialog = () => {
		setOpenProgressDialog(true);
	}

	
	const handleCloseProgressDialog = () => {
		setOpenProgressDialog(false);
	}

	const handleOpenGoalDialog = () => {
		setOpenGoalsDialog(true);
		localStorage.setItem('hasSeenGoalDialog', 'true')
		setHasSeenGoalDialog(true);
	}

	const handleCloseGoalDialog = () => {
		setOpenGoalsDialog(false);
	}

	const handleClick = (part) => {
		setSelectedPart(part);
	}

	const handleDotClick = (dotIndex) => {
		setCurrentIndex(dotIndex);
	  }	

	  const handleSwipeLeft = () => {
		if (currentIndex < slicedTraining.length - 1) {
		  setCurrentIndex(currentIndex + 1);
		}
	  };
	
	  const handleSwipeRight = () => {
		if (currentIndex > 0) {
		  setCurrentIndex(currentIndex - 1);
		}
	  };

	const handleSvgClick = (event) => {
		const clickedElementId = event.target.id;
		console.log("Clicked element id:", clickedElementId)
		if(clickedElementId === 'wholeBodyTrigger') {
			setSelectedPart('wholeBody')
		} 
		if(clickedElementId === 'upperBodyTrigger'){
			setSelectedPart('upperBody')
		} else if(clickedElementId === 'middleBodyTrigger') {
			setSelectedPart('middleBody')
		} else if(clickedElementId === 'lowerBodyTrigger') {
			setSelectedPart('lowerBody')
		}
		console.log("selected part:", selectedPart)

	}

	const getValidPrograms = (trainings) => {
        return trainings.filter(program => {
            const activities = program.activities || [];
            if(!activities) {
                console.error("Activities are undefined");
                return false;
            }
        const hasValidActivities = activities.some(activity => {
            const results = activity.results;
            const sets = results && results.sets;
            return Array.isArray(sets) && sets.length > 0;
        });
        return hasValidActivities;
        })
    }

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getMessages();
				setMessages(data.results);
				console.log(data);

			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();		
	}, []);
	console.log("messages", messages)

	useEffect(() => {
        async function fetchData() {    
			setLoading(true);
            const nextPage = loadedPage + 1;            
            try {
                const data = await getVisits(currentUser.id, nextPage, perPage );
				console.log("Initial Fetch", data);
				const programCounts = {};

				setTrainings(data.results)				
				const latestTrainingsData = data.results.length > 0 ? data.results.slice(0, 3) : [];
				setLatestTrainings(latestTrainingsData);
				console.log("Latest trainings1111111", latestTrainings)                				
                
            } catch(err) {
                console.error("No Data");
            }
			setLoading(false);
        }
        fetchData();
      }, [currentUser])	    
	  console.log("trainigns", trainings)

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getDashboardData();
				setDashboardData(data.visits);
				setHomeActivitiesStatdData(data.visits.home_visits.activities_status);
				setGymActivitiesStatData(data.visits.gym_visits.activities_status);
				setVisitsWeeklyData(data.visits.visits_weekly);
				// calc the goal for component
				let this_week_key = moment().format('YYYY') + "_" +  moment().format('WW');

				// server side weekly goal
				const weekly_goal_gym = currentUser.goals?.gym_visits_per_week || 0;
				const weekly_goal_home = currentUser.goals?.home_training_sessions_per_week || 0;
				const weekly_goal = (weekly_goal_gym + weekly_goal_home) || 0;
				
				let _weekGoalThisWeekData = { home: {count:0, percent:0, goal:0}, gym: {count:0, percent:0, goal:0}, all: {count:0, percent:0, goal:0} };
				// gym
				if(this_week_key in  data.visits.gym_visits.weekly){
					const this_week = data.visits.gym_visits.weekly[this_week_key]['count'];
					const percent = weekly_goal_gym ? 100*this_week/weekly_goal_gym : 0;
					_weekGoalThisWeekData.gym = {count: this_week, percent: percent, goal: weekly_goal_gym }
				}else{
					// no data
					_weekGoalThisWeekData.gym = {count: 0, percent: 0, goal: 0 }
				}
				//home
				if(this_week_key in  data.visits.home_visits.weekly){
					const this_week = data.visits.home_visits.weekly[this_week_key]['count'];
					const percent = weekly_goal_home ? 100*this_week/weekly_goal_home : 0;
					_weekGoalThisWeekData.home = {count: this_week, percent: percent, goal: weekly_goal_home }
				}else{
					// no data
					_weekGoalThisWeekData.home = {count: 0, percent: 0, goal: 0 }
				}

				// all
				if(weekly_goal){
					_weekGoalThisWeekData.all = {count: _weekGoalThisWeekData.home.count + _weekGoalThisWeekData.gym.count, 
												percent: 100*(_weekGoalThisWeekData.home.count + _weekGoalThisWeekData.gym.count)/ weekly_goal, goal: weekly_goal}
				}else{
					_weekGoalThisWeekData.all = {count: 0, percent: 0, goal: 0 }
				}

				// set data
				setWeekGoalThisWeekData(_weekGoalThisWeekData);

				
				const visitResponse = await getLatestTrainingSession(
					currentUser.id
				);
				const lastVisit = get(visitResponse, "results[0]");
				if (lastVisit) {
					setLastVisit(lastVisit);
				}
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);
	
	

	// useEffect(() => {
	// 		const homePercent = weekGoalThisWeekData.home.percent;
	// 		const gymPercent = weekGoalThisWeekData.gym.percent;
	// 	if(gymPercent >= 100 && homePercent >= 100 && !hasSeenGoalDialog) {
	// 		handleOpenGoalDialog();
	// 	}
	// }, [weekGoalThisWeekData]);

	console.log("dashboardData", dashboardData)

	console.log("dashboard currentUser", currentUser);
	 
	  const totalItems = mostUsedPrograms.length

	  useEffect(() => {
		async function fetchData() {
		setLoading(true);
		try {
			const data = await getTrainings(currentUser.id);
			const _availableActivities = await getAvailableActivities()
			setTrainingData(data.results);
			setAvailableActivities(_availableActivities);
			

		} catch(err) {
			console.error(err);
		}
		setLoading(false);
	}
	fetchData();
	  }, [])

	 
	  console.log("AvailableActivities", availableActivities);
	  
	  console.log("trainings", trainingData)	 

	  const onCreateSession = async (trainingId) => {
		console.log("trainingId", trainingId)
        if(!trainingId) {
            setError("Please select a training");
            return;
        }
		const selectedTraining = trainings.find(
			(training) => training.id === trainingId
		)
        if(!selectedTraining) {
            setError("Please select location")
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const newVisit = await createVisit({
                login: new Date().toISOString(),
                logout: null,
                user: currentUser.id,
                visit_type: selectedVisitType,
                training_program_name: selectedTraining.name,
                training_program: selectedTraining.id,
                facility: currentUser.membership.facility,
                comments: 'Created through the app',
            });
            navigate(`/app/workouts/${newVisit.id}`);
        } catch(e){
            console.log(e);
        }
        setLoading(false);
    }

	

	  let totalDurationInMinutes =[];
	  const formattedDates = [];
	  if(latestTrainings.length > 0) {
	  latestTrainings.forEach(training => {
		if(training.login && training.logout) {
			const loginTime = new Date(training.login)
			const logoutTime = new Date(training.logout);
			const differenceInMinutes = logoutTime.getTime() - loginTime.getTime();
			const durationInMinutes = Math.floor(differenceInMinutes / (1000 * 60)) 
			totalDurationInMinutes.push(durationInMinutes);

			const formattedDate = `${loginTime.getDate()}/${loginTime.getMonth() + 1}/${loginTime.getFullYear()}`
			formattedDates.push(formattedDate)
		}
	  })
	}
	console.log("formatted dates", formattedDates)
	console.log("totalDurationInMinutes",totalDurationInMinutes)	
	

	let firstHomeVisit = null;
	let lastHomeVisit = null;

	let firstGymVisit = null;
	let lastGymVisit = null;

	let gymVisitCountThisMonth;
	let homeVisitCountThisMonth;
	let homeVisitCountLastMonth;
	let gymVisitCountLastMonth;	
	let gymVisitPercentThisToPreviousMonth;
	let homeVisitPercentThisToPreviousMonth;

	if (dashboardData) {
		const homeVisits = dashboardData.home_visits;
		const gymVisits = dashboardData.gym_visits;


		// if(homeVisits.count) homeVisitCountThisMonth = homeVisits.count;
		// if(gymVisits.count) gymVisitCountThisMonth = gymVisits.count;
		
		// this month
		let this_month_key = moment().format('YYYY') + "_" +  moment().format('MM');
		let last_month_key = moment().subtract(1,'months').format('YYYY') + "_" +  moment().subtract(1,'months').format('MM');

		if(this_month_key in homeVisits.monthly){
			homeVisitCountThisMonth = homeVisits.monthly[this_month_key]['count'];
			if(last_month_key in homeVisits.monthly){
				homeVisitCountLastMonth = homeVisits.monthly[last_month_key]['count'];
				if(homeVisitCountThisMonth  != 0 ){
					homeVisitPercentThisToPreviousMonth = ( 100 * (homeVisitCountThisMonth - homeVisitCountLastMonth)/ (homeVisitCountThisMonth) ).toFixed(0);
				}else{
					homeVisitPercentThisToPreviousMonth = 0;	
				}				
			}else{
				homeVisitCountLastMonth = 0;
				homeVisitPercentThisToPreviousMonth = 100;

			}
		}else{
			homeVisitCountThisMonth = 0;
			homeVisitCountLastMonth = 0;
			homeVisitPercentThisToPreviousMonth = 0;
		}

		if(this_month_key in gymVisits.monthly){
			gymVisitCountThisMonth = gymVisits.monthly[this_month_key]['count'];
			if(last_month_key in gymVisits.monthly){
				gymVisitCountLastMonth = gymVisits.monthly[last_month_key]['count'];
				if(gymVisitCountThisMonth != 0 ){
					gymVisitPercentThisToPreviousMonth = ( 100 * (gymVisitCountThisMonth - gymVisitCountLastMonth)/ (gymVisitCountThisMonth) ).toFixed(0);
				}else{
					gymVisitPercentThisToPreviousMonth = 0;	
				}
			}else{
				gymVisitCountLastMonth = 0;
				gymVisitPercentThisToPreviousMonth = 100;

			}
		}else{
			gymVisitCountThisMonth = 0;
			gymVisitCountLastMonth = 0;
			gymVisitPercentThisToPreviousMonth = 0;
		}

		if (homeVisits.first_visit_date) {
			firstHomeVisit = (
				<p>
					<b>First: </b>{" "}
					{moment(homeVisits.first_visit_date).format("LLL")}
				</p>
			);
		}
		if (homeVisits.last_visit_date) {
			lastHomeVisit = (
				<p>
					<b>Last: </b>
					{moment(homeVisits.last_visit_date).format("LLL")}
				</p>
			);
		}



		if (gymVisits.first_visit_date) {
			firstGymVisit = (
				<p>
					<b>First: </b>
					{moment(gymVisits.first_visit_date).format("LLL")}
				</p>
			);
		}
		if (gymVisits.last_visit_date) {
			lastGymVisit = (
				<p>
					<b>Last: </b>
					{moment(gymVisits.last_visit_date).format("LLL")}
				</p>
			);
		}		
	}	

  const sortedTrainingData = trainingData.sort((a, b) => {
	if(a.current && !b.current) {
		return -1;		
	} else if(!a.current && b.current) {
		return 1;
	} else {
		return a.id - b.id
	}
  });
  const slicedTraining = sortedTrainingData.slice(0, 3);  

  console.log("slicedTraining with current first", slicedTraining);

// 	if(trainings && trainings.length > 0) {
// 		const totalDuration = {};
// 		const numberOfActivities = {};
// 		const amountOfActivities = {};
  
  
//   	mostUsedPrograms.forEach((program) => {
// 	  totalDuration[program.id] = 0;
// 	  numberOfActivities[program.id] = 0;
	  
//   });
  console.log("222222222222222222222222")
  
//   trainings.forEach((training) => {
// 	  const programName = training.training_program_name;
  

// 	  if (mostUsedPrograms.includes(programName)) {
// 		  const loginTime = new Date(training.login).getTime();
// 		  const logoutTime = new Date(training.logout).getTime();
// 		  const durationInMinutes = (logoutTime - loginTime) / (1000 * 60);
  

// 		  totalDuration[programName] += durationInMinutes;
// 		  numberOfActivities[programName]++;
  
// 		  const activities = training.activities.length;
// 		  amountOfActivities[programName] = activities;
// 		  console.log(amountOfActivities)
// 	  }
	  
//   });

  console.log("1000000000000001")
  

//   const averageDuration = {};
//   for (const program in totalDuration) {
// 	  if (totalDuration.hasOwnProperty(program) && numberOfActivities[program] > 0) {
// 		  averageDuration[program] = totalDuration[program] / numberOfActivities[program];
// 	  }
//   }

  useEffect(() => {
	if(!trainings || trainings.length > 0) {
	
	const trainingActivityNames = new Set();
	const programTrainings = {};	
	const filteredGymPrograms = trainings.filter(program => program.visit_type === 1 || program.visit_type === 3)
	if(filteredGymPrograms.length > 0) {	
	const validPrograms = getValidPrograms(filteredGymPrograms);
	if(validPrograms && validPrograms.length > 0) {
	const latestTrainings = validPrograms[0];
	const latestTrainingName = latestTrainings.training_program_name;
		setProgramName(latestTrainingName);
		console.log("latestTrainings",latestTrainings)
		const trainingsWithSameProgram = trainings.filter(training => (
			training.training_program === latestTrainings.training_program
		))
	

		trainingsWithSameProgram.sort((a, b) => {
			const dateA = new Date(a.login)
			const dateB = new Date(b.login)
			console.log("dates for first and latest", dateA, dateB)
			return dateA.getTime() - dateB.getTime()
			
		})		

	const firstTrainings = trainingsWithSameProgram[0]
	
	
	console.log("First trainings", firstTrainings);
	console.log("last trainings", latestTrainings);	

	if(firstTrainings && latestTrainings) {
		setFirstTrainingDate(new Date(firstTrainings.login))
		setLatestTrainingDate(new Date(latestTrainings.login))
	}
		
  
	const trainingsActivities = (training) => {
		if(!training) return [];
		return training.activities.map(activity => ({ 
			...activity
		}))
	}	

	
	const firstTrainingsActivities = trainingsActivities(firstTrainings)
	const latestTrainingsActivities = trainingsActivities(latestTrainings)
	console.log("first and latest trainigns", firstTrainings, latestTrainings)
	console.log("ACTIVITIES", firstTrainingsActivities, latestTrainingsActivities)

	firstTrainingsActivities.forEach(activity => {		
			trainingActivityNames.add(activity.activity_name);
		
	})


	const matchedActivities = availableActivities.filter(activity => 
		trainingActivityNames.has(activity.name)
	)
	console.log("trainingActivityNames", trainingActivityNames)
	const upperBodyActivities = new Set();
	const middleBodyActivities = new Set();
	const lowerBodyActivities = new Set();
	const uncategorizedActivities = new Set();

	matchedActivities.forEach(activity => {
		const activityDefinition = activity.all_activity.activity_definition
		const bodySegment = activityDefinition ? activityDefinition.body_segment_targeted : null;

		switch(bodySegment){
			case "U":
				upperBodyActivities.add(activity.name);
				break;
			case "M":
				middleBodyActivities.add(activity.name);
				break;
			case "L":
				lowerBodyActivities.add(activity.name);
				break;
				default:
			console.warn(`Uncategorized activity: ${activity.all_activity.name}`);
			uncategorizedActivities.add(activity);
			break;
		}
	})

	console.log("upperBodyActivities from available", upperBodyActivities)

const firstcategorizedUpperBodyActivities = [];
const firstcategorizedMiddleBodyActivities = [];
const firstcategorizedLowerBodyActivities = [];
const latestcategorizedUpperBodyActivities = [];
const latestcategorizedMiddleBodyActivities = [];
const latestcategorizedLowerBodyActivities = [];
const _uncategorizedActivities = [];	



  firstTrainingsActivities.forEach(activity => {			
	const activityName = activity.activity_name;		
	if (upperBodyActivities.has(activityName)) {
		firstcategorizedUpperBodyActivities.push(activity);
	} else if (middleBodyActivities.has(activityName)) {
		firstcategorizedMiddleBodyActivities.push(activity);
	} else if (lowerBodyActivities.has(activityName)) {
		firstcategorizedLowerBodyActivities.push(activity);
	} else {
	  _uncategorizedActivities.push(activity);
	}
})
latestTrainingsActivities.forEach(activity => {	
	const results = activity.results;
		if(results === 0) {
			return;
		}		
	const activityName = activity.activity_name;	
	if (upperBodyActivities.has(activityName)) {
		latestcategorizedUpperBodyActivities.push(activity);
	} else if (middleBodyActivities.has(activityName)) {
		latestcategorizedMiddleBodyActivities.push(activity);
	} else if (lowerBodyActivities.has(activityName)) {
		latestcategorizedLowerBodyActivities.push(activity);
	} else {
	  _uncategorizedActivities.push(activity);
	}
})

console.log("FIRSTTRAININGS ACTIVITIES CATEGORIZED", firstcategorizedUpperBodyActivities, firstcategorizedMiddleBodyActivities, firstcategorizedLowerBodyActivities )
console.log("LATESTTRAININGS ACTIVITIES CATEGORIZED", latestcategorizedUpperBodyActivities, latestcategorizedMiddleBodyActivities, latestcategorizedLowerBodyActivities )


const calculateVolume = (activities) => {
	let totalVolume = 0;
	const activityVolumes = {};
  
	activities.forEach(activity => {
		let activityTotalVolume = 0;
	  if (activity.settings && activity.settings.sets) {
		activity.settings.sets.forEach(set => {
		  const reps = set.reps || 0;
		  const load = set.load / 1000 || 0;
		  activityTotalVolume += reps * load;
		});
	  }
	activityVolumes[activity.activity_name] = activityTotalVolume
	totalVolume += activityTotalVolume
	});
  
	return {totalVolume, activityVolumes};
  };

  const calculateTotalVolume = (activities) => {
	let totalVolume = 0;
	activities.forEach(activity => {		
		if (activity.settings && activity.settings.sets) {
			activity.settings.sets.forEach(set => {
				const reps = set.reps || 0;
				const load = set.load / 1000 || 0; // Assuming load is in grams, divide by 1000 for kilograms.
				totalVolume += reps * load;
			});
		}
	});
	return totalVolume;
};

const firstVolume = calculateTotalVolume(firstTrainingsActivities);
const latestVolume = calculateTotalVolume(latestTrainingsActivities);
const firstUpperBodyVolume = calculateVolume(firstcategorizedUpperBodyActivities);
const firstMiddleBodyVolume = calculateVolume(firstcategorizedMiddleBodyActivities);
const firstLowerBodyVolume = calculateVolume(firstcategorizedLowerBodyActivities);
const firstTotalVolume = Math.floor(firstUpperBodyVolume.totalVolume + firstMiddleBodyVolume.totalVolume + firstLowerBodyVolume.totalVolume);
console.log("first upper and middle body", firstUpperBodyVolume, firstMiddleBodyVolume);
const latestUpperBodyVolume = calculateVolume(latestcategorizedUpperBodyActivities);
const latestMiddleBodyVolume = calculateVolume(latestcategorizedMiddleBodyActivities);
const latestLowerBodyVolume = calculateVolume(latestcategorizedLowerBodyActivities);
const latestTotalVolume = Math.floor(latestUpperBodyVolume.totalVolume + latestMiddleBodyVolume.totalVolume + latestLowerBodyVolume.totalVolume);
console.log("latest upper and middle body", latestUpperBodyVolume, latestMiddleBodyVolume)
console.log("latest and first middle", firstMiddleBodyVolume, latestMiddleBodyVolume	)
const firstAllActivities = [
	...firstcategorizedUpperBodyActivities,
	...firstcategorizedMiddleBodyActivities,
	...firstcategorizedLowerBodyActivities,
];

const latestAllActivities = [
	...latestcategorizedUpperBodyActivities,
	...latestcategorizedMiddleBodyActivities,
	...latestcategorizedLowerBodyActivities,
]

const firstAllActivitiesVolume = calculateVolume(firstAllActivities)
const latestAllActivitiesVolume = calculateVolume(latestAllActivities)
console.log("firstAllActivities", firstAllActivitiesVolume)
console.log("latestAllActivities", latestAllActivitiesVolume)
console.log("first and latest total volumes", firstVolume, latestVolume);

console.log("firstUpperBodyVolume", firstUpperBodyVolume)
console.log("latestUpperBodyVolume", latestUpperBodyVolume)
console.log("lowerBodyProgress", firstMiddleBodyVolume)

const calculateProgress = (firstVolume, latestVolume) => {
	if(firstVolume === 0) return 0;
	const progress = Math.floor(((latestVolume - firstVolume) / firstVolume) * 100)
	return progress || 0;
}

const getProgressActivities = (firstVolumes, latestVolumes) => {
	const progressActivities = [];

	Object.keys(firstVolumes).forEach(activityName => {
		if(latestVolumes.hasOwnProperty(activityName)) {
			const progress = calculateProgress(firstVolumes[activityName], latestVolumes[activityName]);
			progressActivities.push({activityName, progress});
		}
	})

	if(progressActivities.length === 0) return {mostProgress: null, leastProgress: null}

	progressActivities.sort((a, b) => b.progress - a.progress);

	const mostProgress = progressActivities[0];
	progressActivities.sort((a, b) => a.progress - b.progress);
	const leastProgress = progressActivities.find(activity => activity.activityName !== mostProgress.activityName) || progressActivities[0];

	return {mostProgress, leastProgress}
}

const getMostAndLeastProgress = (firstTrainingsActivities, latestTrainingsActivities) => {
	const progressActivities = [];

    // Filter out activities with status "N"
    // const validFirstActivities = firstTrainingsActivities.filter(activity => activity.status !== "N");
    // const validLatestActivities = latestTrainingsActivities.filter(activity => activity.status !== "N");

    // Map activities by their name for easy lookup
    const latestActivitiesMap = latestTrainingsActivities.reduce((map, activity) => {
        map[activity.activity_name] = activity;
        return map;
    }, {});

    firstTrainingsActivities.forEach(activity => {
        const firstActivityName = activity.activity_name;

        if (latestActivitiesMap[firstActivityName]) {
            const firstVolume = calculateTotalVolume([activity]);
            const latestVolume = calculateTotalVolume([latestActivitiesMap[firstActivityName]]);

            const progress = calculateProgress(firstVolume, latestVolume);

            progressActivities.push({
                activityName: firstActivityName,
                progress,
            });
        }
    });

    if (progressActivities.length === 0) return { mostProgress: null, leastProgress: null };

    // Sort by progress to find most and least progress
    progressActivities.sort((a, b) => b.progress - a.progress);

    const mostProgress = progressActivities[0];
    const leastProgress = progressActivities[progressActivities.length - 1];

    return { mostProgress, leastProgress };
}

const totalVolumeActivities = getMostAndLeastProgress(firstTrainingsActivities, latestTrainingsActivities);
const upperBodyProgressActivities = getProgressActivities(firstUpperBodyVolume.activityVolumes, latestUpperBodyVolume.activityVolumes)
const middleBodyProgressActivities = getProgressActivities(firstMiddleBodyVolume.activityVolumes, latestMiddleBodyVolume.activityVolumes)
const lowerBodyProgressActivities = getProgressActivities(firstLowerBodyVolume.activityVolumes, latestLowerBodyVolume.activityVolumes)
const wholeBodyProgressActivities = getProgressActivities(firstAllActivitiesVolume.activityVolumes, latestAllActivitiesVolume.activityVolumes)

console.log("Most and latest upper body activities progress", upperBodyProgressActivities);
console.log("Most and latest middle body activities progress", middleBodyProgressActivities);
console.log("Most and latest lower body activities progress", lowerBodyProgressActivities);
console.log("Most and latest lower body activities progress", wholeBodyProgressActivities);
console.log(firstAllActivitiesVolume.totalVolume, latestAllActivitiesVolume.totalVolume)

const totalProgress = calculateProgress(firstVolume, latestVolume)
const upperBodyProgress = calculateProgress(firstUpperBodyVolume.totalVolume, latestUpperBodyVolume.totalVolume);
const middleBodyProgress = calculateProgress(firstMiddleBodyVolume.totalVolume, latestMiddleBodyVolume.totalVolume);
const lowerBodyProgress = calculateProgress(firstLowerBodyVolume.totalVolume, latestLowerBodyVolume.totalVolume);
const overallBodyProgress = calculateProgress(firstAllActivitiesVolume.totalVolume, latestAllActivitiesVolume.totalVolume);
console.log("TOTALPROGRESS", totalProgress);
console.log("overall progress", overallBodyProgress)
console.log("upperBodyProgress", upperBodyProgress)
console.log("lowerBodyProgress", lowerBodyProgress)
console.log("middleBodyProgress", middleBodyProgress)
console.log("middlebodyProgress", middleBodyProgress, firstMiddleBodyVolume, latestMiddleBodyVolume)
	
	setCategorizedActivities({
		upperBody: upperBodyProgress,
		middleBody: middleBodyProgress,
		lowerBody: lowerBodyProgress,
		wholeBody: totalProgress,
		upperBodyProgressActivities: upperBodyProgressActivities,
		middleBodyProgressActivities: middleBodyProgressActivities,
		lowerBodyProgressActivities: lowerBodyProgressActivities,
		wholeBodyProgressActivities: totalVolumeActivities
	  });

	  console.log("categorizedActivities", categorizedActivities);
	}
	  console.log("No trainings");
	  return;
  }
} else {
	console.error("No valid programs found")
}
	  
	  
},[trainings, availableActivities])



// useEffect(() => {
// 	if(!trainings || trainings.length > 0) {		
// 		return;
	
// 		const filteredGymPrograms = trainings.filter(program => program.visit_type !== 4);
// 		const latestTrainingName = filteredGymPrograms[0].training_program_name
// 		setProgramName(latestTrainingName);
// 	}	
	
// },[trainings])

let partProgress;
switch (selectedPart) {
	case 'wholeBody':
		partProgress = categorizedActivities.wholeBody
	break;
	case 'upperBody':
		partProgress = categorizedActivities.upperBody
	break;
	case 'middleBody':
		partProgress = categorizedActivities.middleBody
	break;
	case 'lowerBody':
		partProgress = categorizedActivities.lowerBody
	break;
	default:
	break;
}

const getSelectedProgressActivities = (selectedPart) => {
	switch (selectedPart) {
		case 'wholeBody':
			return categorizedActivities.wholeBodyProgressActivities || { mostProgress: null, leastProgress: null };
		
		case 'upperBody':
			return categorizedActivities.upperBodyProgressActivities || { mostProgress: null, leastProgress: null };
		
		case 'middleBody':
			return categorizedActivities.middleBodyProgressActivities || { mostProgress: null, leastProgress: null };
		
		case 'lowerBody':
			return categorizedActivities.lowerBodyProgressActivities || { mostProgress: null, leastProgress: null };
		
		default:
			return {mostProgress: null, leastProgress: null}
	}
}

const selectedProgressActivities = getSelectedProgressActivities(selectedPart);
  
//   const latestActivities = latestTrainings[].activities.filter(activity => activity.visit_type === 3 || activity.visit_type === 1);
  const latestActivities = latestTrainings.length > 0 ? latestTrainings[0].activities : [];
  
  console.log("latest activities", latestActivities) 

const isFemale = currentUser?.profile?.gender === 'F';

	if(currentUser && currentUser.profile) {
		const isFemale = currentUser.profile.gender === 'F';
	} else {
		const isFemale = false;
	}

	console.log("Is female:", isFemale)

const daysUntilWeekend = 7 - moment().day()
	
console.log("latestTraining3333", latestTrainings)
  const current_month = moment().format('MMMM');

	const getTrainingPicture = (trainingProgramId, trainingPrograms) => {
		if(!Array.isArray(trainingPrograms)) {
			return null;
		}
		const trainingProgram = trainingPrograms.find(program => program.id === trainingProgramId);
		return trainingProgram ? trainingProgram.training_picture : null;
	};
	
	const serverUrl = localStorage.getItem("server_url");
	const cdnAddress = "https://cdn01.hursmarttouch.com"						
	
	const fallBackImages = [
		"TEK_1633.jpg",
		"TEK_1650.jpg",
		"TEK_2013.jpg",
		"TEK_1944.jpg",
		"TEK_1768.jpg",
		"TEK_1987.jpg",
		"TEK_2261.jpg",
	]	



	let usedFallBackImage = new Set()

	function getUniqueRandomFallbakImageUrl() {
		if(usedFallBackImage.size === fallBackImages.length) {
			usedFallBackImage.clear();
		}
		let randomIndex;
		do {
			randomIndex = Math.floor(Math.random() * fallBackImages.length);
		} while(usedFallBackImage.has(fallBackImages[randomIndex]))
			usedFallBackImage.add(fallBackImages[randomIndex]);
			return `${cdnAddress}/media/training_images/gym_training/${fallBackImages[randomIndex]}` 
	}

	function getResusableRandomFallbackImageUrl() {
		const randomIndex = Math.floor(Math.random() * fallBackImages.length);
		return `${cdnAddress}/media/training_images/gym_training/${fallBackImages[randomIndex]}` 
	}

	function getRandomFallBackImageUrl() {
		const randomIndex = Math.floor(Math.random() * fallBackImages.length)
		return `${cdnAddress}/media/training_images/gym_training/${fallBackImages[randomIndex]}`
	}

	function getImageUrl(program, trainingPrograms, unique = false) {
		const trainingPicture = getTrainingPicture(program.training_program, trainingPrograms)
		if(trainingPicture && trainingPicture.trim() !== "") {
			return `${serverUrl}/media/${trainingPicture}`
		}
		const image = program.training_picture;
		if(image && image.trim() !== "") {
			return `${serverUrl}/media/${image}`
		} else {
			return unique ? getUniqueRandomFallbakImageUrl() : getResusableRandomFallbackImageUrl();
		}
	}		

	function calculateTotalProgress(gymData, homeData) {		
		let totalGoal = 0;
		let totalAchieved = 0;

		if(gymData.goal > 0) {
			totalGoal += gymData.goal;
			totalAchieved += gymData.count;
		}

		if(homeData.goal > 0) {
			totalGoal += homeData.goal;
			totalAchieved += homeData.count;			
		}

		if(totalGoal === 0) return 0;

		return Math.floor((totalAchieved / totalGoal) * 100);
	}

	const totalWeekProgress = calculateTotalProgress(weekGoalThisWeekData.gym, weekGoalThisWeekData.home)
	console.log("totalWeekProgress", totalWeekProgress);

	useEffect(() => {				
		const homePercent = weekGoalThisWeekData.home.percent;
		const homeGoal = weekGoalThisWeekData.home.goal;
		const gymPercent = weekGoalThisWeekData.gym.percent;
		const gymGoal = weekGoalThisWeekData.gym.goal;		
		if((homePercent > 0 || gymPercent > 0) || (gymGoal > 0 || homeGoal > 0)){
			setIsDataFetched(true);

		if(!hasSeenGoalDialog) {
			if((gymPercent >= 100 && (homeGoal === 0 || homePercent >= 100)) || (homePercent >= 100 && (gymGoal === 0 || gymPercent >= 100))) {
				handleOpenGoalDialog();
			}			
		}				
	}	
	}, [weekGoalThisWeekData, hasSeenGoalDialog])

	useEffect(() => {
		if(isDataFetched) {
			const homePercent = weekGoalThisWeekData.home.percent;
			const homeGoal = weekGoalThisWeekData.home.goal;
			const gymPercent = weekGoalThisWeekData.gym.percent;
			const gymGoal = weekGoalThisWeekData.gym.goal;

			if((gymGoal === 0 && homePercent < 100) || (homeGoal === 0 && gymPercent < 100)) {
				if(hasSeenGoalDialog) {
				localStorage.setItem('hasSeenGoalDialog', 'false');
				setHasSeenGoalDialog(false);
			}
			}
		}
	}, [isDataFetched, weekGoalThisWeekData, hasSeenGoalDialog])

	const unreadMessagesCount = Array.isArray(messages) && currentUser && currentUser.id
	? messages.filter(msg => msg.read_at === null && msg.sender !== currentUser.id).length
	: 0;

	console.log("unread messages", unreadMessagesCount)

	useEffect(() => {	
		let updated = false;	
		const newImageCache = {...imageCache};
		slicedTraining.forEach(program => {
			if(!newImageCache[program.id]) {
				newImageCache[program.id] = getImageUrl(program, true)
				updated = true;
			}			
		});	
		if(updated) {
		setImageCache(newImageCache);			
	}
	}, [slicedTraining, getImageUrl, imageCache])

	useEffect(() => {
		console.log("current path:", location.pathname)
		console.log("loading state:", loading)
		console.log("manual delay state:", manualDelay)
		if(previousPath.current === '/login') {
			console.log("From /login applying delay...");
			setManualDelay(true);

			const timer = setTimeout(() => {
				setLoading(false);
				setManualDelay(false);
			}, 6000)
			return () => clearTimeout(timer);
		} else {
			setLoading(false);
		}
		previousPath.current = location.pathname
	}, [location.pathname])
	console.log("fallbackUrl", getRandomFallBackImageUrl())	
	console.log("weekgoalthisweek", weekGoalThisWeekData)		

	if (loading || manualDelay) {
		console.log("loading");				
		return <HurLoadingScreen />;
	}			
	if(error) {
		return <div>Error: {error.message}</div>
	}		
	return (
		
		<div className={classes.root}>
			<div>
			<p className={classes.welcome}>{t('dashboard_page.welcomeback')}</p>
			<div className={classes.nameContainer}>
			<h2 className={classes.welcomeName}>{currentUser?.first_name + ' ' + currentUser?.last_name[0]}</h2>
			<div style= {{
				position: "relative",
				display: "flex",
				alignItems: "center"
			}}>
			<MessageIcon 
			style={{				
				marginTop: 10,
				marginRight: 20
				}}
			onClick={() => navigate('/app/messages')}
				 />
				{unreadMessagesCount > 0 && (
					<div className={classes.unreadMessage}>
							{unreadMessagesCount}
					</div>
				)}
				</div>
			</div>
			<h3 style={{marginTop: 5, fontFamily:"Poppins", fontWeight: 600, fontSize: "1rem", color:"#404040"}}>{t('dashboard_page.lets_exercise')}</h3>
			</div>
			
			<div className={classes.trainingPapersDiv}>
				<div style={{display: "flex", justifyContent:"center", margin: "auto", marginLeft: "10px"}}>
				{slicedTraining.length === 0  ? (									
					<h2>{t('dashboard_page.no_programs')}</h2>
				) : (
					<Swipeable onSwipeLeft={handleSwipeLeft} onSwipeRight={handleSwipeRight}>
					<div 
					className={classes.paperContainer}
					style={{marginLeft: slicedTraining.length === 3 ? '60px' : '25px'}}
					>
					{slicedTraining.map((program, index)=> {
						const amountOfActivitiesForProgram = program.activities.length;						
						const paperWidth = 345;
						const gap = 10																																							
						// const imageUrl = `${image}`.startsWith('training_images') ? `${serverUrl}media/${image}`: `${fallBackUrl}`
						let initialOffset = `${-((currentIndex - 1) * (100 + gap))}%`
						console.log("Program:", program);
						const imageUrl = imageCache[program.id] || getImageUrl(program, true);						
						
						console.log("mostUsedPrograms visit types", program.visit_type)

						if(slicedTraining.length === 1) {
							initialOffset = '0%';							
						} else if (slicedTraining.length === 2 ) {
							initialOffset = `${-((currentIndex - 0.55) * (paperWidth + gap))}px`;
						}
						return (
						<Paper 
						className={classes.trainingPapers}
						key={index}
						style = {{
								//   transform: `translateX(calc(-${currentIndex * 100}% + ${index - currentIndex}100%))`,
								  transform: `translateX(${initialOffset})`,
								  transition: 'transform 0.4s ease',
								  backgroundImage: `url(${imageUrl})`,
								  backgroundSize: "cover",
								  display: 'flex',
    							  flexDirection: 'column',
    							  justifyContent: 'flex-end',
								  
								  					  					
					}}	
					onClick={(event) => {
						event.stopPropagation();
					}}
						>						
							<div className={classes.topPaper}
							onClick={(event) => {
								event.stopPropagation();
							}}
							>
								
							<div style={{display: "flex", flexDirection: "column", marginLeft:"15px"}}>
							<h5 style={{marginTop: 10, marginBottom: 0, fontWeight: 500, fontFamily: "Poppins", fontSize: "0.875rem", color: "#404040"}}>{program.name}</h5>
							<p className={classes.workoutDesc}>{amountOfActivitiesForProgram} {t('common.exercises')}</p>
							</div>
							<Button
								style={{alignItems: "center", marginRight: "10px"}}
								className={classes.trainingButton}								
								onClick={() => onCreateSession(navigate('/app/startworkout'))}	
														
								>
									{t('dashboard_page.start_training')}
									</Button>
							</div>
						</Paper>
						);
					})}			
					</div>				
					</Swipeable>
				)}							
				</div>
				</div>				
				{slicedTraining.length > 0 && (
				<div className={classes.dotContainer}>
					{slicedTraining.map((_, dotIndex) => (
					<IconButton key={dotIndex} onClick={() => handleDotClick(dotIndex)}>					
						<Box
							className={`${classes.dot} ${currentIndex === dotIndex ? classes.activeDot : ''}`} />						
					</IconButton>
					))}
					 
					</div>
					)}
					
			<h3 style={{marginTop: 5, fontFamily:"Poppins", fontWeight: 600, fontSize: "16px", color:"#404040"}}>{t('dashboard_page.muscle_group_progress')}</h3>						
			<div className={classes.progressDiv}>			
				<Paper className={classes.progressPaper}>
					{selectedPart === 'wholeBody' && (isFemale ?
						<FemaleWholeBody onClick={handleSvgClick} className={classes.svgPart} /> :
						<MaleWhole onClick={handleSvgClick} className={classes.svgPart}/>
					)}
					{selectedPart === 'upperBody' && (isFemale ?
						<FemaleUpperBody onClick={handleSvgClick} className={classes.svgPart} /> :
						<MaleUpperBody onClick={handleSvgClick} className={classes.svgPart} />
					)}									
					{selectedPart === 'middleBody' && (isFemale ?
						<FemaleMiddleBody onClick={handleSvgClick} className={classes.svgPart} /> :
						<MaleMiddleBody onClick={handleSvgClick} className={classes.svgPart} />
					)}					
					{selectedPart === 'lowerBody' && (isFemale ?
						<FemaleLowerBody onClick={handleSvgClick} className={classes.svgPart} /> :
						<MaleLowerBody onClick={handleSvgClick} className={classes.svgPart} />
					)}					
					<div className={classes.progressDetails}>
					<div style={{marginRight: "40px", display:"flex", justifyContent:"flex-end", width: "100%"}}>
					<InfoButton style={{height: "24px", width: "24px"}} onClick={handleOpenProgressDialog} />			
					</div>
					<Dialog 
				open={openProgressDialog} 
				onClose={handleCloseProgressDialog}				
				PaperProps={{
                    style:{
                        border: "3px solid rgba(64, 64, 64, 1)",
                        borderRadius: "20px"
                    }
                }}
				>
			<div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderBottom: "2px solid #404040", alignItems: "center", width: "100%"}}>
			<DialogTitle className={classes.dialogTitle} style={{}}>{t('dashboard_page.muscle_group_progress')}</DialogTitle>
			<IconButton  
				onClick={handleCloseProgressDialog}
				style={{
					paddingRight: 10,
					flexShrink: 0,
				}}>
				<CloseButton />
			</IconButton>
			</div>
			<DialogContent style={{paddingBottom: 5, paddingTop: 0, paddingLeft:0, paddingRight:0}}>
				<DialogContentText style={{color: "#404040", padding: 10, fontSize:"0.8125rem", fontWeight: 400, textAlign: "left", alignItems: "center"}}>
				<span style={{ display: "block", marginBottom: 8, paddingRight: 5,  paddingLeft: 5, wordBreak: "break-word", whiteSpace: "normal"}}>{t('dashboard_page.muscle_group_progress_explanation')}</span>
				<p style={{ marginTop: 8, paddingRight: 5,  paddingLeft: 5, width:"100%", }}>{t('dashboard_page.muscle_group_progress_explanation1')}</p>								 								 
				</DialogContentText>
				<div style={{display: "flex", justifyContent: "space-evenly"}}>
				<MaleBodyWithArrows style={{width: "40%", height: "40%"}}/>
				<FemaleBodyWithArrows style={{width: "40%", height: "40%"}}/>
				</div>
			</DialogContent>			
			</Dialog>
					<h3 className={classes.programTitle}>
					{programName}
					</h3>
					<div>
					<ProgressPie overallProgress={categorizedActivities[selectedPart]} />
					<p className={classes.programDescription}>
					{selectedPart === 'wholeBody' && t('dashboard_page.muscle_group_segment_overall_progress')}
					{selectedPart === 'upperBody' && t('dashboard_page.muscle_group_segment_upper')}
					{selectedPart === 'middleBody' && t('dashboard_page.muscle_group_segment_middle')}
					{selectedPart === 'lowerBody' && t('dashboard_page.muscle_group_segment_lower')}
					</p>
					</div>				
					<div>
					<p className={classes.progressDetailsTextProgress}>{t('dashboard_page.muscle_group_progress_most_progress')}</p>
            <p className={classes.progressDetailsText}>{selectedProgressActivities.mostProgress ? `${selectedProgressActivities.mostProgress.activityName}: ${selectedProgressActivities.mostProgress.progress > 0 ? '+' : ''} ${selectedProgressActivities.mostProgress.progress}%` : t('dashboard_page.muscle_group_progress_no_exercises')}</p>
			<p className={classes.progressDetailsTextProgress}>{t('dashboard_page.muscle_group_progress_least_progress')}</p>
            <p className={classes.progressDetailsText} style={{paddingBottom: "10px"}}>{selectedProgressActivities.leastProgress ? `${selectedProgressActivities.leastProgress.activityName}: ${selectedProgressActivities.leastProgress.progress > 0 ? '+' : ''} ${selectedProgressActivities.leastProgress.progress}%` : t('dashboard_page.muscle_group_progress_no_exercises')}</p>            
			<p className={classes.progressDetailsText}>{t('dashboard_page.muscle_group_progress_latest_training_date')} {latestTrainingDate ? latestTrainingDate.toLocaleDateString() : null}</p>
			<p className={classes.progressDetailsText}>{t('dashboard_page.muscle_group_progress_first_training_date')} {firstTrainingDate ? firstTrainingDate.toLocaleDateString() : null}</p>			
			</div>
            </div>
				</Paper>
			
			</div>
			<div style={{display: "flex", justifyContent:"space-between", marginTop: 10, alignItems: "center" }}>
				<h3 style={{marginTop: 5, fontFamily:"Poppins", fontWeight: 600, fontSize: "16px", color:"#404040", textAlign: "center"}}>{t('dashboard_page.workout_progress')}</h3>								
			</div>		
			<div className={classes.workoutPaperContainer}>
				<Paper className={classes.workoutPaper}>					
					<div className={classes.workoutTotalContainer}>
					<div className={classes.workoutTotalText}>
					<div style={{display: "flex", justifyContent:"space-between"}}>
					{weekGoalThisWeekData.all.goal === 0 ? (
						<>
						<h4 style={{marginTop: 5, marginBottom: 0, fontFamily:"Poppins", fontWeight: 600, fontSize: "0.75rem", color:"#404040"}}>{t('dashboard_page.goals_not_set')}</h4>						
						</>
					) : (
					<h3  style={{marginTop: 5, marginBottom: 0, fontFamily:"Poppins", fontWeight: 600, fontSize: "0.75rem", color:"#404040"}}>{t('dashboard_page.weekly_total')}</h3>
				)}
					<InfoButton onClick={handleOpenDialog} style={{marginRight: "20px", height: "24px", width:"24px",}}/>			
					<Dialog 
				open={openDialog} 
				onClose={handleCloseDialog}	
				PaperProps={{
                    style:{
                        border: "3px solid rgba(64, 64, 64, 1)",
                        borderRadius: "20px"
                    }
                }}			
				>
			<div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderBottom: "2px solid #404040", alignItems: "center", width: "100%"}}>
			<DialogTitle className={classes.dialogTitle}>{t('dashboard_page.weekly_workout_time')}</DialogTitle>
			<IconButton  
				onClick={handleCloseDialog}
				style={{
					paddingRight: 10,
					flexShrink: 0,
				}}>
				<CloseButton />
			</IconButton>
			</div>
			<DialogContent>
				<DialogContentText style={{color: "black", fontSize:"0.75rem", fontWeight: 400}}>
				<p style={{marginTop:0}}>{t('dashboard_page.explain_weekly_workout_time')}</p>				
				 
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button ></Button>
			</DialogActions>
			</Dialog>
					</div>
					{weekGoalThisWeekData.all.goal === 0 ? (
						<>		
						<Button onClick={() => navigate('/app/profile/goal-settings')} className={classes.setgoalsbutton} style={{}}>{t('dashboard_page.set_goals')}</Button>				
						</>
				) : (
					<>
					<div style={{display: "flex", flexDirection: "row"}}>
					<h4 style={{
						fontFamily: "Poppins",
						fontSize: "1.25rem", 
						fontWeight: 500, 
						margin: 0, 
						color: "#404040"
						 }}
						 >
							{totalWeekProgress}%
							</h4>	
					{(weekGoalThisWeekData.gym.percent >= 100 && weekGoalThisWeekData.home.percent >= 100) ||
					 (weekGoalThisWeekData.gym.goal === 0 && weekGoalThisWeekData.home.percent >= 100) || 
					 (weekGoalThisWeekData.home.goal === 0 && weekGoalThisWeekData.gym.percent >= 100) ? (
						<Trophy style={{height: "8%", width: "8%"}} />
					) : null}	
					</div>	
					{(weekGoalThisWeekData.gym.percent >= 100 && weekGoalThisWeekData.home.percent >= 100) ||
					(weekGoalThisWeekData.gym.goal === 0 && weekGoalThisWeekData.home.percent >= 100) ||
					(weekGoalThisWeekData.home.goal === 0 && weekGoalThisWeekData.gym.percent >= 100) ? (
						<p className={classes.goaltext}>{t('dashboard_page.goal_dialog_text')}</p>
					) : (
						<p className={classes.goaltext}>{daysUntilWeekend} {t('dashboard_page.days_till_goal')}</p>
					)}						
					
					</>
				)
			}
					</div>
					</div>
					<div className={classes.workoutProgressContainer}>
					<WorkoutProgress
						weekGoalThisWeekData={weekGoalThisWeekData}/>		
					<GoalsAchived 						
						open={openGoalsDialog}
						handleCloseDialog={handleCloseGoalDialog}												
						/>			
					</div>
				</Paper>				
			</div>
			<div style={{display: "flex", justifyContent: "space-between"}}>
			{latestTrainings.length > 0 && (
				<>
				<h3 style={{fontFamily:"Poppins", fontWeight: 600, fontSize: "16px", color:"#404040"}}>{t('dashboard_page.latest')}</h3>
				
				<Button
					style={{color: "#404040", paddingRight: 20, textTransform: "none"}}
					onClick={() => navigate("/app/workouts")}
					>
						{t('dashboard_page.see_more')}	
					</Button>
					</>
					)}
				</div>
				<div style={{ display: "flex", flexDirection: "column", alignItems:"center", justifyContent:"center", marginRight:25, marginBottom: 10,}}>
				{latestTrainings.length === 0 ? (					
					<></>					
				) : (										
					latestTrainings.map((training, index) => {	
						// const imageUrl = imageCache[training.id] || getRandomFallBackImageUrl();
						const imageUrl = getImageUrl(training, trainingData, true);	
						
					return (		
					<Button component={RouterLink} to={`/app/workouts/${training?.id}`} key={index} className={classes.latestWorkouts} >						
						<Avatar
						alt={training.training_program_name}
						src={imageUrl}
						className={classes.latestworkoutpic}
						style={{marginRight: 15}}
						/>
						<div>
						<h4 className={classes.workoutTitle}>{training.training_program_name}</h4>
						<p className={classes.workoutDetail}>{formattedDates[index]} | {totalDurationInMinutes[index]} {t('common.minutes')}</p>										
						</div>
						<div className={classes.tag}>
						{training.visit_type === 4 ? (
							<HomeTag />
						) : (
							<GymTag />
						)}
						</div>				
					</Button>
					);
					})
				)}						
				</div>											
		</div>
		
	);
}

