import { teal } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
// import { Link as ExternalLink } from 'react-router-link-external';
//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import pick from "lodash/pick";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { HurLoadingScreen, Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { getDashboardData, getLatestTrainingSession } from "../services/api";
import { RootState } from "../reducers";
import UserProfile from "../components/Profile/User";
import { useActions } from "../actions";
import * as UserActions from "../actions/auth";
import { getAccount, updateAccount } from "../services/api";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { PersonalInformation } from "../components";

import privacyPolicy from "../components/Profile/PrivacyPolicy";
import { Link as RouterLink} from "react-router-dom";

import {
	Profile,
	Language,
	Goals,
	Message,
	Privacy,
	Update,
	Tutorial,
	Arrow,
	ArrowLeft,
} from '../resources'
import CloseIcon from '@mui/icons-material/Close';
import HurLogo from '../resources/img/for_lifelong.png'

import Slide from '@mui/material/Slide';
import { Button, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		minHeight: "100vh",
		width: "100%",
		paddingBottom: "60px"			
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "column",		
		justifyContent: "center",
		paddingTop: "50px"				
	},
	backbutton: {
		zIndex: 100,
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",        
        paddingTop: 15,
        height: 70,
        background: "white",
        width: "340px",
        top: 0
	},
	title: {
		fontFamily: "Poppins",
		fontSize: "16px",
		fontWeight: 700,		
		color: "#404040"
	},
	stat_card: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
	buttonsDiv: {
		display: "flex",		
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		paddingTop: 10,
		color: "#404040"
		
	},
	secondButtonsDiv: {
		display: "flex",		
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		paddingTop: 50,
		textAlign: "left",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		textAlign: "left",
		color: "black",
		fontWeight: 500,
		fontSize: "0.75rem",
		marginTop: "20px",
		width: "306px",
		height: "50px",
		background: "white",		
		borderRadius: "16px",
		boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
		borderBottom: "2px solid #00A0DF",
		textTransform: "none"
	},
	arrow: {
		marginLeft: "auto"	,
	},
	icon: {
		marginRight: "10px",
		marginLeft: "10px"
		
	},
	buttonText: {
		flexGrow: 1,
		alignText: "left",
		marginLeft: "10px"
	},
	logoutButton: {	
		alignItems: "left",        
		marginTop: "30px",		
        color: "white",
        height: "44px",
		width: "201px",
		borderRadius: "99px",
		background: "rgba(203, 39, 45, 1)",
		textTransform: "none",
		fontWeight: 700,
		boxShadow: "0 2px 2px #404040",
		'&:hover': {
			boxShadow: "0 2px 2px #404040",
			background: "rgba(203, 39, 45, 1)",
		},
    },
	logo: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		margin: "20px"
	},
	dialog: {
		width: "100%",
		borderRadius: "20px"
	},
	arrowButton: {
		height: "40px",               
        borderRadius: "14px",
        background: "rgba(247, 248, 248, 1)",
        marginRight: "54px",
        marginLeft: "10px" 
	},
}));

export function ProfilePage() {
	const navigate = useNavigate();
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const authActions = useActions(UserActions);
	const [dashboardData, setDashboardData] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [lastVisit, setLastVisit] = React.useState(null);
	const [message, setMessage] = React.useState(null); // for snackbar
	const [currentView, setCurrentView] = React.useState('account');
	const [openPrivacyDialog, setOpenPrivacyDialog] = React.useState(false);
	const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper')

	const currentUser = useSelector((state: RootState) => state.user);

	const handleViewChange = (viewName) => {
		setCurrentView(viewName);
	}

	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	const handleCloseMessage = () => {
		setMessage(null);
	};

	const handlePrivacyPolicyClick = (scrollType: DialogProps['scroll']) => () => {
		setOpenPrivacyDialog(true);
		setScroll(scrollType);
	}

	const handleClosePrivacyDialog = () => {
		setOpenPrivacyDialog(false);
	}

	const handleUpdate = () => {
		window.location.reload();
	};

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				// const data = await getDashboardData();
				// setDashboardData(data.visits);
				// const visitResponse = await getLatestTrainingSession(
				// 	currentUser.id
				// );
				// const lastVisit = get(visitResponse, "results[0]");
				// if (lastVisit) {
				// 	setLastVisit(lastVisit);
				// }

				const user = await getAccount();
				authActions.setUser(user);
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveUser = async (values) => {
		// console.log(values);
		let userValues = pick(values, ['first_name',  'last_name']);
		let profileValues = pick(values, ['gender', 'language']);

		let _currentUser = {...currentUser, ...userValues};
		_currentUser.profile = {...currentUser.profile, ...profileValues};
		
		// console.log(_currentUser);
		// remove cards for now
		_currentUser.cards = []	
		// remove mugshot for now
		_currentUser.profile.mugshot = null		

		try{
			const updatedUser = await updateAccount(
				_currentUser
			);
			authActions.setUser(updatedUser);
			setMessage(  t('profile_page.profile_saved') );
		}catch(e){
			console.log(e);
		}

		
		//authActions.setUser(currentUser);
	}
	


	// const  endSession = async () => {

	// 	try {
	// 		// remove activities from payload
	// 		const {activities, ...uploadVisitData} = visit;
	// 		// fill in logout
	// 		uploadVisitData.logout = moment().toISOString();
	// 		const updatedVisit = await updateVisit(
	// 			parseInt(visit.id, 10),
	// 			uploadVisitData
	// 		);
	// 		setVisit(updatedVisit);
	// 		setMessage("Session ended.");
	// 		setTimeElapsed("");
	// 		clearInterval(interval);			
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
		

	// }
	
	function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}


	if (loading) {
		return <HurLoadingScreen />;
	}
    const current_month = moment().format('MMMM');
	return (
		<div className={classes.root}>
			<div className={classes.buttonContainer}>				
			{/* <h3 style={{marginLeft: "60px"}}>{t('profile_page.sub_title_account')}</h3> */}
			<div className={classes.buttonsDiv}>
			<div className={classes.backbutton}>
                    <Button onClick={() => navigate('/app/dashboard')} style={{marginRight: "54px"}}><ArrowLeft/></Button>
                    <h4 className={classes.title}>{t('common.bottom_label_profile')}</h4>
                </div>	
			<Button className={classes.buttons} onClick={() => navigate('/app/profile/personal-information')} startIcon={<Profile className={classes.icon}/>} endIcon={<Arrow className={classes.arrow} />}><span className={classes.buttonText}>{t('profile_page.personalinformation_button')}</span></Button>
			<Button className={classes.buttons} onClick={() => navigate('/app/profile/language-settings')} startIcon={<Language className={classes.icon}/>} endIcon={<Arrow className={classes.arrow}/>}><span className={classes.buttonText}>{t('profile_page.languagesettings_button')}</span></Button>			
			<Button className={classes.buttons} onClick={() => navigate('/app/profile/goal-settings')} startIcon={<Goals className={classes.icon}/>} endIcon={<Arrow className={classes.arrow}/>}><span className={classes.buttonText}>{t('profile_page.weeklygoals_button')}</span></Button>			
			
			{/* <h3 style={{marginLeft: "60px"}}>{t('profile_page.sub_title_other')}</h3> */}
			
			<Button className={classes.buttons} onClick={() => navigate('/app/messages')} startIcon={<Message className={classes.icon}/>} endIcon={<Arrow className={classes.arrow} />}><span className={classes.buttonText}>{t('profile_page.message_button')}</span></Button>
			<Button className={classes.buttons} onClick={() => navigate('/app/onboarding')} startIcon={<Tutorial className={classes.icon}/>} endIcon={<Arrow className={classes.arrow}/>}><span className={classes.buttonText}>{t('profile_page.tutorial')}</span></Button>
			<Button className={classes.buttons} onClick={handlePrivacyPolicyClick('paper')} startIcon={<Privacy className={classes.icon}/>} endIcon={<Arrow className={classes.arrow}/>} ><span className={classes.buttonText}>{t('profile_page.privacy_button')}</span></Button>			
			<Dialog 
				open={openPrivacyDialog} 
				onClose={handleClosePrivacyDialog}
				scroll={scroll}
				className={classes.dialog}		
				>
			<DialogTitle style={{marginLeft: "30px"}}>{t('profile_page.privacy_button')}</DialogTitle>
			<IconButton  
				onClick={handleClosePrivacyDialog}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,

				}}>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<DialogContentText style={{color: "black", fontSize:"12px", fontWeight: 400, textAlign:"left"}}>
				{privacyPolicy}
				</DialogContentText>
			</DialogContent>			
			</Dialog>
			<Button className={classes.buttons} onClick={handleUpdate} startIcon={<Update className={classes.icon}/>} endIcon={<Arrow className={classes.arrow}/>} ><span className={classes.buttonText}>{t('profile_page.check_for_updates')}</span></Button>			
			<div style={{alignItems: "left", width: "300px"}}>
			<Button className={classes.logoutButton} component={RouterLink} to="/app/logout" style={{textAlign:"center"}}>{t('common.logout')}</Button>
			</div>
			</div>			
			</div>			
			<div className={classes.logo}>
			<img src={HurLogo}  />	
			<Typography variant="body2" color="#404040" align="center">					
				HUR Mobile App 0.3.42b				
		</Typography>		
			</div>
		
				{/* <Grid container spacing={3}>

					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<UserProfile user={currentUser} save_user={saveUser}/>
					</Grid >
					{message && <Snackbar
						open={!!message}
						autoHideDuration={3000}
						onClose={handleCloseMessage}
						TransitionComponent={SlideTransition}
						sx={{ bottom: { xs: 60, sm: 0 } }}
					>
						<Alert severity="success">
							{message}
						</Alert>
					</Snackbar>
					}
				</Grid>			 */}
		</div>
	);
}
