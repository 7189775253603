import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField
} from '@mui/material';

import { RootState } from "../../reducers";
import { toInteger } from 'lodash';
import { withTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Goals = props => {
  const { className, ...rest } = props;

  const [values, setValues] = useState({
    gym_visits_per_week: props.user_goals?.gym_visits_per_week,
    home_training_sessions_per_week: props.user_goals?.home_training_sessions_per_week,
    gym_visits_per_month: props.user_goals?.gym_visits_per_month,
    home_training_sessions_per_month: props.user_goals?.home_training_sessions_per_month
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: toInteger(event.target.value)
    });
  };


  const classes = useStyles();

  return (
    <div>
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader={props.t('settings_page.sub_sub_title')}
          title={props.t('settings_page.sub_title')}
        />
        <Divider />
        <CardContent>
          <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText={props.t('settings_page.gym_visits_weekly_hint')}
                label={props.t('settings_page.gym_visits_weekly')}
                margin="dense"
                name="gym_visits_per_week"
                onChange={handleChange}
                required
                value={values.gym_visits_per_week}
                variant="outlined"
              />
              <TextField
                fullWidth
                helperText={props.t('settings_page.home_trainings_weekly_hint')}
                label={props.t('settings_page.home_trainings_weekly')}
                margin="dense"
                name="home_training_sessions_per_week"
                onChange={handleChange}
                required
                value={values.home_training_sessions_per_week}
                variant="outlined"
              />
              <TextField
                fullWidth
                helperText={props.t('settings_page.monthly_gym_goal_hint')}
                label={props.t('settings_page.monthly_gym_goal')}
                margin="dense"
                name="gym_visits_per_month"
                onChange={handleChange}
                required
                value={values.gym_visits_per_month}
                variant="outlined"
              />
              <TextField
                fullWidth
                helperText={props.t('settings_page.home_trainings_monthly_hint')}
                label={props.t('settings_page.home_trainings_monthly')}
                margin="dense"
                name="home_training_sessions_per_month"
                onChange={handleChange}
                required
                value={values.home_training_sessions_per_month}
                variant="outlined"
              />

              {/* <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Sunday Starts the Week"
              /> */}
            </Grid>          
        </CardContent>
        <Divider />

        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {props.save_user_goals(values);}}
          >
            {props.t('common.save')}
          </Button>
        </CardActions>
      </form>
    </div>
    <Grid container spacing={1} style={{ padding:16, margin:50 }}></Grid>
    </div>
  );
};

Goals.propTypes = {
  className: PropTypes.string,
  user_goals: PropTypes.object,
  save_user_goals: PropTypes.func
};

export default withTranslation()(Goals);
