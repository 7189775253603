
import React from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: `${theme.spacing(0)} auto`,  
        border: "2px solid #00A0DF",
        borderRadius: "4px"
    },
    wrapText: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
        },
    },
    button: {
        color: "#0F5486",
        width: "25px",
        height: "25px"
        //margin: theme.spacing(1),
    },
  })
);


export const TextInput = (props) => {
    const classes = useStyles();
    
    // create state for text input
    const [message, setMessage] = React.useState(""); 
    const [isFocused, setIsFocused] = React.useState(false);
    // create state for button
    

    return (
        <>
            <form className={classes.wrapForm}  noValidate autoComplete="off">
            <TextField
                id="standard-text"
                label={!message && !isFocused ? props.label : ''}
                variant="outlined"
                className={classes.wrapText}
                value={message}
                onChange={(newValue) => setMessage(newValue.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                InputLabelProps={{
                    shrink: false,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton onClick={() => 
                                {props.writeReply(message)
                                 setMessage('');
                                 setIsFocused(false);
                                }}
                                >
                            <SendIcon className={classes.button} />
                        </IconButton>
                        </InputAdornment>
                    )
                }}              
            />            
            </form>
        </>
    )
}



TextInput.propTypes = {
    writeReply: PropTypes.func,
    label: PropTypes.string,
  };
  
